import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Box, Button, Slider, Tab, TabProps, Tabs, Typography } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useSimulationInterfaceSettingsContext } from '../../../../../hooks/ContextHooks'
import { useTranslation } from 'react-i18next'

const StyledTab = (props: TabProps) => {
  const { children, ...other } = props
  return (
    <Tab
      sx={{
        color: 'text.primary',
        '&.Mui-selected': {
          color: 'primary.main',
        },
      }}
      {...other}
    >
      {children}
    </Tab>
  )
}

const InterfacePreview = (props: { children: ReactNode }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>{t('simulations.running.customizationSettings.preview')}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.default',
            borderRadius: 1,
            border: 2,
            borderColor: 'primary.main',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: window.innerWidth / 2.3 < 524 ? window.innerWidth / 2.3 : 524,
            height: window.innerHeight / 2.3 < 295 ? window.innerHeight / 2.3 : 295,
          }}
        >
          <Box
            sx={{
              bgcolor: 'appbar.primary',
              width: '100%',
              height: 50,
              display: 'flex',
            }}
          />
          {props.children}
        </Box>
      </Box>
    </>
  )
}

interface FrequencyBlockSettingsTabPanelProps {
  value: number
  width: number
  setWidth: (width: number) => void
  alignment: 'left' | 'center' | 'right'
  setAlignment: (alignment: 'left' | 'center' | 'right') => void
}

const FrequencyBlockSettingsTabPanel = (props: FrequencyBlockSettingsTabPanelProps) => {
  const { value, width, setWidth, alignment, setAlignment } = props
  const [freqsPerLine, setFreqsPerLine] = useState(Math.floor(((width / 100) * window.innerWidth) / 200))
  const { t } = useTranslation()

  useEffect(() => {
    setFreqsPerLine(Math.floor(((width / 100) * window.innerWidth) / 200))
  }, [width])

  // on window resize, update freqsPerLine
  useEffect(() => {
    const handleResize = () => setFreqsPerLine(Math.floor(((width / 100) * window.innerWidth) / 200))
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [width])

  return (
    <Box hidden={value !== 0}>
      <Typography>{t('simulations.running.customizationSettings.frequencyBlock.width')}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Slider
          min={1}
          max={100}
          value={width}
          sx={{ width: '70%', mb: 1 }}
          valueLabelDisplay={'auto'}
          onChange={(_event, val) => {
            if (typeof val !== 'number') return
            if (Math.floor(((val / 100) * window.innerWidth) / 200) < 1) return
            setWidth(val)
          }}
        />
      </Box>
      <Typography>{t('simulations.running.customizationSettings.frequencyBlock.alignment')}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Select
          value={alignment}
          onChange={(event) => {
            setAlignment(event.target.value as 'left' | 'center' | 'right')
          }}
          sx={{ width: 250, mb: 2 }}
        >
          <MenuItem value={'left'}>{t('simulations.running.customizationSettings.frequencyBlock.left')}</MenuItem>
          <MenuItem value={'center'}>{t('simulations.running.customizationSettings.frequencyBlock.center')}</MenuItem>
          <MenuItem value={'right'}>{t('simulations.running.customizationSettings.frequencyBlock.right')}</MenuItem>
        </Select>
      </Box>
      <InterfacePreview>
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: alignment === 'left' ? 'flex-start' : alignment === 'center' ? 'center' : 'flex-end',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ m: 1 }}>
            {t('simulations.running.customizationSettings.frequencyBlock.frequenciesPerLine', {
              count: freqsPerLine,
            })}
          </Typography>
          <Box
            sx={{
              border: 4,
              borderStyle: 'dashed',
              borderColor: 'primary.main',
              borderRadius: 1,
              height: 150,
              display: 'flex',
              flexDirection: 'column',
              width: `${width}%`,
            }}
          >
            {/*line 1*/}
            <Box
              sx={{
                borderRadius: 1,
                width: `100%`,
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: alignment,
              }}
            >
              {Array.from(Array(freqsPerLine).keys()).map((i) => (
                <Box
                  key={i}
                  sx={{
                    borderRadius: 1,
                    width: '55px',
                    height: '80%',
                    bgcolor: 'primary.main',
                    m: '7px',
                  }}
                />
              ))}
            </Box>
            {/*line 2*/}
            <Box
              sx={{
                borderRadius: 1,
                width: `100%`,
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: alignment,
              }}
            >
              {Array.from(Array(freqsPerLine).keys()).map((i) => (
                <Box
                  key={i}
                  sx={{
                    borderRadius: 1,
                    width: '55px',
                    height: '80%',
                    bgcolor: 'primary.main',
                    m: '7px',
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </InterfacePreview>
    </Box>
  )
}

interface PrivateCallSettingsTabPanelProps {
  value: number
  alignment: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  setAlignment: (alignment: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right') => void
}

const PrivateCallSettingsTabPanel = (props: PrivateCallSettingsTabPanelProps) => {
  const { value, alignment, setAlignment } = props
  const windowHeight = window.innerHeight / 2.3 < 295 ? window.innerHeight / 2.3 : 295
  const { t } = useTranslation()

  return (
    <Box hidden={value !== 1}>
      <Typography>{t('simulations.running.customizationSettings.privateCall.alignment')}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Select
          value={alignment}
          onChange={(event) => {
            setAlignment(event.target.value as 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right')
          }}
          sx={{ width: 250, mb: 2 }}
        >
          <MenuItem value={'top-left'}>{t('simulations.running.customizationSettings.privateCall.topLeft')}</MenuItem>
          <MenuItem value={'top-right'}>{t('simulations.running.customizationSettings.privateCall.topRight')}</MenuItem>
          <MenuItem value={'bottom-left'}>
            {t('simulations.running.customizationSettings.privateCall.bottomLeft')}
          </MenuItem>
          <MenuItem value={'bottom-right'}>
            {t('simulations.running.customizationSettings.privateCall.bottomRight')}
          </MenuItem>
        </Select>
      </Box>
      <InterfacePreview>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: alignment.includes('top') ? 'flex-start' : 'flex-end',
            alignItems: alignment.includes('left') ? 'flex-start' : 'flex-end',
          }}
        >
          {alignment.includes('top') ? (
            <>
              <Box
                sx={{
                  border: 2,
                  borderStyle: 'dashed',
                  borderColor: 'primary.main',
                  borderRadius: 1,
                  height: 30,
                  width: 100,
                  display: 'flex',
                  mr: 1,
                  ml: 1,
                  mt: alignment.includes('top') ? 1 : 0,
                }}
              />
              <Box
                sx={{
                  mt: 1,
                  border: 2,
                  borderStyle: 'dashed',
                  borderColor: 'primary.main',
                  borderRadius: 1,
                  height: windowHeight * 0.35 + 'px',
                  width: 30,
                  display: 'flex',
                  mr: 1,
                  ml: 1,
                }}
              />
            </>
          ) : (
            <>
              <Box
                sx={{
                  border: 2,
                  borderStyle: 'dashed',
                  borderColor: 'primary.main',
                  borderRadius: 1,
                  height: windowHeight * 0.35 + 'px',
                  width: 30,
                  display: 'flex',
                  mr: 1,
                  ml: 1,
                }}
              />
              <Box
                sx={{
                  mt: 1,
                  border: 2,
                  borderStyle: 'dashed',
                  borderColor: 'primary.main',
                  borderRadius: 1,
                  height: 30,
                  width: 100,
                  display: 'flex',
                  mr: 1,
                  ml: 1,
                  mb: alignment.includes('bottom') ? 1 : 0,
                }}
              />
            </>
          )}
        </Box>
      </InterfacePreview>
    </Box>
  )
}

interface InterfaceCustomizationDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const InterfaceCustomizationDialog = (props: InterfaceCustomizationDialogProps) => {
  const { open, setOpen } = props
  const [tab, setTab] = useState(0)
  const simulationInterfaceSettings = useSimulationInterfaceSettingsContext()
  const [width, setWidth] = useState(
    simulationInterfaceSettings?.settings.frequencyBlockSettings.frequencyBlockWidth ?? 100,
  )
  const [frequencyBlockAlignment, setFrequencyBlockAlignment] = useState<'left' | 'center' | 'right'>(
    simulationInterfaceSettings?.settings.frequencyBlockSettings.frequencyBlockAlignment ?? 'center',
  )
  const [privateCallAlignment, setPrivateCallAlignment] = useState<
    'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  >(simulationInterfaceSettings?.settings.privateCallSettings.privateCallAlignment ?? 'bottom-right')
  const { t } = useTranslation()

  const handleSave = () => {
    simulationInterfaceSettings.updateFrequencyBlockWidth(width)
    simulationInterfaceSettings.updateFrequencyBlockAlignment(frequencyBlockAlignment)
    simulationInterfaceSettings.updatePrivateCallAlignment(privateCallAlignment)
    setOpen(false)
  }

  return (
    <Dialog fullWidth open={open} onClose={() => {}}>
      <DialogTitle>Interface customization</DialogTitle>
      <DialogContent sx={{ height: 500 }}>
        <Box>
          <Tabs sx={{ mb: 2 }} variant={'fullWidth'} value={tab} onChange={(_event, newValue) => setTab(newValue)}>
            <StyledTab label={t('simulations.running.customizationSettings.frequencyBlock.title')} />
            <StyledTab label={t('simulations.running.customizationSettings.privateCall.title')} />
          </Tabs>
          <FrequencyBlockSettingsTabPanel
            value={tab}
            width={width}
            setWidth={setWidth}
            alignment={frequencyBlockAlignment}
            setAlignment={setFrequencyBlockAlignment}
          />
          <PrivateCallSettingsTabPanel
            value={tab}
            alignment={privateCallAlignment}
            setAlignment={setPrivateCallAlignment}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default InterfaceCustomizationDialog
