import { Box, Card, Chip, Typography } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import { APISimulationRole } from '../../../../rest/simulation/models/APISimulationRole'

interface SimulationRoleReviewCardProps {
  role: APISimulationRole
  index: number
}

function SimulationRoleReviewCard(props: SimulationRoleReviewCardProps) {
  const { role, index } = props

  const simulationStyle = {
    fwContainer: {
      fontSize: '1.1em',
      borderRadius: 2,
      p: 1,
      width: '100%',
      border: '1px solid #517788',
      backgroundColor: 'secondary.light',
      boxShadow: 2,
    },
    container: {
      fontSize: '1.1em',
      borderRadius: 2,
      backgroundColor: 'secondary.light',
      p: 1,
      border: '1px solid #517788',
      boxShadow: 2,
    },
  }

  return (
    <Card
      key={index}
      sx={{
        ml: 1,
        mr: 1,
        mt: 2,
        mb: 2,
        p: 2,
        borderRadius: 2,
        bgcolor: 'secondary.dark',
        minWidth: 260,
        width: 310,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography
          sx={{
            mr: 2,
            ...simulationStyle.fwContainer,
          }}
          noWrap
        >
          {role.name}
        </Typography>
        <Typography
          sx={{
            width: '13em',
            ...simulationStyle.container,
          }}
        >
          {t('roleTypes.' + role.roleType)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography
          sx={{
            mr: 2,
            width: '13em',
            ...simulationStyle.container,
          }}
        >
          {role.minAge} - {role.maxAge}
        </Typography>
        <Typography
          sx={{
            ...simulationStyle.fwContainer,
          }}
        >
          {t('gender.' + role.gender)}
        </Typography>
      </Box>
      <Box
        sx={{
          mb: 2,
          ...simulationStyle.container,
        }}
      >
        {role.languages.map((language) => (
          <Chip key={language} label={t('languages.' + language)} sx={{ fontSize: '1rem', m: 0.2 }} />
        ))}
      </Box>
      <Box
        sx={{
          mb: 2,
          ...simulationStyle.container,
        }}
      >
        {role.accents.map((accent) => (
          <Chip key={accent} label={t('accents.' + accent)} sx={{ fontSize: '1rem', m: 0.2 }} />
        ))}
      </Box>
      <Box
        sx={{
          ...simulationStyle.container,
        }}
      >
        {role.frequencies.map((frequency) => (
          <Chip key={frequency} label={frequency} sx={{ fontSize: '1rem', m: 0.2 }} />
        ))}
      </Box>
    </Card>
  )
}

export default SimulationRoleReviewCard
