import { Box, Card, CardActions, CardContent, Divider, Grid, Typography, useTheme } from '@mui/material'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfirm } from 'material-ui-confirm'
import UserContext from '../../../../contexts/user/UserContext'
import toast from 'react-hot-toast'
import SimulationRequestRoleDetails from './SimulationRequestRoleDetails'
import { useSimulationManagementApplicationsContext } from '../../../../hooks/ContextHooks'
import { postParticipation } from '../../../../rest/simulation/queries/SimulationParticipationQueries'
import { APISimulationRequest } from '../../../../rest/simulation/models/APISimulationRequest'
import { APISimulationRole } from '../../../../rest/simulation/models/APISimulationRole'

interface SimulationRequestRoleComponentProps {
  role: APISimulationRole
  simulation: APISimulationRequest
  disabled?: boolean
  setDisabledRoles?: Dispatch<SetStateAction<number[]>>
}

export const SimulationRequestRoleComponent = (props: SimulationRequestRoleComponentProps) => {
  const { role, simulation, disabled, setDisabledRoles } = props
  const theme = useTheme()
  const { t } = useTranslation()
  const confirm = useConfirm()
  const user = useContext(UserContext)?.user
  const [value, setValue] = React.useState('Dione')
  const [open, setOpen] = React.useState(false)
  const { updateVoluntaryUser } = useSimulationManagementApplicationsContext()

  const handleClose = (newValue?: string) => {
    setOpen(false)

    if (newValue) {
      setValue(newValue)
    }
  }

  function openDetails() {
    setOpen(true)
  }

  function handleParticipate() {
    confirm({
      title: t('simulations.published.roles.applyTitle', {
        role: role.name,
        simulation: simulation.simulation.name,
      }),
      description: t('simulations.published.roles.applyDescription', {
        role: role.name,
        simulation: simulation.simulation.name,
      }),
      confirmationText: t('simulations.published.roles.apply'),
      cancellationText: t('simulations.published.roles.cancel'),
    })
      .then(() => {
        postParticipation(user?.username, simulation.id ? simulation.id : undefined, role.id ? role.id : undefined)
          .then((updatedVoluntaryUser) => {
            if (setDisabledRoles) {
              setDisabledRoles((prev: number[]) => {
                return [...prev, updatedVoluntaryUser.roleId]
              })
            }
            toast.success(
              t('toast.simulations.roles.applySuccess', {
                role: role.name,
                simulation: simulation.simulation.name,
              }),
              { duration: 4000 },
            )
            updateVoluntaryUser(updatedVoluntaryUser)
          })
          .catch(() => {
            toast.error(
              t('toast.simulations.roles.applyError', {
                role: role.name,
                simulation: simulation.simulation.name,
              }),
              { duration: 4000 },
            )
          })
      })
      .catch(() => {})
  }

  return (
    <>
      <SimulationRequestRoleDetails
        role={role}
        simulation={simulation}
        open={open}
        value={value}
        onClose={handleClose}
      />
      <Card
        variant={'outlined'}
        sx={{
          m: 2,
          width: '350px',
          minHeight: '350px',
          boxShadow: 5,
          borderRadius: 3,
          '&:hover': {
            boxShadow: 15,
          },
        }}
      >
        <CardContent sx={{ height: '87%' }}>
          <Typography variant="h5">{role.name}</Typography>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            {t('roleTypes.' + role.roleType)}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Divider sx={{ width: '70%', m: 0.5 }} color={theme.palette.primary.main} />
          </Box>
          <Typography variant={'body2'} gutterBottom>
            {t('simulations.create.roles.languages')}
          </Typography>
          {role.languages.map((lang) => {
            return <Chip key={lang} label={t(`languages.${lang}`)} sx={{ m: 0.25 }} />
          })}
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Divider sx={{ width: '70%', m: 1 }} color={theme.palette.primary.main} />
          </Box>
          <Typography variant={'body2'} gutterBottom>
            {t('simulations.create.roles.accents')}
          </Typography>
          {role.accents.map((acc) => {
            return <Chip key={acc} label={t(`accents.${acc}`)} sx={{ m: 0.25 }} />
          })}
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Divider sx={{ width: '70%', m: 1 }} color={theme.palette.primary.main} />
          </Box>
          <Grid
            container
            width={'100%'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant={'body2'} gutterBottom>
                {t('simulations.create.roles.gender')}
              </Typography>
              <Chip label={t(`gender.${role.gender}`)} />
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'body2'} gutterBottom>
                {t('simulations.create.roles.ageMin')}
              </Typography>
              <Chip label={role.minAge} />
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'body2'} gutterBottom>
                {t('simulations.create.roles.ageMax')}
              </Typography>
              <Chip label={role.maxAge} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button
              size="small"
              disabled={disabled}
              onClick={() => {
                handleParticipate()
              }}
            >
              {t('simulations.published.roles.apply')}
            </Button>
            <Button size="small" onClick={() => openDetails()}>
              {t('simulations.published.roles.viewDetails')}
            </Button>
          </Box>
        </CardActions>
      </Card>
    </>
  )
}
