import React from 'react'
import useSimulationInterfaceSettings from './useSimulationInterfaceSettings'
import SimulationInterfaceSettingsContext from './SimulationInterfaceSettingsContext'

const SimulationInterfaceSettingsProvider = (props: { children: React.ReactNode }) => {
  const {
    simulationInterfaceCustomization,
    updateFrequencyBlockWidth,
    updateFrequencyBlockAlignment,
    updatePrivateCallAlignment,
  } = useSimulationInterfaceSettings()
  const { children } = props

  return (
    <SimulationInterfaceSettingsContext.Provider
      value={{
        settings: simulationInterfaceCustomization,
        updateFrequencyBlockWidth: updateFrequencyBlockWidth,
        updateFrequencyBlockAlignment: updateFrequencyBlockAlignment,
        updatePrivateCallAlignment: updatePrivateCallAlignment,
      }}
    >
      {children}
    </SimulationInterfaceSettingsContext.Provider>
  )
}

export default SimulationInterfaceSettingsProvider
