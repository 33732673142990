import React from 'react'
import { Box, Button, Container, Divider, Link, SxProps, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { useLocalStorage } from 'usehooks-ts'

interface ColumnConfig {
  id: number
  component: React.ReactNode
  sx?: SxProps
}

const ColumnComponent = (props: { children: React.ReactNode; sx?: SxProps; length: number }) => {
  return (
    <Box
      sx={{
        ...props.sx,
        p: 1,
        // border: 1,
        // borderColor: '#ff0000',
        minWidth: '200px',
        position: 'relative',
      }}
    >
      {props.children}
    </Box>
  )
}

function Footer() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [mode] = useLocalStorage('mode', window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

  const columns: ColumnConfig[] = [
    {
      id: 1,
      component: (
        <Box>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            sx={{
              color: 'appbar.text',
            }}
          >
            {t('footer.pages')}
          </Typography>
          <Divider sx={{ bgcolor: 'appbar.text', ml: 3, mr: 3 }} />
          <Button
            sx={{
              color: 'appbar.text',
              borderColor: 'transparent',
              boxShadow: 0,
              '&:hover': {
                borderColor: 'transparent',
                boxShadow: 0,
                color: '#fff',
              },
            }}
            variant={mode === 'light' ? 'contained' : 'outlined'}
            onClick={() => navigate('/')}
          >
            {t('pageNames.home')}
          </Button>
          <Button
            sx={{
              color: 'appbar.text',
              borderColor: 'transparent',
              boxShadow: 0,
              '&:hover': {
                borderColor: 'transparent',
                boxShadow: 0,
                color: '#fff',
              },
            }}
            variant={mode === 'light' ? 'contained' : 'outlined'}
            onClick={() => navigate('/simulations')}
          >
            {t('pageNames.simulations')}
          </Button>
        </Box>
      ),
    },
    {
      id: 2,
      component: (
        <Box>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            sx={{
              color: 'appbar.text',
            }}
          >
            {t('footer.account')}
          </Typography>
          <Divider sx={{ bgcolor: 'appbar.text', ml: 3, mr: 3 }} />
          <Button
            sx={{
              color: 'appbar.text',
              borderColor: 'transparent',
              boxShadow: 0,
              '&:hover': {
                borderColor: 'transparent',
                boxShadow: 0,
                color: '#fff',
              },
            }}
            variant={mode === 'light' ? 'contained' : 'outlined'}
            onClick={() => navigate('/account')}
          >
            {t('pageNames.myaccount')}
          </Button>
          <Button
            sx={{
              color: 'appbar.text',
              borderColor: 'transparent',
              boxShadow: 0,
              '&:hover': {
                borderColor: 'transparent',
                boxShadow: 0,
                color: '#fff',
              },
            }}
            variant={mode === 'light' ? 'contained' : 'outlined'}
            onClick={() => navigate('/profile')}
          >
            {t('pageNames.myprofile')}
          </Button>
        </Box>
      ),
    },
    {
      id: 3,
      component: (
        <Box>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            sx={{
              color: 'appbar.text',
            }}
          >
            {t('footer.about.title')}
          </Typography>
          <Divider sx={{ bgcolor: 'appbar.text', mb: 1, ml: 3, mr: 3 }} />
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              color: 'appbar.text',
            }}
            onClick={() => toast.success('Version: ' + process.env.REACT_APP_VERSION)}
          >
            {t('footer.about.content')}
          </Typography>
        </Box>
      ),
    },
  ]

  return (
    <>
      <MediaQuery minWidth={992}>
        <Box sx={{ mt: 20 }}>
          <Box
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              left: 0,
              padding: '1rem',
            }}
            sx={{
              bgcolor: 'appbar.primary',
              boxShadow: '0 0 5px 1px rgba(0, 0, 0, 0.5)',
            }}
          >
            <Container maxWidth={'xl'}>
              {/*columns*/}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  alignContent: 'header',
                  flexWrap: 'wrap',
                }}
              >
                {columns.map((column) => (
                  <ColumnComponent
                    key={column.id}
                    sx={{
                      ...column?.sx,
                      width: 'calc(100% /' + columns.length + ' - 2rem)',
                      height: '120px',
                    }}
                    length={columns.length}
                  >
                    {column.component}
                  </ColumnComponent>
                ))}
              </Box>
              <Divider sx={{ bgcolor: 'appbar.text', mb: 2 }} />
              <Typography
                variant="body2"
                sx={{
                  color: 'appbar.text',
                }}
                align="center"
              >
                Copyright{' '}
                <Link
                  color={'appbar.text'}
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=kraftedbytes.boardgames.checkers.androidapp',
                      '_blank',
                    )
                  }
                  sx={{
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'none',
                    },
                  }}
                >
                  ©
                </Link>{' '}
                2024
              </Typography>
            </Container>
          </Box>
        </Box>
      </MediaQuery>
      <MediaQuery maxWidth={991.9}>
        <Box sx={{ mt: 50 }}>
          <Box
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              left: 0,
              padding: '1rem',
            }}
            sx={{
              bgcolor: 'appbar.primary',
              boxShadow: '0 0 5px 1px rgba(0, 0, 0, 0.5)',
            }}
          >
            {/*columns*/}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                alignContent: 'header',
                flexWrap: 'wrap',
                mb: 2,
              }}
            >
              {columns.map((column) => (
                <ColumnComponent
                  key={column.id}
                  sx={{
                    ...column?.sx,
                  }}
                  length={columns.length}
                >
                  {column.component}
                </ColumnComponent>
              ))}
            </Box>
            <Divider sx={{ bgcolor: 'appbar.text', mb: 2 }} />
            <Typography variant="body2" align="center" sx={{ color: 'appbar.text' }}>
              Copyright{' '}
              <Link
                color="appbar.text"
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=kraftedbytes.boardgames.checkers.androidapp',
                    '_blank',
                  )
                }
                sx={{
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'none',
                  },
                }}
              >
                ©
              </Link>{' '}
              2024
            </Typography>
          </Box>
        </Box>
      </MediaQuery>
    </>
  )
}

export default Footer
