import { Room as LivekitRoom } from 'livekit-client'

export enum FrequencyStateEnum {
  Idle,
  Rx,
  Tx,
}

export interface FrequencyType {
  frequency: string
  sid: string
  state: FrequencyStateEnum
  livekitRoom: LivekitRoom
  audioContext: AudioContext
}
