import { Box, Card, CardActions, CardContent, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import React from 'react'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import GenericChipPopper from '../../../../components/mui/pops/GenericChipPopper'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'
import { APISimulationRequest } from '../../../../rest/simulation/models/APISimulationRequest'
import { putValidateSimulation } from '../../../../rest/simulation/queries/SimulationRequestQueries'
interface SimulationRequestComponentProps {
  simulation: APISimulationRequest
  viewRoles?: boolean
  manageApplications?: boolean
}

export const SimulationRequestComponent = (props: SimulationRequestComponentProps) => {
  const { simulation, viewRoles, manageApplications } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const confirm = useConfirm()

  function handleValidate() {
    confirm({
      title: t('simulations.validate.title'),
      description: t('simulations.validate.description', {
        simulation: simulation.simulation.name,
      }),
      confirmationText: t('button.confirm'),
      cancellationText: t('button.cancel'),
    }).then(() => {
      if (simulation.id) {
        putValidateSimulation(simulation.id)
          .then(() => {
            toast.success(t('simulations.validate.success', { simulation: simulation.simulation.name }))
          })
          .catch((e) => {
            console.error('error ', e)
            toast.error(t('simulations.validate.error', { simulation: simulation.simulation.name }))
          })
      }
    })
  }

  function handleManageCandidates() {
    //TODO : Refresh candidates
    navigate('/simulations/manage/' + simulation.id)
  }

  return (
    <Card
      variant={'outlined'}
      sx={{
        m: 2,
        width: '300px',
        height: '260px',
        boxShadow: 5,
        borderRadius: 3,
        '&:hover': {
          boxShadow: 15,
        },
      }}
    >
      <CardContent sx={{ height: '82%' }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {new Date(simulation.startDate).toLocaleDateString(undefined, {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          }) +
            ' ' +
            new Date(simulation.startDate).toLocaleTimeString(undefined, {
              hour: '2-digit',
              minute: '2-digit',
            }) +
            ' - ' +
            new Date(simulation.endDate).toLocaleDateString(undefined, {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            }) +
            ' ' +
            new Date(simulation.endDate).toLocaleTimeString(undefined, {
              hour: '2-digit',
              minute: '2-digit',
            })}
        </Typography>

        <Typography
          variant="h5"
          sx={{
            mb: 1,
            mt: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {simulation.simulation.name}
        </Typography>

        <Typography sx={{ mb: 1 }} color="text.secondary">
          {simulation.simulation.weather.replace('_', ' ').toLowerCase() +
            ' / ' +
            simulation.simulation.wind.replace('_', ' ').toLowerCase()}
        </Typography>
        <Chip label={simulation.status} />
        {simulation.simulation.tags.slice(0, 3).map((tag) => (
          <Chip
            key={tag}
            label={tag}
            sx={{
              m: 0.5,
              maxWidth: '120px',
              minHeight: 40,
              '& .MuiChip-label': {
                display: 'block',
                whiteSpace: 'normal',
              },
            }}
          />
        ))}
        {simulation.simulation.tags.length > 3 && (
          <GenericChipPopper
            chipContent={'+' + (simulation.simulation.tags.length - 3)}
            popperContent={
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    maxWidth: '300px',
                    mt: 1,
                    mb: 1,
                  }}
                >
                  {simulation.simulation.tags.map((tag) => (
                    <Chip key={tag} label={tag} sx={{ m: 0.5, height: 40 }} />
                  ))}
                </Box>
              </Box>
            }
            chipProps={{
              color: 'info',
              sx: {
                color: 'white',
                m: 0.5,
              },
            }}
          />
        )}
      </CardContent>
      <CardActions sx={{ width: '100%' }}>
        {viewRoles && (
          <Button
            size="small"
            onClick={() => {
              navigate('/simulations/list/' + simulation.id)
            }}
          >
            {t('simulations.published.viewRoles')}
          </Button>
        )}
        {manageApplications && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button
              size="small"
              onClick={() => {
                handleManageCandidates()
              }}
            >
              {t('simulations.manage.card')}
            </Button>
            <Button
              disabled={simulation.status !== 'PUBLISHED'}
              size="small"
              sx={{
                right: 0,
              }}
              onClick={() => {
                handleValidate()
              }}
              //TODO: faire la vérification back-end et rediriger uniquement si tout n'est pas ok avec un message
            >
              {t('simulations.manage.validate')}
            </Button>
          </Box>
        )}
      </CardActions>
    </Card>
  )
}
