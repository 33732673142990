import ConferenceCreationStepper from './SimulaionFormStepper'
import React from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { Add, UploadFile } from '@mui/icons-material'
import { SelectChangeEvent } from '@mui/material/Select'
import 'dayjs/locale/en-gb'
import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'
import SimulationFormEditScenarios from './SimulationFormEditScenarios'
import { APIScenario } from '../../../rest/simulation/models/APIScenario'
import { APISimulationConf } from '../../../rest/simulation/models/APISimulationConf'

interface SimulationFormSettingsProps {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  simulation: APISimulationConf
  setSimulation: React.Dispatch<React.SetStateAction<APISimulationConf>>
  savedScenarios: APIScenario[] | undefined
  setSavedScenarios: React.Dispatch<React.SetStateAction<APIScenario[] | undefined>>
  setFrequencies: React.Dispatch<React.SetStateAction<string[]>>
}

function SimulationFormSettings(props: SimulationFormSettingsProps) {
  const [t] = useTranslation()
  const { step, setStep, simulation, setFrequencies, setSimulation, savedScenarios, setSavedScenarios } = props
  const [isNew, setIsNew] = React.useState(true)
  const windOptions = ['NO_WIND', 'LIGHT_WIND', 'STRONG_WIND']
  const weatherOptions = ['CLEAR_SKY', 'LIGHT_RAIN', 'HEAVY_RAIN']
  const [simulationName, setSimulationName] = React.useState(simulation.name)

  function handleTagsChange(tags: string[]) {
    setSimulation({ ...simulation, tags: tags })
  }

  function handleSwitchNew(event: React.MouseEvent<HTMLElement>, newAlignment: boolean) {
    if (newAlignment !== null) {
      setIsNew(newAlignment)
    }
  }

  function handleClear() {
    setSimulation({
      id: null,
      name: '',
      weather: '',
      wind: '',
      roles: [],
      tags: [],
    })
    setSimulationName('')
    setFrequencies([''])
  }

  function handleContinue() {
    if (simulationName.trimStart().trimEnd() !== '' && simulation.weather !== '' && simulation.wind !== '') {
      setSimulation({ ...simulation, name: simulationName.trimStart().trimEnd() })
      setStep(step + 1)
    }
  }

  function handleNameInput(event: React.ChangeEvent<HTMLInputElement>) {
    setSimulationName(event.target.value)
  }

  function handleWeatherInput(event: SelectChangeEvent) {
    setSimulation({ ...simulation, weather: event.target.value })
  }

  function handleWindInput(event: SelectChangeEvent) {
    setSimulation({ ...simulation, wind: event.target.value })
  }

  function handleScenarioChange(event: SelectChangeEvent) {
    if (savedScenarios === undefined) return
    const scenar = savedScenarios.find((scenario) => scenario.simulation.name === event.target.value)
    if (scenar !== undefined) {
      setSimulation(scenar.simulation)
      setSimulationName(scenar.simulation.name)
      setFrequencies(Array.from(new Set(scenar.simulation.roles.map((role) => role.frequencies).flat())))
    }
  }

  function SavedScenarios() {
    if (!isNew) {
      return (
        <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
          <FormControl fullWidth sx={{ mr: 1 }}>
            <InputLabel>{t('simulations.create.settings.saved')}</InputLabel>
            <Select
              value={simulation.name}
              label={t('simulations.create.settings.saved')}
              onChange={handleScenarioChange}
            >
              {savedScenarios &&
                savedScenarios.map((scenario, index) => (
                  <MenuItem key={index} value={scenario.simulation.name}>
                    {scenario.simulation.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <SimulationFormEditScenarios
            savedScenarios={savedScenarios}
            setSavedScenarios={setSavedScenarios}
            disabled={savedScenarios?.length === 0}
          />
        </Box>
      )
    }
    return <></>
  }

  return (
    <>
      <ConferenceCreationStepper step={0} />
      <Box sx={{ width: 500, maxWidth: '90%', margin: 'auto', marginTop: '40px' }}>
        <Card variant={'elevation'} sx={{ p: 3 }}>
          <ToggleButtonGroup
            color="primary"
            value={isNew}
            exclusive
            onChange={handleSwitchNew}
            aria-label="Platform"
            sx={{ height: 50, width: '100%', mb: 2 }}
          >
            <ToggleButton value={true} sx={{ width: '100%' }}>
              {t('simulations.create.settings.switch.new')} <Add sx={{ ml: 1 }} />
            </ToggleButton>
            <ToggleButton
              value={false}
              sx={{ width: '100%' }}
              disabled={!(savedScenarios && savedScenarios.length !== 0)}
            >
              {t('simulations.create.settings.switch.load')} <UploadFile sx={{ ml: 1 }} />
            </ToggleButton>
          </ToggleButtonGroup>
          <SavedScenarios />
          <TextField
            fullWidth
            label={t('simulations.create.settings.name')}
            sx={{ mb: 2 }}
            value={simulationName}
            onChange={handleNameInput}
            error={simulationName.trim() === ''}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel error={simulation.weather === ''}>{t('simulations.create.settings.weather')}</InputLabel>
            <Select
              value={simulation.weather}
              label="Weather"
              onChange={handleWeatherInput}
              error={simulation.weather === ''}
            >
              {weatherOptions.map((weather, index) => (
                <MenuItem key={index} value={weather}>
                  {t('weather.' + weather)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel error={simulation.wind === ''}>{t('simulations.create.settings.wind')}</InputLabel>
            <Select value={simulation.wind} label="Wind" onChange={handleWindInput} error={simulation.wind === ''}>
              {windOptions.map((wind, index) => (
                <MenuItem key={index} value={wind}>
                  {t('wind.' + wind)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Autocomplete
            sx={{ mt: 2 }}
            multiple
            value={simulation.tags}
            onChange={(event, newValue) => handleTagsChange(newValue)}
            options={
              savedScenarios
                ? savedScenarios
                    .flatMap((s) => s.simulation.tags)
                    .filter((v, i, a) => a.indexOf(v) === i)
                    .sort()
                : []
            }
            freeSolo
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('simulations.create.settings.tags')}
                placeholder={t('simulations.published.addTag') ?? ''}
              />
            )}
            fullWidth
          />
          <FormHelperText sx={{ ml: 1 }}>{t('simulations.create.settings.helperTag')}</FormHelperText>
        </Card>
      </Box>
      <Box
        sx={{
          width: 500,
          maxWidth: '90%',
          margin: 'auto',
          marginTop: '40px',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <Button variant={'contained'} color={'error'} onClick={handleClear} sx={{ width: '6em' }}>
          {t('simulations.create.buttons.clear')}
        </Button>

        <Button variant={'contained'} color={'info'} onClick={handleContinue} sx={{ width: '6em' }}>
          {t('simulations.create.buttons.continue')}
        </Button>
      </Box>
    </>
  )
}

export default SimulationFormSettings
