import { Box, Button, Card, CardContent, CircularProgress, Typography } from '@mui/material'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import SimulationSocketContext from '../../../../../contexts/simulation/SimulationSocketContext'
import { SimulationStatus } from '../../../../../contexts/simulation/SimulationSocket'
import { FrequencyStateEnum } from '../../FrequencyType'
import { RoomContext, useLocalParticipant, useRemoteParticipants, useRoomContext } from '@livekit/components-react'
import { ConnectionState, LocalParticipant, Room as LivekitRoom } from 'livekit-client'
import SpatialAudioRenderer from '../../../../../utils/audio/SpatialAudioRenderer'
import { WebAudioContext } from '../../../../../utils/audio/webAudio'

interface AudioComponentProps {
  frequency: string
  frequencyState: FrequencyStateEnum
  setFrequencyState: (frequencyState: FrequencyStateEnum) => void
  setFrequencySid: (sid: string) => void
  audioContext: AudioContext
}

let localParticipantBackup: LocalParticipant
let setFrequencySidBackup: (sid: string) => void

const AudioComponent = (props: AudioComponentProps) => {
  const { frequency, frequencyState, setFrequencyState, setFrequencySid, audioContext } = props
  const participant = useLocalParticipant()
  const [mic, setMic] = useState(false)
  const [audio, setAudio] = useState(false)
  const participants = useRemoteParticipants()
  const simulationSocket = useContext(SimulationSocketContext)
  const freqStatus = simulationSocket?.freqStatus
  const privateCallStatus = simulationSocket?.privateCall.status
  const mode = localStorage.getItem('mode')
  const isTx = frequencyState === FrequencyStateEnum.Tx
  const isRx = frequencyState === FrequencyStateEnum.Rx
  const room = useRoomContext()
  const [isTrackInitialized, setIsTrackInitialized] = useState(false)

  localParticipantBackup = participant.localParticipant
  setFrequencySidBackup = setFrequencySid

  useEffect(() => {
    localParticipantBackup.setMicrophoneEnabled(false).then().catch(console.error)
    setFrequencySidBackup(participant.microphoneTrack?.trackSid || '')
  }, [participant.microphoneTrack?.trackSid, isTrackInitialized])

  useEffect(() => {
    if (!isTrackInitialized) {
      if (room.state === ConnectionState.Connected) {
        if (!participant.microphoneTrack) {
          localParticipantBackup
            .setMicrophoneEnabled(true)
            .then(() => {
              setIsTrackInitialized(true)
            })
            .catch(console.error)
        }
      }
    }
  }, [room.state, participant.microphoneTrack, isTrackInitialized])

  // const p = useRemoteParticipant(participant.localParticipant.identity)
  // p?.setAudioOutput()=

  useEffect(() => {
    if (audio) {
      participants.forEach((p) => {
        p.audioTracks.forEach((t) => {
          if (!t.isEnabled) {
            t.setEnabled(true)
          }
        })
      })
    } else {
      participants.forEach((p) => {
        p.audioTracks.forEach((t) => {
          if (t.isEnabled) {
            t.setEnabled(false)
          }
        })
      })
    }
  }, [participants, audio])

  const handleClickAudio = (sound: boolean) => {
    if (!sound) {
      setAudio(false)
    } else {
      if (audioContext.state === 'suspended') {
        audioContext.resume().then().catch(console.error)
      }
      setAudio(true)
    }
  }

  const handleClickMic = () => {
    if (!audio) {
      handleClickAudio(true)
    }
    // participant.localParticipant.setMicrophoneEnabled(!mic).then().catch()
    setMic(!mic)
  }

  // if (room.state === ConnectionState.Connecting || room.state === ConnectionState.Reconnecting) {
  //   return (
  //     <Card
  //       sx={{
  //         boxShadow: 5,
  //         m: 1,
  //         height: '135px',
  //         width: '160px',
  //         borderRadius: 3,
  //         border: '2px solid',
  //         borderWidth: '2px',
  //         borderColor:
  //           freqStatus && freqStatus[frequency] == SimulationStatus.USER_SPEAKING && isTx
  //             ? 'yellowgreen'
  //             : 'transparent',
  //       }}
  //     >
  //       <CardContent
  //         sx={{
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           height: '100%',
  //         }}
  //       >
  //         {/*    centered circular progress */}
  //         <CircularProgress />
  //       </CardContent>
  //     </Card>
  //   )
  // }

  return (
    <>
      <SpatialAudioRenderer position={privateCallStatus === 'IN_CALL' ? 'LEFT' : 'CENTER'} />
      {/*<Button onClick={() => handleClickMic()}> {mic ? 'Mute' : 'Unmute'} Mic</Button>*/}
      {/*<Button onClick={() => handleClickAudio()}> {audio ? 'Mute' : 'Unmute'} Audio</Button>*/}

      <Card
        sx={{
          boxShadow: 5,
          m: 1,
          height: '135px',
          width: '175px',
          borderRadius: 3,
          border: '2px solid',
          borderWidth: '2px',
          borderColor:
            freqStatus && freqStatus[frequency] == SimulationStatus.USER_SPEAKING && isTx
              ? 'yellowgreen'
              : 'transparent',
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <Typography
              color={mode === '"dark"' ? 'primary.main' : 'secondary.main'}
              gutterBottom
              variant="h6"
              sx={{
                borderWidth: 1,
                borderRadius: 3,
                bgcolor: mode === '"dark"' ? 'background.default' : 'primary.light',
                mb: 2,
                width: '90%',
                mr: 0.5,
                whiteSpace: 'nowrap',
              }}
            >
              {frequency.substring('radio_'.length).replace('_', '.')}
            </Typography>
            <Box
              sx={{
                width: '18px',
                height: '18px',
                border: '2px solid',
                backgroundColor:
                  (isRx || isTx) && freqStatus
                    ? freqStatus[frequency] === SimulationStatus.OTHER_SPEAKING
                      ? 'red'
                      : 'transparent'
                    : 'transparent',
                borderColor:
                  (isRx || isTx) && freqStatus
                    ? freqStatus[frequency] === SimulationStatus.OTHER_SPEAKING
                      ? 'red'
                      : 'primary.main'
                    : 'transparent',
                ml: 0.5,
                transform: 'translate(0, 6px)',
                borderRadius: '50%',
                position: isRx || isTx ? 'relative' : 'absolute',
              }}
            ></Box>
          </Box>
          {room.state === ConnectionState.Connecting || room.state === ConnectionState.Reconnecting ? (
            <CircularProgress />
          ) : room.state === ConnectionState.Disconnected ? (
            <Typography color={mode === '"dark"' ? 'primary.main' : 'secondary.dark'} gutterBottom variant="h6">
              Disconnected
            </Typography>
          ) : (
            <>
              <Button
                variant="outlined"
                sx={{
                  fontSize: 20,
                  height: '50px',
                  width: '50px',
                  mr: 1,
                  boxShadow: isTx ? 5 : 0,
                  borderWidth: isTx ? 3 : 1,
                  bgcolor: isTx
                    ? mode === '"dark"'
                      ? 'rgba(155, 155, 255, 0.15)'
                      : 'rgba(0, 0, 255, 0.15)'
                    : 'transparent',
                  '&:hover': {
                    borderWidth: isTx ? 3 : 1,
                    bgcolor: isTx
                      ? mode === '"dark"'
                        ? 'rgba(155, 155, 255, 0.3)'
                        : 'rgba(0, 0, 255, 0.2)'
                      : 'transparent',
                  },
                }}
                onClick={() => {
                  if (frequencyState === FrequencyStateEnum.Tx) {
                    handleClickMic()
                    setFrequencyState(FrequencyStateEnum.Rx)
                  } else {
                    handleClickMic()
                    setFrequencyState(FrequencyStateEnum.Tx)
                  }
                }}
              >
                Tx
              </Button>
              <Button
                variant="outlined"
                sx={{
                  fontSize: 18,
                  height: '50px',
                  width: '50px',
                  ml: 1,
                  boxShadow: isRx ? 5 : 0,
                  borderWidth: isRx ? 3 : 1,
                  bgcolor: isRx
                    ? mode === '"dark"'
                      ? 'rgba(155, 155, 255, 0.15)'
                      : 'rgba(0, 0, 255, 0.15)'
                    : 'transparent',
                  '&:hover': {
                    borderWidth: isRx ? 3 : 1,
                    bgcolor: isRx
                      ? mode === '"dark"'
                        ? 'rgba(155, 155, 255, 0.3)'
                        : 'rgba(0, 0, 255, 0.2)'
                      : 'transparent',
                  },
                }}
                onClick={() => {
                  if (frequencyState === FrequencyStateEnum.Rx) {
                    handleClickAudio(false)
                    setFrequencyState(FrequencyStateEnum.Idle)
                  } else if (frequencyState === FrequencyStateEnum.Tx) {
                    handleClickMic()
                    setFrequencyState(FrequencyStateEnum.Rx)
                  } else {
                    handleClickAudio(true)
                    setFrequencyState(FrequencyStateEnum.Rx)
                  }
                }}
              >
                Rx
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </>
  )
}

export interface CardFrequencyProps {
  frequency: string
  frequencyState: FrequencyStateEnum
  livekitRoom: LivekitRoom
  audioContext: AudioContext
  setFrequencyState: (frequencyState: FrequencyStateEnum) => void
  setFrequencySid: (frequencySid: string) => void
}

const CardFrequency = (props: CardFrequencyProps) => {
  const { frequency, frequencyState, setFrequencyState, setFrequencySid, livekitRoom, audioContext } = props
  useEffect(() => {}, [livekitRoom, audioContext, frequency])

  return (
    <RoomContext.Provider value={livekitRoom}>
      <WebAudioContext.Provider value={audioContext}>
        <AudioComponent
          frequency={frequency}
          frequencyState={frequencyState}
          setFrequencyState={setFrequencyState}
          setFrequencySid={setFrequencySid}
          audioContext={audioContext}
        />
      </WebAudioContext.Provider>
    </RoomContext.Provider>
  )
}

export default CardFrequency
