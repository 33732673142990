import { useContext, useEffect, useState } from 'react'
import { SimulationSocket, SimulationStatus } from './SimulationSocket'
import useWebSocket from 'react-use-websocket'
import UserContext from '../user/UserContext'
import APIresources from '../../api-resources'
import { Room } from './Room'
import { PrivateCall } from './PrivateCall'
import { Room as LivekitRoom } from 'livekit-client'
import { useGlobalAudioPlayer } from 'react-use-audio-player'

const apiUrl = APIresources.api_simulation_url
const audioSimulationWsUrl = APIresources.audio_simulation_ws_url ?? ''

const livekitRoom = new LivekitRoom()
const audioContext = new AudioContext()

const useSimulationSocket = () => {
  const frontUser = useContext(UserContext)
  const [simulationId, setSimulationId] = useState<string | undefined>(undefined)
  const [frequencies, setFrequencies] = useState<Room[]>([])
  const [isError, setIsError] = useState<boolean>(false)
  const [freqStatus, setFreqStatus] = useState<{ [room: string]: SimulationStatus }>({})
  const audioPlayer = useGlobalAudioPlayer()
  const [inputDevice, setInputDevice] = useState<MediaDeviceInfo | undefined>(
    localStorage.getItem('inputDevice') ? JSON.parse(localStorage.getItem('inputDevice') ?? '') : undefined,
  )
  const [outputDevice, setOutputDevice] = useState<MediaDeviceInfo | undefined>(
    localStorage.getItem('outputDevice') ? JSON.parse(localStorage.getItem('outputDevice') ?? '') : undefined,
  )
  const [privateCall, setPrivateCall] = useState<PrivateCall>({
    owner: null,
    room: null,
    token: null,
    lastCalledUser: null,
    livekitRoom: livekitRoom,
    audioContext: audioContext,
    status: 'NO_CALL',
    users: [],
  })
  const [incomingPrivateCalls, setIncomingPrivateCalls] = useState<PrivateCall[]>([])
  const [pausedPrivateCalls, setPausedPrivateCalls] = useState<PrivateCall[]>([])
  const [simulationSocket, setSimulationSocket] = useState<SimulationSocket | undefined>({
    webSocket: null,
    isOpened: false,
    open: (id: string) => {
      setSimulationId(id)
      setSimulationSocket((prevSimulationSocket) => {
        if (!prevSimulationSocket) return undefined
        return {
          ...prevSimulationSocket,
          isOpened: true,
        }
      })
    },
    close: () => {
      setSimulationSocket((prevSimulationSocket) => {
        if (!prevSimulationSocket) return undefined
        return {
          ...prevSimulationSocket,
          webSocket: null,
          isOpened: false,
        }
      })
      setPrivateCall((prevPrivateCall) => {
        prevPrivateCall.livekitRoom.disconnect().then().catch(console.error)
        return {
          ...prevPrivateCall,
          owner: null,
          room: null,
          token: null,
          lastCalledUser: null,
          status: 'NO_CALL',
          users: [],
          livekitRoom: new LivekitRoom(),
          audioContext: new AudioContext(),
        }
      })
      setFrequencies([])
    },
  })

  const setActiveMediaDevices = (room: LivekitRoom) => {
    if (inputDevice) {
      livekitRoom.switchActiveDevice('audioinput', inputDevice?.deviceId).then().catch(console.error)
    }
    if (outputDevice) {
      livekitRoom.switchActiveDevice('audiooutput', outputDevice?.deviceId).then().catch(console.error)
    }
  }

  const websocket = useWebSocket(
    simulationSocket?.isOpened ? apiUrl + '/simulation/' + simulationId + '/' + frontUser?.user?.username : null,
    {
      queryParams: {
        token: frontUser?.token ?? '',
      },
      onClose: () => {
        setSimulationSocket((prevSimulationSocket) => {
          if (!prevSimulationSocket) return undefined
          return {
            ...prevSimulationSocket,
            webSocket: null,
            isOpened: false,
          }
        })
      },
      onMessage: (event) => {
        const data = JSON.parse(event.data)
        console.log(data)
        if (data.error) {
          setIsError(true)
          return
        }
        switch (data.response) {
          // **************** FREQUENCIES ****************
          case 'USER_UNMUTED' || 'USER_ALREADY_SPEAKING':
            if (data.username == frontUser?.user?.username) {
              setFreqStatus((prevFreqStatus) => {
                return {
                  ...prevFreqStatus,
                  [data.data.room]: SimulationStatus.USER_SPEAKING,
                }
              })
            } else {
              setFreqStatus((prevFreqStatus) => {
                return {
                  ...prevFreqStatus,
                  [data.data.room]: SimulationStatus.OTHER_SPEAKING,
                }
              })
            }
            break
          case 'USER_MUTED':
            setFreqStatus((prevFreqStatus) => {
              return {
                ...prevFreqStatus,
                [data.data.room]: SimulationStatus.NOTHING,
              }
            })
            break
          case 'TOKENS_GENERATED': {
            const rooms = data.data as { [room: string]: string }
            const roomsArray: Room[] = []
            for (const room in rooms) {
              roomsArray.push({
                name: room,
                token: rooms[room],
                livekitRoom: new LivekitRoom(),
                audioContext: new AudioContext(),
              })
            }
            roomsArray.sort((a, b) => {
              const cleanedA = a.name.substring('radio_'.length).replace('_', '.')
              const cleanedB = b.name.substring('radio_'.length).replace('_', '.')
              const aName: number = parseFloat(cleanedA)
              const bName: number = parseFloat(cleanedB)

              if (isNaN(aName) && isNaN(bName)) {
                if (cleanedA < cleanedB) return -1
                if (cleanedA > cleanedB) return 1
                return 0
              }
              if (isNaN(aName)) return -1
              if (isNaN(bName)) return 1
              if (aName < bName) return -1
              if (aName > bName) return 1
              return 0
            })
            setFrequencies(roomsArray)
            for (const room of roomsArray) {
              navigator.mediaDevices
                .getUserMedia({
                  audio: true,
                })
                .then(() => {
                  room.livekitRoom
                    .connect(audioSimulationWsUrl, room.token, {
                      rtcConfig: {
                        iceTransportPolicy: 'relay',
                      },
                    })
                    .then(() => {
                      console.log('Connected to room ' + room.name)
                      room.livekitRoom
                        .startAudio()
                        .then(() => {
                          console.log('Audio started for room ' + room.name)
                          room.livekitRoom.localParticipant.setMicrophoneEnabled(false).then().catch(console.error)
                          setActiveMediaDevices(room.livekitRoom)
                          console.log('ICE SERVER: ', room.livekitRoom.engine.latestJoinResponse?.iceServers)
                        })
                        .catch(console.error)
                    })
                    .catch((err) => {
                      console.error('Failed to connect', err)
                    })
                })
                .catch(console.error)
            }
            break
          }
          // **************** PRIVATE CALL ****************
          case 'CALL_SUCCESS': {
            const owner = data.privateCallState.owner
            const roomName = data.privateCallState.roomName
            const token = data.token
            const lastCalledUser = data.privateCallState.lastCalledUser
            const users = data.privateCallState.users

            setPrivateCall((prevPrivateCall) => {
              const ac = prevPrivateCall.audioContext ?? new AudioContext()
              ac.resume().then().catch(console.error)
              return {
                ...privateCall,
                owner: owner,
                room: roomName,
                token: token,
                lastCalledUser: lastCalledUser,
                status: 'CALLING',
                users: users,
                audioContext: ac,
                livekitRoom: prevPrivateCall.livekitRoom ?? new LivekitRoom(),
              }
            })
            break
          }
          case 'CALL_RECEIVED': {
            const owner = data.privateCallState.owner
            const roomName = data.privateCallState.roomName
            const lastCalledUser = data.privateCallState.lastCalledUser
            const users = data.privateCallState.users

            if (privateCall.status !== 'NO_CALL') {
              setIncomingPrivateCalls((prevIncomingPrivateCalls) => {
                return [
                  ...prevIncomingPrivateCalls,
                  {
                    owner: owner,
                    room: roomName,
                    token: null,
                    lastCalledUser: lastCalledUser,
                    livekitRoom: privateCall.livekitRoom ?? new LivekitRoom(),
                    audioContext: privateCall.audioContext ?? new AudioContext(),
                    status: 'RECEIVING_CALL',
                    users: users,
                  },
                ]
              })
            } else {
              audioPlayer.load('/ringtone.mp3', { autoplay: true, loop: true })
              setPrivateCall({
                ...privateCall,
                owner: owner,
                room: roomName,
                token: null,
                lastCalledUser: lastCalledUser,
                status: 'RECEIVING_CALL',
                users: users,
              })
              setIncomingPrivateCalls((prevIncomingPrivateCalls) => {
                return [
                  ...prevIncomingPrivateCalls,
                  {
                    owner: owner,
                    room: roomName,
                    token: null,
                    lastCalledUser: lastCalledUser,
                    livekitRoom: privateCall.livekitRoom ?? new LivekitRoom(),
                    audioContext: privateCall.audioContext ?? new AudioContext(),
                    status: 'RECEIVING_CALL',
                    users: users,
                  },
                ]
              })
            }
            break
          }
          case 'CALL_REJECTED': {
            const roomName = data.privateCallState.roomName

            setIncomingPrivateCalls((prevIncomingPrivateCalls) => {
              return prevIncomingPrivateCalls.filter((call) => call.room !== roomName)
            })
            if (
              privateCall.room === roomName &&
              (privateCall.status === 'RECEIVING_CALL' || privateCall.status === 'CALLING')
            ) {
              audioPlayer.stop()
              setPrivateCall({
                ...privateCall,
                owner: null,
                room: null,
                token: null,
                lastCalledUser: null,
                status: 'NO_CALL',
              })
            }
            break
          }
          case 'CALL_ACCEPTED': {
            const roomName = data.privateCallState.roomName
            const token = data.token
            const users = data.privateCallState.users
            const incomingCall = incomingPrivateCalls.find((call) => call.room === roomName)

            if (privateCall.room === roomName) {
              if (privateCall.status === 'RECEIVING_CALL') {
                audioPlayer.stop()
                setIncomingPrivateCalls((prevIncomingPrivateCalls) => {
                  return prevIncomingPrivateCalls.filter((call) => call.room !== roomName)
                })
                setPrivateCall((prevPrivateCall) => {
                  navigator.mediaDevices
                    .getUserMedia({
                      audio: true,
                    })
                    .then(() => {
                      prevPrivateCall.livekitRoom
                        .connect(audioSimulationWsUrl, token, {
                          rtcConfig: {
                            iceTransportPolicy: 'relay',
                          },
                        })
                        .then(() => {
                          privateCall.livekitRoom
                            .startAudio()
                            .then(() => {
                              setActiveMediaDevices(prevPrivateCall.livekitRoom)
                            })
                            .catch(console.error)
                          if (!privateCall.livekitRoom.localParticipant.isMicrophoneEnabled) {
                            privateCall.livekitRoom.localParticipant
                              .setMicrophoneEnabled(true)
                              .then()
                              .catch(console.error)
                          }
                        })
                        .catch(console.error)
                    })
                    .catch(console.error)

                  return {
                    ...privateCall,
                    token: token,
                    status: 'IN_CALL',
                    users: users,
                  }
                })
              } else if (privateCall.status === 'CALLING') {
                setPrivateCall((prevPrivateCall) => {
                  if (prevPrivateCall.token != null) {
                    navigator.mediaDevices
                      .getUserMedia({
                        audio: true,
                      })
                      .then(() => {
                        prevPrivateCall.livekitRoom
                          .connect(audioSimulationWsUrl, prevPrivateCall.token ?? '', {
                            rtcConfig: {
                              iceTransportPolicy: 'relay',
                            },
                          })
                          .then(() => {
                            privateCall.livekitRoom
                              .startAudio()
                              .then(() => {
                                setActiveMediaDevices(prevPrivateCall.livekitRoom)
                              })
                              .catch(console.error)
                            if (!privateCall.livekitRoom.localParticipant.isMicrophoneEnabled) {
                              privateCall.livekitRoom.localParticipant
                                .setMicrophoneEnabled(true)
                                .then()
                                .catch(console.error)
                            }
                          })
                          .catch(console.error)
                      })
                      .catch(console.error)
                  }
                  return {
                    ...privateCall,
                    status: 'IN_CALL',
                    users: users,
                  }
                })
              }
            } else if (incomingCall) {
              audioPlayer.stop()
              if (privateCall.status === 'IN_CALL') {
                privateCall.livekitRoom.disconnect().then().catch(console.error)
                websocket.sendJsonMessage({
                  user: {
                    username: privateCall.lastCalledUser?.username,
                    nickname: privateCall.lastCalledUser?.nickname,
                  },
                  action: 'DISCONNECT_CALL',
                  target: null,
                  room: privateCall.room,
                })
              }
              if (privateCall.status === 'CALLING') {
                websocket.sendJsonMessage({
                  user: {
                    username: frontUser?.user?.username,
                    nickname: frontUser?.user?.nickname,
                  },
                  action: 'REJECT_CALL',
                  target: {
                    username: privateCall.lastCalledUser?.username,
                    nickname: privateCall.lastCalledUser?.nickname,
                  },
                  room: privateCall.room,
                })
              }
              setPrivateCall((prevPrivateCall) => {
                navigator.mediaDevices
                  .getUserMedia({
                    audio: true,
                  })
                  .then(() => {
                    prevPrivateCall.livekitRoom
                      .connect(audioSimulationWsUrl, token, {
                        rtcConfig: {
                          iceTransportPolicy: 'relay',
                        },
                      })
                      .then(() => {
                        privateCall.livekitRoom
                          .startAudio()
                          .then(() => {
                            setActiveMediaDevices(prevPrivateCall.livekitRoom)
                          })
                          .catch(console.error)
                        if (!privateCall.livekitRoom.localParticipant.isMicrophoneEnabled) {
                          privateCall.livekitRoom.localParticipant
                            .setMicrophoneEnabled(true)
                            .then()
                            .catch(console.error)
                        }
                      })
                      .catch(console.error)
                  })
                  .catch(console.error)
                return {
                  ...prevPrivateCall,
                  owner: incomingCall.owner,
                  room: incomingCall.room,
                  token: token,
                  lastCalledUser: incomingCall.lastCalledUser,
                  status: 'IN_CALL',
                  users: users,
                }
              })

              setIncomingPrivateCalls((prevIncomingPrivateCalls) => {
                return prevIncomingPrivateCalls.filter((call) => call.room !== roomName)
              })
            }
            break
          }
          case 'CALL_DISCONNECTED': {
            const lastDisconnectedUser = data.privateCallState.lastDisconnectedUser
            const users = data.privateCallState.users

            if (privateCall.status === 'IN_CALL' && privateCall.room === data.privateCallState.roomName) {
              if (lastDisconnectedUser.username == frontUser?.user?.username) {
                privateCall.livekitRoom.disconnect().then(() => {
                  setPrivateCall((prevPrivateCall) => {
                    return {
                      ...prevPrivateCall,
                      owner: null,
                      room: null,
                      token: null,
                      lastCalledUser: null,
                      status: 'NO_CALL',
                      users: [],
                    }
                  })
                })
              } else if (data.privateCallState.roomName === privateCall.room) {
                if (users.length === 0) {
                  privateCall.livekitRoom
                    .disconnect()
                    .then(() => {
                      setPrivateCall((prevPrivateCall) => {
                        return {
                          ...prevPrivateCall,
                          owner: null,
                          room: null,
                          token: null,
                          lastCalledUser: null,
                          status: 'NO_CALL',
                          users: [],
                        }
                      })
                    })
                    .catch(console.error)
                } else {
                  setPrivateCall((prevPrivateCall) => {
                    return {
                      ...prevPrivateCall,
                      users: users,
                    }
                  })
                }
              }
            } else if (pausedPrivateCalls.length > 0) {
              const pausedCall = pausedPrivateCalls.find((call) => call.room === data.privateCallState.roomName)
              if (pausedCall) {
                if (users.length === 0) {
                  pausedCall.livekitRoom
                    .disconnect()
                    .then(() => {
                      setPausedPrivateCalls((prevPausedPrivateCalls) => {
                        return prevPausedPrivateCalls.filter((call) => call.room !== data.privateCallState.roomName)
                      })
                    })
                    .catch(console.error)
                } else {
                  setPausedPrivateCalls((prevPausedPrivateCalls) => {
                    //   update the users in the paused call
                    return prevPausedPrivateCalls.map((call) => {
                      if (call.room === data.privateCallState.roomName) {
                        return {
                          ...call,
                          users: users,
                        }
                      } else {
                        return call
                      }
                    })
                  })
                }
              }
            }
            break
          }
        }
      },
      onOpen: () => {
        setSimulationSocket((prevSimulationSocket) => {
          if (!prevSimulationSocket) return undefined
          return {
            ...prevSimulationSocket,
            webSocket: websocket,
            isOpened: true,
          }
        })
      },
    },
  )

  useEffect(() => {
    const interval = setInterval(() => {
      websocket.sendJsonMessage({ alive: true })
    }, 10000)
    return () => clearInterval(interval)
  })

  const pausePrivateCall = () => {
    privateCall.livekitRoom.localParticipant.setMicrophoneEnabled(false).then().catch(console.error)
    setPausedPrivateCalls((prevPausedPrivateCalls) => {
      return [...prevPausedPrivateCalls, privateCall]
    })
    setPrivateCall((prevPrivateCall) => {
      return {
        ...prevPrivateCall,
        owner: null,
        room: null,
        token: null,
        livekitRoom: new LivekitRoom(),
        lastCalledUser: null,
        status: 'NO_CALL',
        users: [],
      }
    })
  }

  const resumePrivateCall = (pc: PrivateCall) => {
    if (privateCall.status === 'IN_CALL') {
      privateCall.livekitRoom.disconnect().then().catch(console.error)

      websocket.sendJsonMessage({
        user: {
          username: frontUser?.user?.username,
          nickname: frontUser?.user?.nickname,
        },
        action: 'DISCONNECT_CALL',
        target: null,
        room: privateCall.room,
      })
    }
    setPrivateCall(pc)
    setPausedPrivateCalls((prevPausedPrivateCalls) => {
      return prevPausedPrivateCalls.filter((call) => call.room !== pc.room)
    })
    pc.livekitRoom.localParticipant.setMicrophoneEnabled(true).then().catch(console.error)
  }

  return {
    simulationSocket,
    freqStatus,
    frequencies,
    privateCall,
    incomingPrivateCalls,
    pausedPrivateCalls,
    pausePrivateCall,
    resumePrivateCall,
    isError,
    mediaDevices: {
      inputDevice: inputDevice,
      outputDevice: outputDevice,
      setInputDevice: (device?: MediaDeviceInfo) => {
        setInputDevice(device)
        localStorage.setItem('inputDevice', JSON.stringify(device))
      },
      setOutputDevice: (device?: MediaDeviceInfo) => {
        setOutputDevice(device)
        localStorage.setItem('outputDevice', JSON.stringify(device))
      },
    },
  }
}

export default useSimulationSocket
