import DialogTitle from '@mui/material/DialogTitle'
import { Box, Chip, Typography } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import * as React from 'react'
import { t } from 'i18next'
import { APISimulationRequest } from '../../../../rest/simulation/models/APISimulationRequest'
import { APISimulationRole } from '../../../../rest/simulation/models/APISimulationRole'
import { useTheme } from '@mui/material/styles'

interface SimulationRequestRoleComponentProps {
  role: APISimulationRole
  simulation: APISimulationRequest
  open: boolean
  onClose: (value?: string) => void
  value: string
}

function SimulationRequestRoleDetails(SimulationRequestRoleComponentProps: SimulationRequestRoleComponentProps) {
  const { role, simulation, open, onClose, value, ...other } = SimulationRequestRoleComponentProps
  const radioGroupRef = React.useRef<HTMLElement>(null)
  const theme = useTheme()
  const mode = theme.palette.mode

  const handleOk = () => {
    onClose(value)
  }

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }

  const simulationStyle = {
    fwContainer: {
      fontSize: '1.1em',
      borderRadius: 1,
      p: 1,
      width: '100%',
      // border: '1px solid #517788',
      backgroundColor: 'background.paper',
      boxShadow: 2,
      '&:hover': {
        filter: mode === 'dark' ? 'brightness(1.2)' : 'brightness(0.9)',
      },
    },
    container: {
      fontSize: '1.1em',
      borderRadius: 1,
      backgroundColor: 'background.paper',
      p: 1,
      // border: '1px solid #517788',
      boxShadow: 2,
      '&:hover': {
        filter: mode === 'dark' ? 'brightness(1.2)' : 'brightness(0.9)',
      },
    },
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 500, bgcolor: 'background.default' } }}
      maxWidth="md"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '1.5em' }}>{t('simulations.published.roles.details.title')}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography
            sx={{
              mr: 2,
              ...simulationStyle.fwContainer,
            }}
            noWrap
          >
            <Typography component="span" sx={{ color: 'text.secondary' }} display="inline">
              {t('simulations.create.settings.name') + ' : '}
            </Typography>
            {simulation.simulation.name}
          </Typography>

          <Typography
            sx={{
              ...simulationStyle.fwContainer,
            }}
          >
            <Typography component="span" sx={{ color: 'text.secondary' }} display="inline">
              {t('simulations.create.settings.weather') + ' : '}
            </Typography>
            {t('weather.' + simulation.simulation.weather)}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography
            sx={{
              mr: 2,
              ...simulationStyle.fwContainer,
            }}
            noWrap
          >
            <Typography component="span" sx={{ color: 'text.secondary' }} display="inline">
              {t('simulations.create.roles.name') + ' : '}
            </Typography>
            {role.name}
          </Typography>
          <Typography
            sx={{
              ...simulationStyle.fwContainer,
            }}
          >
            <Typography component="span" sx={{ color: 'text.secondary' }} display="inline">
              {t('simulations.create.roles.roleType') + ' : '}
            </Typography>
            {t('roleTypes.' + role.roleType)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography
            sx={{
              mr: 2,
              width: '13em',
              ...simulationStyle.container,
            }}
          >
            <Typography component="span" sx={{ color: 'text.secondary' }} display="inline">
              {t('simulations.create.roles.age') + ' : '}
            </Typography>
            {role.minAge} - {role.maxAge}
          </Typography>
          <Typography
            sx={{
              ...simulationStyle.fwContainer,
            }}
          >
            <Typography component="span" sx={{ color: 'text.secondary' }} display="inline">
              {t('simulations.create.roles.gender') + ' : '}
            </Typography>
            {t('gender.' + role.gender)}
          </Typography>
        </Box>
        <Box
          sx={{
            mb: 2,
            ...simulationStyle.container,
          }}
        >
          <Typography component="span" sx={{ color: 'text.secondary' }} display="inline">
            {t('simulations.create.roles.languages') + ' : '}
          </Typography>
          {role.languages.map((language) => (
            <Chip key={language} label={t('languages.' + language)} sx={{ fontSize: '1rem', m: 0.2 }} />
          ))}
        </Box>
        <Box
          sx={{
            mb: 2,
            ...simulationStyle.container,
          }}
        >
          <Typography component="span" sx={{ color: 'text.secondary' }} display="inline">
            {t('simulations.create.roles.accents') + ' : '}
          </Typography>
          {role.accents.map((accent) => (
            <Chip key={accent} label={t('accents.' + accent)} sx={{ fontSize: '1rem', m: 0.2 }} />
          ))}
        </Box>
        <Box
          sx={{
            ...simulationStyle.container,
          }}
        >
          <Typography component="span" sx={{ color: 'text.secondary' }} display="inline">
            {t('simulations.create.roles.frequencies') + ' : '}
          </Typography>
          {role.frequencies.map((frequency) => (
            <Chip
              key={frequency}
              label={frequency.substring('radio_'.length).replace('_', '.')}
              sx={{ fontSize: '1rem', m: 0.2 }}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOk()}>{t('simulations.create.settings.edit.close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SimulationRequestRoleDetails
