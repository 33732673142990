export default {
  home: 'Accueil',
  account: 'Compte',
  profile: 'Profil',
  admin: 'Admin',
  adminAccountList: 'Liste des comptes',
  adminEditAccount: 'Éditer le compte (id:{{accountId}})',
  adminSimulationHistory: 'Historique des simulation requests',
  simulations: 'Simulations',
  simulationsCreate: 'Créer une simulation',
  simulationsList: 'Simulations publiées',
  simulationsViewRoles: 'Rôles de la simulation n°{{simulationId}}',
  simulationsRoles: 'Rôles',
  simulationsHistory: 'Historique',
  simulationsManage: 'Gérer',
  simulationsManageRoles: 'Gérer les rôles',
  runningSimulation: 'Simulation en cours',
  403: 'Erreur 403',
  404: 'Erreur 404',
  500: 'Erreur 500',
}
