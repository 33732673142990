import React, { useCallback, useEffect, useState } from 'react'
import { useUserContext } from '../../hooks/ContextHooks'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { Box, Typography } from '@mui/material'
import { APIIncomingParticipationType } from '../../rest/simulation/models/APIIncomingParticipationType'
import { APIActiveParticipationType } from '../../rest/simulation/models/APIActiveParticipationType'
import {
  getActiveSimulationsParticipationByUsername,
  getIncomingSimulationsParticipationByUsername,
} from '../../rest/simulation/queries/SimulationParticipationQueries'

const useJoinedSimulations = () => {
  const [activeParticipationList, setActiveParticipationList] = useState<APIActiveParticipationType[] | undefined>(
    undefined,
  )
  const [oldParticipationList, setOldParticipationList] = useState<APIActiveParticipationType[] | undefined>(undefined)
  const [incomingParticipationRequestsList, setIncomingParticipationRequestsList] = useState<
    APIIncomingParticipationType[] | undefined
  >(undefined)
  const [participationRequestsList, setParticipationRequestsList] = useState<
    APIIncomingParticipationType[] | undefined
  >(undefined)

  const user = useUserContext()
  const { t } = useTranslation()

  const refreshJoinedSimulations = useCallback(() => {
    if (!user || !user.user) {
      setActiveParticipationList(undefined)
      setOldParticipationList(undefined)
      setIncomingParticipationRequestsList(undefined)
      setParticipationRequestsList(undefined)
      return
    }

    getIncomingSimulationsParticipationByUsername(user.user.username)
      .then((response) => {
        setIncomingParticipationRequestsList(
          response.filter(
            (participation) =>
              participation.simulation.status === 'VALIDATED' && participation.acceptanceStatus === 'ACCEPTED',
          ),
        )
        setParticipationRequestsList(response)
        // setUnconfirmedParticipationList(
        //   response.filter((participation) => participation.simulation.status === 'PUBLISHED'),
        // )
        // setOldParticipationRequestsList(
        //   response.filter(
        //     (participation) =>
        //       participation.simulation.status === 'CANCELED' ||
        //       participation.simulation.status === 'FINISHED' ||
        //       participation.simulation.status === 'STARTED',
        //   ),
        // )
      })
      .catch((error) => {
        console.log(error)
        setIncomingParticipationRequestsList(undefined)
        toast.error(
          () => (
            <Box textAlign={'center'}>
              <Typography variant={'body1'}>{t('toast.simulations.joined.error_title')}</Typography>
              <Typography variant={'body1'}>{t('toast.simulations.joined.error_description')}</Typography>
            </Box>
          ),
          {
            duration: 7000,
          },
        )
      })

    getActiveSimulationsParticipationByUsername(user.user.username)
      .then((response) => {
        setActiveParticipationList(
          response.filter(
            (participation) =>
              participation.simulation.status === 'ACTIVE' || participation.simulation.status === 'PENDING',
          ),
        )
        setOldParticipationList(
          response.filter(
            (participation) =>
              participation.simulation.status === 'DONE' || participation.simulation.status === 'DELETED',
          ),
        )
      })
      .catch((error) => {
        console.log(error)
        setActiveParticipationList(undefined)
        setOldParticipationList(undefined)
        toast.error(
          () => (
            <Box textAlign={'center'}>
              <Typography variant={'body1'}>{t('toast.simulations.joined.error_title')}</Typography>
              <Typography variant={'body1'}>{t('toast.simulations.joined.error_description')}</Typography>
            </Box>
          ),
          {
            duration: 7000,
          },
        )
      })
  }, [user, t])

  useEffect(() => {
    refreshJoinedSimulations()
  }, [refreshJoinedSimulations])

  return {
    activeParticipationList,
    oldParticipationList,
    incomingParticipationRequestsList,
    participationRequestsList,
  }
}

export default useJoinedSimulations
