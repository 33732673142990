import React, { ReactNode, useContext, useEffect } from 'react'
import * as AmplifyUI from '@aws-amplify/ui-react'
import { Authenticator } from '@aws-amplify/ui-react'
import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomBreadcrumbs from '../global/CustomBreadcrumbs'
import Footer from '../global/Footer'
import NavBar from '../global/NavBar'
import { ColorModeContext } from '../../App'
import { useTranslation } from 'react-i18next'
import JoinedSimulationsProvider from '../../contexts/joined-simulations/JoinedSimulationsProvider'

export interface CustomAuthenticatorProps {
  noFooter?: boolean
}

const CustomAuthenticator = (props: { children: ReactNode } & CustomAuthenticatorProps) => {
  const noFooter = props.noFooter ?? false
  const { children } = props
  const navigate = useNavigate()
  const colorMode = useContext(ColorModeContext)
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const handleClickCreateAccount = () => {
    navigate('/sign-up')
  }

  const components = {
    Footer() {
      const { t } = useTranslation()
      return (
        <>
          <Box textAlign="center">
            <AmplifyUI.Button
              size={'small'}
              variation={'link'}
              onClick={() => handleClickCreateAccount()}
              style={{
                marginBottom: '1rem',
              }}
            >
              {t('home.loginPortal.goToSignUp')}
            </AmplifyUI.Button>
          </Box>
        </>
      )
    },
  }
  return (
    <>
      <Box className="NavBar" sx={{ mb: 10 }}>
        <NavBar
          onClickDarkMode={() => {
            colorMode.toggleColorMode()
          }}
        />
      </Box>
      <Authenticator hideSignUp components={components}>
        <JoinedSimulationsProvider>
          <CustomBreadcrumbs />
          {/*{user ? children : <CircularProgress />}*/}
          {children}
          {!noFooter && <Footer />}
        </JoinedSimulationsProvider>
      </Authenticator>
    </>
  )
}

export default CustomAuthenticator
