import { get, post, put } from '../../rest-utils'
import ApiResources from '../../../api-resources'
import { APISimulationRequest } from '../models/APISimulationRequest'
import { APIVoluntaryUser } from '../models/APIVoluntaryUser'

export async function getPublishedSimulationRequests(): Promise<APISimulationRequest[]> {
  return get<APISimulationRequest[]>(
    `simulation-conf/simulation-request/published-list`,
    ApiResources.api_simulation_conf_url,
  )
}

export async function getSimulationRequestById(id: number | string): Promise<APISimulationRequest> {
  return get<APISimulationRequest>(
    `simulation-conf/simulation-request/?simulationRequestId=${id}`,
    ApiResources.api_simulation_conf_url,
  )
}

export async function getAllSimulationRequests(): Promise<APISimulationRequest[]> {
  return get<APISimulationRequest[]>(
    `simulation-conf/simulation-request/admin-all-list`,
    ApiResources.api_simulation_conf_url,
  )
}

export async function getSimulationRequestByClientUsername(username: string): Promise<APISimulationRequest[]> {
  return get<APISimulationRequest[]>(
    `simulation-conf/simulation-request/?clientUsername=${username}`,
    ApiResources.api_simulation_conf_url,
  )
}

export async function postSimulationRequest(scenario: APISimulationRequest): Promise<APISimulationRequest> {
  return post('simulation-conf/simulation-request', scenario, ApiResources.api_simulation_conf_url)
}

export async function putValidateSimulation(id: number): Promise<APIVoluntaryUser> {
  return put(
    `simulation-conf/simulation-request/validate?simulationRequestId=${id}`,
    {},
    ApiResources.api_simulation_conf_url,
  )
}
