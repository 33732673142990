import { Card, CardActionArea, CardContent, SxProps, Typography } from '@mui/material'
import React from 'react'

interface TextColor {
  descriptionColor?: string
}

interface CardButtonProps {
  description: React.ReactNode
  onClick?: () => void
  sx?: SxProps
  textColor?: TextColor
  cardActionProps?: React.ComponentProps<typeof CardActionArea>
  icon?: React.ReactNode
}

function CardButtonAction(props: CardButtonProps) {
  const { description, onClick, sx, cardActionProps, textColor, icon } = props

  return (
    <Card
      onClick={onClick}
      sx={{
        ...sx,
        minHeight: '5em',
        border: 3,
        borderStyle: 'dashed',
        borderColor: 'primary.main',
        bgcolor: 'transparent',
      }}
    >
      <CardActionArea {...cardActionProps}>
        <CardContent>
          <Typography color={'primary.main'}>{icon}</Typography>
          <Typography
            variant="body2"
            color={textColor && textColor.descriptionColor ? textColor.descriptionColor : 'primary.main'}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default CardButtonAction
export type { CardButtonProps }
