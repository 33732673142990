import { Box, CircularProgress, Typography } from '@mui/material'
import APIUser from '../../rest/user/APIUser'
import React, { ReactElement, useContext } from 'react'
import UserContext from '../../contexts/user/UserContext'

function ProfileInformations(props: { user: APIUser | undefined }): ReactElement {
  const { user } = props
  console.log(user)

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 600,
        bgcolor: 'background.paper',
        m: '0 auto',
        mb: 5,
        // display: 'flex',
        justifyContent: 'center',
        borderRadius: 1,
        boxShadow: 5,
      }}
    >
      {/*<Box sx={{ width: '90%', mt: 3, mb: 3 }} display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>*/}
      {/*  <Box gridColumn="span 5" sx={{ bgcolor: 'primary.dark' }}>*/}
      {/*    <List>*/}
      {/*      <ListItem>*/}
      {/*        <ListItemText primary="First name" secondary={user.firstname} />*/}
      {/*      </ListItem>*/}
      {/*    </List>*/}
      {/*  </Box>*/}
      {/*  <Box gridColumn="span 7" sx={{ bgcolor: 'primary.dark' }}>*/}
      {/*    <List>*/}
      {/*      <ListItem>*/}
      {/*        <ListItemText primary="Last name" secondary={user.lastname} />*/}
      {/*      </ListItem>*/}
      {/*    </List>*/}
      {/*  </Box>*/}
      {/*  <Box gridColumn="span 7" sx={{ bgcolor: 'primary.dark' }}>*/}
      {/*    <List>*/}
      {/*      <ListItem>*/}
      {/*        <ListItemText primary="Nickname" secondary={user.nickname} />*/}
      {/*      </ListItem>*/}
      {/*    </List>*/}
      {/*  </Box>*/}
      {/*  <Box gridColumn="span 5" sx={{ bgcolor: 'primary.dark' }}>*/}
      {/*    <List>*/}
      {/*      <ListItem>*/}
      {/*        <ListItemText primary="Creation date" secondary={user.creationDate.toString()} />*/}
      {/*      </ListItem>*/}
      {/*    </List>*/}
      {/*  </Box>*/}
      {/*</Box>*/}
      <Box>
        <br />
        <Typography variant="h4" component="div" gutterBottom color="error">
          Page under construction
        </Typography>
        <br />
      </Box>
    </Box>
  )
}

function Profile() {
  const frontUser = useContext(UserContext)

  if (!frontUser) {
    return (
      <>
        <Typography variant="h2" component="div" gutterBottom>
          Profile
        </Typography>
        <CircularProgress />
      </>
    )
  }

  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        {frontUser.user?.nickname}'s profile
      </Typography>
      <ProfileInformations user={frontUser.user} />
    </>
  )
}

export default Profile
