import React from 'react'
import { Box, Card, CardContent, Chip, Divider, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Button from '@mui/material/Button'
import SimulationRequestRoleDetails from '../list/roles/SimulationRequestRoleDetails'
import { APISimulationRequest } from '../../../rest/simulation/models/APISimulationRequest'
import { APISimulationRole } from '../../../rest/simulation/models/APISimulationRole'
import { APIIncomingAcceptanceStatus } from '../../../rest/simulation/models/APIIncomingParticipationType'

interface SimulationRoleCardProps {
  request: {
    simulation: APISimulationRequest
    role: APISimulationRole
    acceptanceStatus: APIIncomingAcceptanceStatus
  }
}

function SimulationRoleCard(props: SimulationRoleCardProps) {
  const { request } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const [value, setValue] = React.useState('Dione')
  const [open, setOpen] = React.useState(false)

  const handleClose = (newValue?: string) => {
    setOpen(false)

    if (newValue) {
      setValue(newValue)
    }
  }

  function openDetails() {
    setOpen(true)
  }

  return (
    <>
      <SimulationRequestRoleDetails
        role={request.role}
        simulation={request.simulation}
        open={open}
        value={value}
        onClose={handleClose}
      />
      <Card
        variant={'outlined'}
        sx={{
          m: 2,
          width: '280px',
          minHeight: '200px',
          boxShadow: 5,
          borderRadius: 3,
          '&:hover': {
            boxShadow: 15,
          },
        }}
      >
        <CardContent sx={{ height: '87%' }}>
          <Typography variant="h5">{request.simulation.simulation.name}</Typography>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            {dayjs(request.simulation.startDate).format('MM/DD/YYYY HH:mm')}
          </Typography>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            {dayjs(request.simulation.endDate).format('MM/DD/YYYY HH:mm')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Divider sx={{ width: '70%', m: 0.5 }} color={theme.palette.primary.main} />
          </Box>
          <Typography variant="h5">{request.role.name}</Typography>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            {t('roleTypes.' + request.role.roleType)}
          </Typography>
          <Chip label={request.acceptanceStatus} />
        </CardContent>
        <Button size="small" onClick={() => openDetails()}>
          {t('simulations.published.roles.viewDetails')}
        </Button>
      </Card>
    </>
  )
}

export default SimulationRoleCard
