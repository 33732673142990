import React, { useContext, useState } from 'react'
import SimulationFormRole from './role/SimulationFormRole'
import SimulationFormFrequencies from './frequency/SimulationFormFrequencies'
import SimulationFormReview from './review/SimulationFormReview'
import { Navigate } from 'react-router-dom'
import SimulationFormSettings from './SimulationFormSettings'
import { Gender } from '../../../rest/user/enums/Gender'
import SimulationFormPublish from './SimulationFormPublish'
import UserContext from '../../../contexts/user/UserContext'
import { APISimulationConf } from '../../../rest/simulation/models/APISimulationConf'
import { APIScenario } from '../../../rest/simulation/models/APIScenario'
import { getScenariosByClientUsername } from '../../../rest/simulation/queries/ScenarioQueries'

function SimulationForm() {
  const [step, setStep] = useState<number>(0)
  const [simulation, setSimulation] = useState<APISimulationConf>({
    id: null,
    name: '',
    weather: '',
    wind: '',
    roles: [
      {
        id: null,
        name: '',
        frequencies: [],
        roleType: 'OTHER',
        languages: [],
        accents: [],
        gender: Gender.OTHER,
        minAge: 18,
        maxAge: 80,
      },
    ],
    tags: [],
  })
  const [savedScenarios, setSavedScenarios] = React.useState<APIScenario[]>()
  const [frequencies, setFrequencies] = useState<string[]>([''])
  const frontUser = useContext(UserContext)?.user

  // function formatString(string: string) {
  //   const result = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  //   return result.replace('_', ' ')
  // }

  function frequencyFormat(apiFequencies: string[]) {
    const tempFrequencies = apiFequencies.map((frequency) => {
      return frequency.slice(6).replace('_', '.')
    })
    return tempFrequencies.filter((frequency, index) => tempFrequencies.indexOf(frequency) === index)
  }

  if (savedScenarios === undefined) {
    if (frontUser) {
      getScenariosByClientUsername(frontUser.username)
        .then((scenarios) => {
          setSavedScenarios(
            scenarios.map((scenario) => {
              const simu = scenario.simulation
              return {
                id: scenario.id,
                simulation: {
                  id: simu.id,
                  name: simu.name,
                  weather: simu.weather,
                  wind: simu.wind,
                  roles: simu.roles.map((role) => {
                    return {
                      id: role.id,
                      name: role.name,
                      frequencies: frequencyFormat(role.frequencies),
                      roleType: role.roleType,
                      minAge: role.minAge,
                      maxAge: role.maxAge,
                      gender: role.gender,
                      languages: role.languages,
                      accents: role.accents,
                    }
                  }),
                  frequencies: frequencyFormat(simu.roles.map((role) => role.frequencies).flat()),
                  tags: simu.tags,
                } as APISimulationConf,
                clientUsername: scenario.clientUsername,
              }
            }),
          )
        })
        .catch(() => {
          setSavedScenarios([])
        })
    }
  }

  switch (step) {
    case 0: {
      return (
        <SimulationFormSettings
          step={step}
          setStep={setStep}
          simulation={simulation}
          setSimulation={setSimulation}
          savedScenarios={savedScenarios}
          setSavedScenarios={setSavedScenarios}
          setFrequencies={setFrequencies}
        />
      )
    }
    case 1: {
      return (
        <SimulationFormFrequencies
          simulation={simulation}
          setSimulation={setSimulation}
          step={step}
          setStep={setStep}
          frequencies={frequencies}
          setFrequencies={setFrequencies}
        />
      )
    }
    case 2: {
      return (
        <SimulationFormRole
          simulation={simulation}
          setSimulation={setSimulation}
          step={step}
          setStep={setStep}
          frequencies={frequencies}
        />
      )
    }
    case 3: {
      return (
        <SimulationFormReview
          simulation={simulation}
          setSimulation={setSimulation}
          step={step}
          setStep={setStep}
          savedScenarios={savedScenarios}
          setSavedScenarios={setSavedScenarios}
        />
      )
    }
    case 4: {
      return (
        <SimulationFormPublish simulation={simulation} setSimulation={setSimulation} step={step} setStep={setStep} />
      )
    }
    default: {
      return <Navigate to={'/'} />
    }
  }
}

export default SimulationForm
