export default {
  ENGLISH_UK: 'English (UK)',
  ENGLISH_US: 'English (US)',
  ENGLISH_AU: 'English (AU)',
  ENGLISH_IN: 'English (IN)',
  ENGLISH_CA: 'English (CA)',
  FRENCH_FR: 'French (FR)',
  FRENCH_CA: 'French (CA)',
  SPANISH_ES: 'Spanish (ES)',
  PORTUGUESE_PT: 'Portuguese (PT)',
  PORTUGUESE_BR: 'Portuguese (BR)',
  GERMAN_DE: 'German (DE)',
  CHINESE_CN: 'Chinese (CN)',
  JAPANESE_JP: 'Japanese (JP)',
  HINDI_IN: 'Hindi (IN)',
  ARABIC_SA: 'Arabic (SA)',
  RUSSIAN_RU: 'Russian (RU)',
  HOLLAND_NL: 'Holland (NL)',
  ITALIAN_IT: 'Italian (IT)',
  OTHER: 'Other',
}
