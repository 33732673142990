enum Language {
  ENGLISH = 'ENGLISH',
  CHINESE = 'CHINESE',
  HINDI = 'HINDI',
  SPANISH = 'SPANISH',
  ARABIC = 'ARABIC',
  FRENCH = 'FRENCH',
  RUSSIAN = 'RUSSIAN',
  PORTUGUESE = 'PORTUGUESE',
  JAPANESE = 'JAPANESE',
  GERMAN = 'GERMAN',
  HOLLAND = 'HOLLAND',
  ITALIAN = 'ITALIAN',
  OTHER = 'OTHER',
}

export default Language
