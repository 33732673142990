import React from 'react'
import JoinedSimulationsContext from './JoinedSimulationsContext'
import useJoinedSimulations from './useJoinedSimulations'

const JoinedSimulationsProvider = (props: { children: React.ReactNode }) => {
  const { children } = props
  const {
    activeParticipationList,
    oldParticipationList,
    incomingParticipationRequestsList,
    participationRequestsList,
  } = useJoinedSimulations()

  return (
    <JoinedSimulationsContext.Provider
      value={{
        activeParticipationList,
        oldParticipationList,
        incomingParticipationRequestsList,
        participationRequestsList,
      }}
    >
      {children}
    </JoinedSimulationsContext.Provider>
  )
}

export default JoinedSimulationsProvider
