export default {
  signUp: {
    success: 'You have successfully signed up!',
  },
  signIn: {
    error1: 'An error occurred while fetching user.',
    error2: 'Please try again later.',
  },
  simulations: {
    roles: {
      applySuccess: 'You have successfully applied for the role {{role}} in the simulation {{simulation}}!',
      applyError: 'An error occurred while applying for the role {{role}} in the simulation {{simulation}}.',
    },
    joined: {
      error_title: 'An error occurred while fetching joined simulations:',
      error_description: 'Please try again later.',
    },
  },
}
