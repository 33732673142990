import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CardFrequency from './CardFrequency'
import { FrequencyStateEnum, FrequencyType } from '../../FrequencyType'
import { useSimulationInterfaceSettingsContext } from '../../../../../hooks/ContextHooks'

interface CardFrequencyBoxProps {
  frequencies: FrequencyType[]
  setFrequencyState: (frequency: FrequencyType, frequencyState: FrequencyStateEnum) => void
  setFrequencySid: (frequency: FrequencyType, sid: string) => void
}

const CardFrequencyBox = (props: CardFrequencyBoxProps) => {
  const { frequencies, setFrequencyState, setFrequencySid } = props
  const simulationInterfaceSettings = useSimulationInterfaceSettingsContext()
  const { frequencyBlockAlignment, frequencyBlockWidth } = simulationInterfaceSettings.settings.frequencyBlockSettings
  const [width, setWidth] = useState((frequencyBlockWidth * window.innerWidth) / 100)
  const theoricCardsPerRow = Math.floor(width / 200) < 1 ? 1 : Math.floor(width / 200)
  const linesQty = Math.ceil(frequencies.length / theoricCardsPerRow)
  const cardsPerRow = Math.ceil(frequencies.length / linesQty)
  const lines = []

  useEffect(() => {
    setWidth((frequencyBlockWidth * window.innerWidth) / 100)
  }, [frequencyBlockWidth])

  useEffect(() => {
    const handleResize = () => {
      setWidth((frequencyBlockWidth * window.innerWidth) / 100)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [frequencyBlockWidth])

  for (let i = 0; i < frequencies.length; i += cardsPerRow) {
    lines.push(frequencies.slice(i, i + cardsPerRow))
  }

  return (
    <Box sx={{ ml: 2, mr: 2 }}>
      {lines.map((line, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: frequencyBlockAlignment,
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {line.map((frequency: FrequencyType, ind: number) => (
            <Box key={ind}>
              <CardFrequency
                frequency={frequency.frequency}
                livekitRoom={frequency.livekitRoom}
                audioContext={frequency.audioContext}
                frequencyState={frequency.state}
                setFrequencyState={(frequencyState: FrequencyStateEnum) => setFrequencyState(frequency, frequencyState)}
                setFrequencySid={(sid: string) => setFrequencySid(frequency, sid)}
              />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  )
}

export default CardFrequencyBox
