import { PaletteOptions } from '@mui/material'

export const lightPalette: PaletteOptions = {
  primary: {
    main: '#3f51b5',
    light: '#3f51b5',
    dark: '#6372c5',
  },
  secondary: {
    main: '#fff',
    light: '#fff',
    dark: '#3f51b5',
  },
  appbar: {
    primary: '#3f51b5',
    text: '#fff',
  },
  background: {
    default: '#ffffff',
    paper: '#fffffe',
  },
  text: {
    primary: '#000',
    secondary: '#3f51b5',
  },
  error: {
    main: '#f44336',
  },
}
