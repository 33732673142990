import * as React from 'react'
import { ChipProps, Paper, Popper } from '@mui/material'
import Chip from '@mui/material/Chip'

interface GenericChipPopperProps {
  chipContent: React.ReactNode
  chipProps?: ChipProps
  popperContent: React.ReactNode
}

export default function GenericChipPopper(props: GenericChipPopperProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const { chipContent, chipProps, popperContent } = props

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Chip {...chipProps} aria-describedby={id} onClick={handleClick} label={chipContent} />
      <Popper id={id} open={open} anchorEl={anchorEl} placement={'bottom'}>
        <Paper
          sx={{
            bgcolor: 'background.default',
            borderRadius: 3,
            border: 3,
            borderColor: 'primary.main',
            m: 0.5,
          }}
        >
          {popperContent}
        </Paper>
      </Popper>
    </>
  )
}
