import React from 'react'
import { Box, List, Typography } from '@mui/material'
import { AItem, AListElement } from './AItem'
import { InformationItemProps } from './props/InformationItemProps'
import { useTranslation } from 'react-i18next'

const OtherInformationItem = (props: InformationItemProps): React.ReactElement => {
  const { user, setUser, setField } = props
  const { t } = useTranslation()
  const languages = user.languages
    .map((language) => {
      return t(`languages.${language}`)
    })
    .join(', ')
  const accents = user.accents
    .map((accent) => {
      return t(`accents.${accent}`)
    })
    .join(', ')

  return (
    <AItem>
      <Box>
        <Typography variant="h4" component="div" gutterBottom>
          {t('account.otherInformation.title')}
        </Typography>
        <List>
          <AListElement
            primary={t('account.otherInformation.languages')}
            secondary={languages}
            user={user}
            setUser={setUser}
            fieldId={'languages'}
            editable
            setField={setField}
          />
          <AListElement
            primary={t('account.otherInformation.accents')}
            secondary={accents}
            user={user}
            setUser={setUser}
            fieldId={'accents'}
            editable
            setField={setField}
          />
        </List>
      </Box>
    </AItem>
  )
}

export default OtherInformationItem
