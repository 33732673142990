import { Box, Typography } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import SimulationManagementElement from './SimulationManagementElement'
import { useSimulationManagementApplicationsContext, useSimulationManagementContext } from '../../../hooks/ContextHooks'
import Button from '@mui/material/Button'
import { APISimulationRole } from '../../../rest/simulation/models/APISimulationRole'

function SimulationManagementCandidates() {
  const { callSimulationRequests } = useSimulationManagementContext()
  const { simulationRequest } = useSimulationManagementApplicationsContext()

  function getUsersByRoleAndStatus(role: APISimulationRole, status: string) {
    const voluntaryUsersByRole = simulationRequest?.voluntaryUsers?.filter((v) => v.roleId == role.id)

    //voluntaryUsersByRole?.map((v) => console.log(v.acceptanceStatus + ' ' + status))
    return voluntaryUsersByRole?.filter((v) => v.acceptanceStatus == status)
  }

  return (
    <div>
      <Box maxWidth={600} sx={{ margin: '0 auto' }}>
        <Typography variant="h2" component="div" gutterBottom>
          {t('simulations.applications.title')}
        </Typography>

        <Typography variant="h3" component="div" gutterBottom>
          {simulationRequest?.simulation.name}
        </Typography>

        <Button variant={'outlined'} sx={{ mb: 3 }} onClick={callSimulationRequests}>
          Refresh
        </Button>

        {simulationRequest?.simulation.roles.map((role: APISimulationRole) => {
          const acceptedUsers = getUsersByRoleAndStatus(role, 'ACCEPTED')
          const pendingUsers = getUsersByRoleAndStatus(role, 'PENDING')
          const rejectedUsers = getUsersByRoleAndStatus(role, 'REJECTED')
          if (acceptedUsers && acceptedUsers.length > 0) {
            return (
              <SimulationManagementElement key={role.id} role={role} voluntaryUsers={acceptedUsers} accepted={true} />
            )
          }
          return (
            <>
              <SimulationManagementElement
                key={role.id}
                role={role}
                voluntaryUsers={pendingUsers && rejectedUsers ? pendingUsers.concat(rejectedUsers) : []}
              />
            </>
          )
        })}
      </Box>
    </div>
  )
}

export default SimulationManagementCandidates
