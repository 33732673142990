import React from 'react'
import SimulationManagementApplicationsContext from './SimulationManagementApplicationsContext'
import useSimulationManagementApplications from './useSimulationManagementApplications'

const SimulationManagementApplicationsProvider = (props: { children: React.ReactNode }) => {
  const { children } = props
  const { candidates, simulationRequest, updateVoluntaryUser, refreshCandidates } =
    useSimulationManagementApplications()

  return (
    <SimulationManagementApplicationsContext.Provider
      value={{
        candidates: candidates,
        simulationRequest: simulationRequest,
        updateVoluntaryUser: updateVoluntaryUser,
        refreshCandidates: refreshCandidates,
      }}
    >
      {children}
    </SimulationManagementApplicationsContext.Provider>
  )
}

export default SimulationManagementApplicationsProvider
