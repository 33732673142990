import { get, put } from '../../rest-utils'
import ApiResources from '../../../api-resources'
import { APIUserNames } from '../../user/APIUser'

export async function forceStartSimulation(simulationId: number) {
  return put(
    `simulation-conf/force-start-simulation?simulationId=${simulationId}`,
    {},
    ApiResources.api_simulation_conf_url,
  )
}

export async function stopSimulation(simulationId: number) {
  return put(`simulation-conf/stop-simulation?simulationId=${simulationId}`, {}, ApiResources.api_simulation_conf_url)
}

export async function getSimulationUsers(simulationId: number): Promise<APIUserNames[]> {
  return get<APIUserNames[]>(
    `simulation-conf/simulation-users?simulationId=${simulationId}`,
    ApiResources.api_simulation_conf_url,
  )
}
