import React from 'react'
import { Box, Button } from '@mui/material'
import ConferenceCreationStepper from '../SimulaionFormStepper'
import CardSimulationFormRole from './CardSimulationFormRole'
import CardButtonAction from '../../../../components/mui/cardbutton/CardButtonAction'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { Gender } from '../../../../rest/user/enums/Gender'
import { useTranslation } from 'react-i18next'
import { APISimulationRole } from '../../../../rest/simulation/models/APISimulationRole'
import { APISimulationConf } from '../../../../rest/simulation/models/APISimulationConf'

interface ConferenceRoleProps {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  simulation: APISimulationConf
  setSimulation: React.Dispatch<React.SetStateAction<APISimulationConf>>
  frequencies: string[]
}

function SimulationFormRole(props: ConferenceRoleProps) {
  const { t } = useTranslation()
  const { step, setStep, simulation, setSimulation, frequencies } = props

  function setRoles(
    roles: (
      | APISimulationRole
      | {
          languages: []
          gender: Gender.OTHER
          maxAge: number
          accents: []
          minAge: number
          name: string
          id: null
          roleType: string
          frequencies: []
        }
    )[],
  ) {
    setSimulation({ ...simulation, roles })
  }

  function handleAddRole() {
    setRoles([
      ...simulation.roles,
      {
        id: null,
        name: '',
        frequencies: [],
        roleType: 'OTHER',
        languages: [],
        accents: [],
        gender: Gender.OTHER,
        minAge: 18,
        maxAge: 80,
      },
    ])
  }

  function handleContinue() {
    let valid = true
    simulation.roles.forEach((role) => {
      role.name = role.name.trimEnd()
      if (
        role.frequencies.length === 0 ||
        role.name === '' ||
        role.languages.length === 0 ||
        role.accents.length === 0 ||
        role.minAge < 18 ||
        role.maxAge < 18 ||
        role.minAge > role.maxAge
      ) {
        valid = false
      }
    })
    if (valid) {
      setStep(step + 1)
    }
  }

  return (
    <>
      <ConferenceCreationStepper step={step} />
      <Box sx={{ width: 500, maxWidth: '85%', margin: 'auto' }}>
        <Box sx={{ marginTop: '20px' }}>
          {simulation.roles.map((role, index) => (
            <CardSimulationFormRole
              simulation={simulation}
              setSimulation={setSimulation}
              id={index}
              key={index}
              frequencies={frequencies}
            />
          ))}
          <CardButtonAction
            description={t('simulations.create.roles.new')}
            sx={{ width: '100%', borderRadius: 3, boxShadow: 2 }}
            icon={<AddCircleOutlineOutlinedIcon sx={{ fontSize: 50 }} />}
            onClick={handleAddRole}
          />{' '}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mt: 4 }}>
          <Button onClick={() => setStep(step - 1)} variant={'outlined'}>
            {t('simulations.create.buttons.return')}
          </Button>
          <Button variant={'contained'} color={'info'} sx={{ marginLeft: 5 }} onClick={handleContinue}>
            {t('simulations.create.buttons.continue')}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default SimulationFormRole
