import ConferenceCreationStepper from '../SimulaionFormStepper'
import { Box, Button, Typography } from '@mui/material'
import React, { useContext } from 'react'
import SimulationRoleReviewCard from './SimulationRoleReviewCard'
import UserContext from '../../../../contexts/user/UserContext'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { APISimulationConf } from '../../../../rest/simulation/models/APISimulationConf'
import { APIScenario } from '../../../../rest/simulation/models/APIScenario'
import { APISimulationRole } from '../../../../rest/simulation/models/APISimulationRole'
import { postScenario } from '../../../../rest/simulation/queries/ScenarioQueries'

interface SimulationFormReviewProps {
  simulation: APISimulationConf
  setSimulation: React.Dispatch<React.SetStateAction<APISimulationConf>>
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  savedScenarios: APIScenario[] | undefined
  setSavedScenarios: React.Dispatch<React.SetStateAction<APIScenario[] | undefined>>
}

function SimulationFormReview(props: SimulationFormReviewProps) {
  const { simulation, step, setStep, savedScenarios, setSavedScenarios } = props
  const { t } = useTranslation()
  const frontUser = useContext(UserContext)
  const [savingLoading, setSavingLoading] = React.useState(false)
  const [lockSave, setLockSave] = React.useState(false)

  const simulationStyle = {
    fwContainer: {
      fontSize: '1.1em',
      borderRadius: 2,
      p: 1,
      width: '100%',
      border: '1px solid #517788',
      bgcolor: 'secondary.light',
      boxShadow: 5,
    },
    container: {
      fontSize: '1.1em',
      borderRadius: 2,
      p: 1,
      border: '1px solid #517788',
      bgcolor: 'secondary.light',
      boxShadow: 5,
    },
  }

  function handleContinue() {
    setStep(step + 1)
  }

  function scenarioAlreadyExists() {
    if (savedScenarios) {
      return savedScenarios.some((scenario) => scenario.simulation.name === simulation.name)
    }
    return false
  }

  function handleSave() {
    if (scenarioAlreadyExists()) {
      toast.error(t('simulations.create.review.scenarioAlreadyExists'))
      setSavingLoading(false)
      return
    }
    const scenario = {
      id: null,
      simulation: {
        name: simulation.name,
        wind: simulation.wind.toUpperCase().replace(' ', '_'),
        weather: simulation.weather.toUpperCase().replace(' ', '_'),
        tags: simulation.tags,
        roles: simulation.roles.map((role) => {
          return {
            id: null,
            name: role.name,
            frequencies: role.frequencies.map((freq) => 'radio_' + freq.replace('.', '_')),
            roleType: role.roleType.toUpperCase().replace(' ', '_'),
            gender: role.gender.toUpperCase(),
            languages: role.languages.map((lang) => lang),
            accents: role.accents.map((accent) => accent),
            minAge: role.minAge,
            maxAge: role.maxAge,
          } as APISimulationRole
        }),
      } as APISimulationConf,
      clientUsername: frontUser?.user?.username,
    } as APIScenario
    postScenario(scenario)
      .then(() => {
        setTimeout(() => {
          setSavingLoading(false)
          toast.success(t('simulations.create.review.scenarioSaved'))
        }, 400)
        if (savedScenarios) {
          const newScenario = {
            id: null,
            simulation: simulation,
          } as APIScenario
          setSavedScenarios([...savedScenarios, newScenario])
        }
        setLockSave(true)
      })
      .catch(() => {
        setTimeout(() => {
          setSavingLoading(false)
          toast.error(t('simulations.create.review.scenarioSavedError'))
        }, 400)
      })
  }

  return (
    <>
      <ConferenceCreationStepper step={step} />
      <Box sx={{ width: 400, maxWidth: '90%', m: 'auto' }}>
        <Typography
          sx={{
            mb: 2,
            ...simulationStyle.fwContainer,
          }}
          noWrap
        >
          {simulation.name}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              mr: 2,
              ...simulationStyle.fwContainer,
            }}
          >
            {t('wind.' + simulation.wind)}
          </Typography>
          <Typography
            sx={{
              ...simulationStyle.fwContainer,
            }}
          >
            {t('weather.' + simulation.weather)}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {simulation.roles.map((role, index) => (
          <SimulationRoleReviewCard key={index} role={role} index={index} />
        ))}
      </Box>
      <Box
        sx={{
          maxWidth: '90%',
          width: 400,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 4,
          m: 'auto',
        }}
      >
        <Button onClick={() => setStep(step - 1)} variant={'outlined'}>
          {t('simulations.create.buttons.return')}
        </Button>
        <LoadingButton
          loading={savingLoading}
          disabled={lockSave}
          variant={'contained'}
          color={'info'}
          sx={{ marginLeft: 5 }}
          onClick={handleSave}
        >
          {t('simulations.create.buttons.save')}
        </LoadingButton>
        <Button variant={'contained'} color={'success'} sx={{ marginLeft: 5, color: 'white' }} onClick={handleContinue}>
          {t('simulations.create.buttons.publish')}
        </Button>
      </Box>
    </>
  )
}

export default SimulationFormReview
