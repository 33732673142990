import { useTranslation } from 'react-i18next'
import { Autocomplete, TextField } from '@mui/material'
import * as React from 'react'
import { PrivateCallAccordionProps } from './PrivateCallAccordion'

const UserAutocomplete = (props: PrivateCallAccordionProps) => {
  const { t } = useTranslation()
  const { userList, sx, selectedUser, setSelectedUser } = props

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField {...params} label={t('simulations.running.privateCall.selectSomeoneLabel')} />
      )}
      options={userList}
      sx={sx}
      value={selectedUser}
      isOptionEqualToValue={(option, value) => {
        if (value.value === '') {
          return true
        }
        return option.value === value.value
      }}
      onChange={(event, newValue) => {
        setSelectedUser(newValue ?? { label: '', value: '' })
      }}
    />
  )
}

export default UserAutocomplete
