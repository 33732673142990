import { createContext } from 'react'
import { SimulationInterfaceSettingsType } from './SimulationInterfaceSettingsTypes'

export type SimulationInterfaceSettingsContextType = {
  settings: SimulationInterfaceSettingsType
  updateFrequencyBlockWidth: (frequencyBlockWidth: number) => void
  updateFrequencyBlockAlignment: (frequencyBlockAlignment: 'left' | 'center' | 'right') => void
  updatePrivateCallAlignment: (privateCallAlignment: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right') => void
}

const SimulationInterfaceSettingsContext = createContext<SimulationInterfaceSettingsContextType>(
  {} as SimulationInterfaceSettingsContextType,
)

export default SimulationInterfaceSettingsContext
