import { Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CardButtonGrid from '../../components/mui/cardbutton/CardButtonGrid'
import { useTranslation } from 'react-i18next'

function HomePage() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        {t('home.home.welcome')}
      </Typography>
      <CardButtonGrid
        cardButtonsList={[
          {
            title: t('pageNames.myprofile'),
            description: t('home.home.pageDescriptions.profile'),
            onClick: () => navigate('/profile'),
          },
          {
            title: t('pageNames.myaccount'),
            description: t('home.home.pageDescriptions.account'),
            onClick: () => navigate('/account'),
          },
        ]}
      />
      <CardButtonGrid
        cardButtonsList={[
          {
            title: t('pageNames.simulations'),
            description: t('home.home.pageDescriptions.simulations'),
            onClick: () => navigate('/simulations'),
          },
        ]}
      />
    </>
  )
}

export default HomePage
