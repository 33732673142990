import { ReactNode, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../contexts/user/UserContext'

export type AdminProtectionMode = 'strict' | 'hide'

function AdminProtection({ children, mode }: { children: ReactNode; mode?: AdminProtectionMode }) {
  const frontUser = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (frontUser) {
      if (frontUser.isAdmin !== undefined && !frontUser.isAdmin) {
        if (mode !== 'hide') {
          console.error('AdminProtection: User is not admin')
          navigate('/403')
        }
      }
    }
  }, [frontUser, mode, navigate])

  if (frontUser) {
    if (!frontUser.isAdmin) {
      return <></>
    }
    return <>{children}</>
  } else {
    return <></>
  }
}

export default AdminProtection
