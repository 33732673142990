import * as React from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { SxProps } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = (theme: Theme) => ({
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: theme.palette.secondary.light,
    },
  },
})

interface MultipleSelectChipProps {
  list: any[]
  formattedList?: (element: string) => string
  label: string
  items: any[]
  formattedItem?: (element: string) => string
  setItems: (items: string[]) => void
  additionalHandleChange?: () => void
  multiple?: boolean
  disabled?: boolean
  material?: boolean
  error?: boolean
  sx?: SxProps
}

export default function MultipleSelectChip(props: MultipleSelectChipProps): React.ReactElement {
  const theme = useTheme()
  const {
    list,
    formattedList,
    label,
    items,
    formattedItem,
    setItems,
    additionalHandleChange,
    disabled,
    material,
    error,
    sx,
  } = props
  const formattedLabel = label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()
  let multiple = props.multiple
  if (multiple === undefined) multiple = true

  const handleChange = (event: SelectChangeEvent<typeof items>) => {
    if (additionalHandleChange) {
      additionalHandleChange()
    }
    const {
      target: { value },
    } = event
    setItems(typeof value === 'string' ? value.split(',') : value)
  }
  if (material) {
    return (
      <FormControl sx={{ mt: '1rem', width: '100%' }}>
        <InputLabel id={label + '-multiple-chip-label'} error={error}>
          {formattedLabel}
        </InputLabel>
        <Select
          sx={{
            ...sx,
          }}
          disabled={disabled}
          labelId={label + '-multiple-chip-label'}
          id={label + '-multiple-chip'}
          multiple={multiple}
          value={items}
          onChange={handleChange}
          input={<OutlinedInput id={'select-multiple-' + label} label={formattedLabel} required />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={formattedItem ? formattedItem(value) : value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps(theme)}
          error={error}
        >
          {list.map((name) => (
            <MenuItem key={name} value={name}>
              {formattedList ? formattedList(name) : name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  } else {
    return (
      <div>
        <FormControl sx={{ mt: '1rem', width: '100%' }}>
          <InputLabel
            id={label + '-multiple-chip-label'}
            sx={{
              color: '#838C95',
              fontSize: '0.95rem',
            }}
          >
            {formattedLabel}
          </InputLabel>
          <Select
            disabled={disabled}
            labelId={label + '-multiple-chip-label'}
            id={label + '-multiple-chip'}
            multiple={multiple}
            value={items}
            onChange={handleChange}
            input={
              <OutlinedInput
                id={'select-multiple-' + label}
                label={formattedLabel}
                sx={{
                  '& 	.Mui-focused': {
                    borderColor: theme.palette.text.primary,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#838C95',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#838C95',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.text.primary,
                  },
                }}
                required
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={formattedItem ? formattedItem(value) : value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps(theme)}
          >
            {list.map((name) => (
              <MenuItem key={name} value={name}>
                {formattedList ? formattedList(name) : name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  }
}
