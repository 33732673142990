import { createContext } from 'react'
import { APIActiveParticipationType } from '../../rest/simulation/models/APIActiveParticipationType'
import { APIIncomingParticipationType } from '../../rest/simulation/models/APIIncomingParticipationType'

export type JoinedSimulationsType = {
  activeParticipationList: APIActiveParticipationType[] | undefined // active participation list = accepted & running simulations
  oldParticipationList: APIActiveParticipationType[] | undefined // old participation list = finished simulations
  incomingParticipationRequestsList: APIIncomingParticipationType[] | undefined // incoming participation requests list = accepted & validated simulations
  participationRequestsList: APIIncomingParticipationType[] | undefined // participation requests list = all participation requests, including accepted, pending and refused ones
}

const JoinedSimulationsContext = createContext<JoinedSimulationsType | undefined>(undefined)

export default JoinedSimulationsContext
