import { Box, CircularProgress, IconButton, Menu, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import APIUser, { getUser, putUser } from '../../rest/user/APIUser'
import AccountFields from './AccountFields'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '../../hooks/ContextHooks'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import toast from 'react-hot-toast'
import { useConfirm } from 'material-ui-confirm'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useMediaQuery } from 'react-responsive'

function AccountPage() {
  const { user: apiUser, refreshUser } = useUserContext()
  const { userId } = useParams()
  const navigate = useNavigate()
  const [user, setUser] = React.useState<APIUser | null>(null)
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const confirm = useConfirm()
  const { signOut } = useAuthenticator((context) => [context.user])
  const isTabletOrMobile = useMediaQuery({ maxWidth: 950 })

  function confirmArchive() {
    confirm({
      title: <Typography fontSize={20}>{t('account.confirm.archive.title')}</Typography>,
      confirmationKeyword: 'archive ' + user?.username,
      description: (
        <>
          <Typography variant={'body1'}>{t('account.confirm.archive.content')}</Typography>
          <Typography variant={'body1'} sx={{ fontWeight: 'bold', mb: 1 }} color={'red'}>
            {t('account.confirm.archive.keywordTextInstructions', { keyword: 'archive ' + user?.username })}
          </Typography>
        </>
      ),
      confirmationText: t('button.archive'),
      cancellationText: t('button.cancel'),
    })
      .then(() => {
        if (user) {
          user.status = 'ARCHIVED'
          putUser(user)
            .then(() => {
              toast.success('Successfully archived ' + user.username)
              signOut()
              navigate('/')
            })
            .catch((e) => {
              toast.error('Failed to archive user: ' + e.status)
            })
        }
      })
      .catch(() => {})
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickArchive = () => {
    handleClose()
    confirmArchive()
  }

  useEffect(() => {
    if (!userId && apiUser) {
      setUser(apiUser)
    }
  }, [apiUser, userId])

  const handleUserChange = (value: APIUser) => {
    if (!userId) {
      refreshUser()
    }
    setUser(value)
  }

  if (!user) {
    if (userId) {
      const id = parseInt(userId)
      getUser(id)
        .then((response) => {
          setUser(response)
        })
        .catch((error) => {
          navigate('/' + error)
        })
    } else if (apiUser) {
      setUser(apiUser)
    }

    return (
      <>
        <Typography variant="h2" component="div" gutterBottom>
          {t('account.title')}
        </Typography>
        <CircularProgress />
      </>
    )
  } else {
    return (
      <>
        <Box
          sx={{
            width: '80%',
            m: '0 auto',
            display: 'grid',
            gridTemplateColumns: isTabletOrMobile ? '500fr 1fr' : '1fr 2fr 1fr',
            alignItems: 'center',
          }}
        >
          <Box gridColumn={isTabletOrMobile ? '1' : '2'}>
            <Typography
              variant={isTabletOrMobile ? 'h4' : 'h2'}
              sx={{
                textAlign: isTabletOrMobile ? 'left' : 'center',
                ml: isTabletOrMobile ? 1 : 0,
              }}
            >
              {userId ? 'Editing ' + user.username + "'s account" : t('account.title')}
            </Typography>
          </Box>

          <Box gridColumn={3} justifySelf={'end'}>
            <IconButton
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              size={'large'}
              sx={{ textAlign: isTabletOrMobile ? 'right' : 'center' }}
            >
              <MenuIcon fontSize={'large'} color={'primary'} />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={handleClose}>{t('account.changePassword')}</MenuItem>
              <MenuItem sx={{ color: 'error.main' }} onClick={handleClickArchive}>
                {t('account.archive')}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box sx={{ width: '80%', m: '0 auto' }}>
          <AccountFields user={user} setUser={handleUserChange} />
        </Box>
      </>
    )
  }
}

export default AccountPage
