import * as React from 'react'
import MultipleSelectChip from './MultipleSelectChip'
import { SxProps } from '@mui/material'

interface UniqueSelectChipProps {
  list: any[]
  formattedList?: (element: string) => string
  label: string
  item: string
  formattedItem?: (element: string) => string
  setItem: (items: string) => void
  material?: boolean
  sx?: SxProps
}

export default function UniqueSelectChip(props: UniqueSelectChipProps): React.ReactElement {
  const { list, formattedList, label, item, formattedItem, setItem, material, sx } = props

  return (
    <MultipleSelectChip
      list={list}
      formattedList={formattedList}
      label={label}
      multiple={false}
      items={[item]}
      formattedItem={formattedItem}
      setItems={(items) => setItem(items[0])}
      material={material ?? true}
      sx={{ ...sx }}
    />
  )
}
