import { useLocalStorage } from 'usehooks-ts'
import { useCallback, useEffect, useState } from 'react'
import { SimulationInterfaceSettingsType } from './SimulationInterfaceSettingsTypes'

const useSimulationInterfaceSettings = () => {
  const simulationInterfaceCustomizationLS = useLocalStorage<SimulationInterfaceSettingsType>(
    'simulationInterfaceCustomization',
    {
      frequencyBlockSettings: {
        frequencyBlockWidth: 100,
        frequencyBlockAlignment: 'center',
      },
      privateCallSettings: {
        privateCallAlignment: 'bottom-right',
      },
    },
  )[0]
  const [simulationInterfaceCustomization, setSimulationInterfaceCustomization] =
    useState<SimulationInterfaceSettingsType>(
      simulationInterfaceCustomizationLS || {
        frequencyBlockSettings: {
          frequencyBlockWidth: 100,
          frequencyBlockAlignment: 'center',
        },
        privateCallSettings: {
          privateCallAlignment: 'bottom-right',
        },
      },
    )

  useEffect(() => {
    console.log('simulationInterfaceCustomization', simulationInterfaceCustomization)
    localStorage.setItem('simulationInterfaceCustomization', JSON.stringify(simulationInterfaceCustomization))
  }, [simulationInterfaceCustomization])

  const updateFrequencyBlockWidth = useCallback(
    (frequencyBlockWidth: number) => {
      console.log('updateFrequencyBlockWidth', frequencyBlockWidth)
      setSimulationInterfaceCustomization((prev) => {
        return {
          ...prev,
          frequencyBlockSettings: {
            ...prev.frequencyBlockSettings,
            frequencyBlockWidth,
          },
        }
      })
    },
    [setSimulationInterfaceCustomization],
  )

  const updateFrequencyBlockAlignment = useCallback(
    (frequencyBlockAlignment: 'left' | 'center' | 'right') => {
      setSimulationInterfaceCustomization((prev) => {
        return {
          ...prev,
          frequencyBlockSettings: {
            ...prev.frequencyBlockSettings,
            frequencyBlockAlignment,
          },
        }
      })
    },
    [setSimulationInterfaceCustomization],
  )

  const updatePrivateCallAlignment = useCallback(
    (privateCallAlignment: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right') => {
      setSimulationInterfaceCustomization((prev) => {
        return {
          ...prev,
          privateCallSettings: {
            ...prev.privateCallSettings,
            privateCallAlignment,
          },
        }
      })
    },
    [setSimulationInterfaceCustomization],
  )

  return {
    simulationInterfaceCustomization,
    updateFrequencyBlockWidth,
    updateFrequencyBlockAlignment,
    updatePrivateCallAlignment,
  }
}

export default useSimulationInterfaceSettings
