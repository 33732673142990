import { WebSocketHook } from 'react-use-websocket/dist/lib/types'
import { JsonValue } from 'react-use-websocket/src/lib/types'

export interface SimulationSocket {
  webSocket: WebSocketHook<JsonValue | null, MessageEvent | null> | null
  open: (id: string) => void
  close: () => void
  isOpened: boolean
}

export enum SimulationStatus {
  USER_SPEAKING,
  OTHER_SPEAKING,
  NOTHING,
}
