import React from 'react'
import { Box, List, Stack, Typography } from '@mui/material'
import { AItem, AListElement } from './AItem'
import { InformationItemProps } from './props/InformationItemProps'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

const SystemInformationItem = (props: InformationItemProps): React.ReactElement => {
  const { user, setUser } = props
  const isLaptop = useMediaQuery({ minWidth: 950 })
  // const frontUser = useContext(userContext)
  // const rolesTextList =
  //   frontUser?.user?.id === user.id
  //     ? '' +
  //       frontUser?.roles
  //         .toString()
  //         .split(',')
  //         .map((role: string) => {
  //           return role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()
  //         })
  //         .join(', ')
  //     : 'Unknown'
  const { t } = useTranslation()

  return (
    <AItem>
      <Box>
        <Typography variant="h4" component="div" gutterBottom>
          {t('account.systemInformation.title')}
        </Typography>
        <List>
          <Stack direction={isLaptop ? 'row' : 'column'} spacing={1}>
            {/*<ListItem*/}
            {/*  sx={{*/}
            {/*    bgcolor: 'secondary.light',*/}
            {/*    borderRadius: 1,*/}
            {/*    boxShadow: 5,*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <ListItemText primary={t('account.systemInformation.accountRoles')} secondary={rolesTextList} />*/}
            {/*</ListItem>*/}
            <AListElement
              primary={t('account.systemInformation.lastConnection')}
              secondary={
                new Date(user.creationDate).toLocaleDateString() +
                ' ' +
                new Date(user.creationDate).toLocaleTimeString()
              }
              user={user}
              setUser={setUser}
              fieldId={'lastConnectionDate'}
            />
          </Stack>
        </List>
      </Box>
    </AItem>
  )
}

export default SystemInformationItem
