export default {
  loginPortal: {
    goToSignUp: "Vous n'avez pas de compte ? Inscrivez-vous",
  },
  signUpPage: {
    title: 'Informations du compte',
    errors: {
      required: 'Ce champ est obligatoire',
      passwordLength: 'Le mot de passe doit comporter au moins 8 caractères',
      passwordMatch: 'Les mots de passe ne correspondent pas',
      age: "L'âge doit être compris entre 18 et 99 ans",
      alreadyExists: "Ce nom d'utilisateur existe déjà",
    },
    username: "Nom d'utilisateur",
    email: 'E-mail',
    password: 'Mot de passe',
    confirmPassword: 'Confirmer le mot de passe',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    nickname: 'Pseudo',
    age: 'Âge',
    phoneNumber: 'Numéro de téléphone',
    gender: 'Genre',
    languages: 'Langues',
    accents: 'Accents',
    goToSignIn: 'Vous avez déjà un compte ? Connectez-vous',
    signUp: "S'inscrire",
  },
  home: {
    welcome: 'Bienvenue sur Airgate',
    pageDescriptions: {
      profile: 'Sur cette page vous pouvez voir votre profil public.',
      account: 'Sur cette page vous pouvez gérer votre compte.',
      simulations: 'Sur cette page vous pouvez voir et gérer vos simulations.',
    },
  },
}
