import React from 'react'
import UserContext from './UserContext'
import useFrontUser from './useFrontUser'

const UserProvider = (props: { children: React.ReactNode }) => {
  const { apiUser, roles, isAdmin, refreshUser, token } = useFrontUser()
  const { children } = props

  return (
    <UserContext.Provider
      value={{
        user: apiUser,
        roles: roles,
        isAdmin: isAdmin,
        refreshUser: refreshUser,
        token: token,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
