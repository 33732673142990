import CardFrequencyBox from './subcomponents/frequencies/CardFrequencyBox'
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import MicIcon from '@mui/icons-material/Mic'
import MicOffIcon from '@mui/icons-material/MicOff'
import PaletteIcon from '@mui/icons-material/Palette'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import UserContext from '../../../contexts/user/UserContext'
import { useHoldPress } from '../../../hooks/useHoldPress'
import { FixedHTMLVideoElement } from './FixedHTMLVideoElement'
import { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import { FrequencyStateEnum, FrequencyType } from './FrequencyType'
import StatusPopover from './subcomponents/others/StatusPopover'
import SettingsPopover from './subcomponents/others/SettingsPopover'
import PrivateCallAccordion from './subcomponents/privatecall/PrivateCallAccordion'
import SimulationSocketContext from '../../../contexts/simulation/SimulationSocketContext'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import { useJoinedSimulationsContext } from '../../../hooks/ContextHooks'
import InterfaceCustomizationDialog from './subcomponents/others/InterfaceCustomizationDialog'
import { useConfirm } from 'material-ui-confirm'
import AdminProtection from '../../../components/admin/AdminProtection'
import { getSimulationUsers, stopSimulation } from '../../../rest/simulation/queries/SimulationConfQueries'
import { LongPressEventType, useLongPress } from 'use-long-press'

let frequenciesBackup: FrequencyType[] = []

const Simulation = () => {
  const { simulationId } = useParams()
  const joinedSimulationsContext = useJoinedSimulationsContext()
  const activeParticipationList = joinedSimulationsContext?.activeParticipationList
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined)
  const [isTalking, setIsTalking] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [volume, setVolume] = useState<number | string | Array<number | string>>(100)
  const simulationSocket = useContext(SimulationSocketContext)
  const socket = simulationSocket?.socket
  const frontUser = useContext(UserContext)
  const [frequencies, setFrequencies] = useState<FrequencyType[]>([])
  const [isOk, setIsOk] = useState(true)
  const [inputDevice, setInputDevice] = useState<MediaDeviceInfo | undefined>(undefined)
  const [outputDevice, setOutputDevice] = useState<MediaDeviceInfo | undefined>(undefined)
  const [mediaDevices, setMediaDevices] = useState<{ input: MediaDeviceInfo[]; output: MediaDeviceInfo[] } | undefined>(
    undefined,
  )
  const [openCustomization, setOpenCustomization] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const confirm = useConfirm()
  const [callUserList, setCallUserList] = useState<{ label: string; value: string }[] | undefined>(undefined)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  frequenciesBackup = frequencies

  if (!mediaDevices) {
    if (navigator.mediaDevices) {
      // query media devices permissions

      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(() => {
          console.log('get user media OK')
          navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
              const inputDevices: MediaDeviceInfo[] = devices.filter((d) => d.kind === 'audioinput')
              const outputDevices: MediaDeviceInfo[] = devices.filter((d) => d.kind === 'audiooutput')
              outputDevices.forEach((d) => console.log('output device:', d.label))
              setMediaDevices({ input: inputDevices, output: outputDevices })
            })
            .catch(console.error)
        })
        .catch(() => console.log('get user media not OK'))
    }
  }

  const audioRef = useRef<FixedHTMLVideoElement>(null)

  const sendTalkingMessage = useCallback(
    (talking: boolean) => {
      if (socket && socket.isOpened) {
        // create dict corresponding to tx rooms, format : {"radio_1": "sid", "radio_2": "sid"}
        const freqTx: { [key: string]: string } = {}
        frequencies.forEach((f) => {
          if (f.state === FrequencyStateEnum.Tx) {
            freqTx[f.frequency] = f.sid
          }
        })
        console.log({
          username: frontUser?.user?.username,
          action: talking ? 'PRESS' : 'RELEASE',
          rooms: freqTx,
          key: 'PTT',
        })
        socket.webSocket?.sendJsonMessage({
          username: frontUser?.user?.username,
          action: talking ? 'PRESS' : 'RELEASE',
          rooms: freqTx,
          key: 'PTT',
        })
      }
    },
    [frequencies, frontUser?.user?.username, socket],
  )

  const { onMouseUp, onMouseLeave, onMouseDown, onTouchStart, onTouchEnd } = useHoldPress(() => {
    if (!isMuted) {
      setIsTalking(true)
      sendTalkingMessage(true)
    }
  }, 1000)

  const setFrequencyState = (frequency: FrequencyType, frequencyState: FrequencyStateEnum) => {
    setFrequencies((prevFrequencies) => {
      const newFrequencies = [...prevFrequencies]
      const frequencyIndex = newFrequencies.findIndex((f) => f.frequency === frequency.frequency)
      newFrequencies[frequencyIndex].state = frequencyState
      return newFrequencies
    })
  }

  const setFrequencySid = (frequency: FrequencyType, sid: string) => {
    console.log('setFrequencySid', frequency, 'sid', sid)
    setFrequencies((prevFrequencies) => {
      const newFrequencies = [...prevFrequencies]
      const frequencyIndex = newFrequencies.findIndex((f) => f.frequency === frequency.frequency)
      newFrequencies[frequencyIndex].sid = sid
      return newFrequencies
    })
  }

  const updateVolume = (value: number | string | Array<number | string>) => {
    if (typeof value === 'number') {
      if (value >= 0 && value <= 100) {
        setVolume(value)
        if (audioRef && audioRef.current) {
          audioRef.current.volume = value / 100
        }
      }
    }
  }

  const inputSelect = (e: SelectChangeEvent) => {
    simulationSocket?.mediaDevices.setInputDevice(mediaDevices?.input.find((d) => d.deviceId === e.target.value))
    frequencies.forEach((f) => {
      f.livekitRoom
        .switchActiveDevice('audioinput', e.target.value)
        .then(() => console.log('changed audio input'))
        .catch(console.error)
    })
    simulationSocket?.privateCall.livekitRoom.switchActiveDevice('audioinput', e.target.value).then().catch()

    navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: e.target.value,
      },
    })
    // .then((mediaStream) => {
    //   // TODO: UPDATE INPUT STREAM
    // })
    audioRef.current?.play().then().catch()
  }

  const outputSelect = (e: SelectChangeEvent) => {
    simulationSocket?.mediaDevices.setOutputDevice(mediaDevices?.output.find((d) => d.deviceId === e.target.value))
    frequencies.forEach((f) => {
      f.livekitRoom
        .switchActiveDevice('audiooutput', e.target.value)
        .then(() => console.log('changed audio output'))
        .catch(console.error)
    })
    simulationSocket?.privateCall.livekitRoom.switchActiveDevice('audiooutput', e.target.value).then().catch()

    if (audioRef.current) {
      audioRef.current.setSinkId(e.target.value).then().catch()
      audioRef.current.play().then().catch()
    }
  }

  useEffect(() => {
    setFrequencies(
      simulationSocket?.frequencies.map((f) => ({
        frequency: f.name,
        sid: '',
        state: FrequencyStateEnum.Idle,
        livekitRoom: f.livekitRoom,
        audioContext: f.audioContext,
      })) ?? [],
    )
  }, [simulationSocket?.frequencies])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key.toLowerCase() === 'p' && !isMuted && isOk) {
        setIsTalking(true)
        sendTalkingMessage(true)
        if (
          simulationSocket &&
          simulationSocket.privateCall &&
          simulationSocket.privateCall.livekitRoom.state === 'connected'
        ) {
          simulationSocket.privateCall.livekitRoom.localParticipant
            .setMicrophoneEnabled(false)
            .then()
            .catch(console.error)
        }
        setIsOk(false)
        setTimeout(() => {
          setIsOk(true)
        }, 1000)
      }
      // TODO: discuss about utility of using a global audio mute feature
      // else if (event.key.toLowerCase() === 'm') {
      //   if (isTalking) setIsTalking(false)
      //   if (isMuted) {
      //     setIsMuted(false)
      //     if (audioRef.current && audioRef.current.paused) {
      //       audioRef.current.play().then().catch()
      //     }
      //   } else {
      //     setIsMuted(true)
      //     sendTalkingMessage(false)
      //     if (isTalking) setIsTalking(false)
      //
      //     // if (audioRef.current && !audioRef.current.paused) {
      //     //   audioRef.current.pause()
      //     // }
      //   }
      // }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key.toLowerCase() === 'p') {
        setIsTalking(false)

        if (
          simulationSocket &&
          simulationSocket.privateCall &&
          simulationSocket.privateCall.livekitRoom.state === 'connected'
        ) {
          simulationSocket.privateCall.livekitRoom.localParticipant
            .setMicrophoneEnabled(true)
            .then()
            .catch(console.error)
        }
        if (!isMuted) sendTalkingMessage(false)
      }
    }

    const handleVisibiltyChange = () => {
      setIsTalking(false)
      if (
        simulationSocket &&
        simulationSocket.privateCall &&
        simulationSocket.privateCall.livekitRoom.state === 'connected'
      ) {
        simulationSocket.privateCall.livekitRoom.localParticipant.setMicrophoneEnabled(true).then().catch(console.error)
      }
      if (!isMuted) sendTalkingMessage(false)
    }

    if (!socket?.isOpened && simulationId) {
      const simulationIdNumber = Number(simulationId)
      if (activeParticipationList !== undefined) {
        // TODO: remove === 1
        if (
          activeParticipationList.find(
            (p) => p.simulation.id === simulationIdNumber && p.simulation.status === 'ACTIVE',
          ) ||
          simulationIdNumber === 1
        ) {
          socket?.open(simulationId)
          setIsValid(true)
        } else {
          setIsValid(false)
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)
    document.addEventListener('visibilitychange', handleVisibiltyChange)

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
      document.removeEventListener('visibilitychange', handleVisibiltyChange)
    }
  }, [
    isMuted,
    isTalking,
    socket,
    frontUser,
    frequencies,
    isOk,
    sendTalkingMessage,
    simulationSocket,
    simulationId,
    activeParticipationList,
  ])

  useEffect(() => {
    const onFocusOut = () => {
      if (isTalking) {
        sendTalkingMessage(false)
        setIsTalking(false)
      }
    }

    window.addEventListener('blur', onFocusOut, false)

    return function cleanup() {
      window.removeEventListener('blur', onFocusOut, false)
    }
  }, [isTalking, sendTalkingMessage])

  useEffect(() => {
    return function cleanup() {
      console.log('cleanup')
      console.log('frequencies', frequenciesBackup)
      frequenciesBackup.forEach((f) => {
        console.log('disconnecting', f.frequency)
        f.livekitRoom
          .disconnect(true)
          .then((r) => console.log(r))
          .catch(console.error)
      })
      socket?.close()
      setIsValid(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      socket?.close()
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return function cleanup() {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [socket])

  if (callUserList === undefined) {
    setCallUserList([])
    getSimulationUsers(Number(simulationId))
      .then((r) => {
        setCallUserList(
          r.map((u) => ({ label: `${u.nickname} (${u.firstname} ${u.lastname.toUpperCase()})`, value: u.username })),
        )
      })
      .catch(console.error)
  }

  if (!simulationId) {
    return <Navigate to={'/simulations'} />
  }
  if (isValid === false) {
    return <Navigate to={'/simulations'} />
  }
  if (isValid === undefined || !simulationSocket) {
    // TODO: if (isValid === undefined || !simulationSocket || simulationSocket.frequencies.length === 0) {
    return (
      <Dialog open={true}>
        <DialogTitle
          sx={{
            minWidth: '300px',
            maxWidth: '500px',
            mb: 1,
          }}
        >
          {t('simulations.running.loadingDialog.title')}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate('/simulations')}>{t('simulations.running.loadingDialog.goBack')}</Button>
        </DialogActions>
      </Dialog>
    )
  }

  if (simulationSocket.isError) {
    return <Navigate to={'/simulations'} />
  }

  return (
    <>
      <Tooltip
        title={
          isMobile
            ? isTalking
              ? t('tooltips.simulations.running.pttMicOnMobile')
              : t('tooltips.simulations.running.pttMicOffMobile')
            : t('tooltips.simulations.running.pttMic')
        }
        placement={'top'}
      >
        <IconButton
          size={'medium'}
          color={isTalking ? 'success' : 'error'}
          onMouseDown={() => {
            if (!isMuted) {
              onMouseDown()
              setIsTalking(true)
              sendTalkingMessage(true)
              if (
                simulationSocket &&
                simulationSocket.privateCall &&
                simulationSocket.privateCall.livekitRoom.state === 'connected'
              ) {
                simulationSocket.privateCall.livekitRoom.localParticipant
                  .setMicrophoneEnabled(false)
                  .then()
                  .catch(console.error)
              }
            }
          }}
          onContextMenu={(event) => {
            event.preventDefault()
          }}
          onPointerDown={(event) => {
            if (event.pointerType !== 'mouse') {
              if (!isMuted) {
                onTouchStart()
                setIsTalking(true)
                sendTalkingMessage(true)
                if (
                  simulationSocket &&
                  simulationSocket.privateCall &&
                  simulationSocket.privateCall.livekitRoom.state === 'connected'
                ) {
                  simulationSocket.privateCall.livekitRoom.localParticipant
                    .setMicrophoneEnabled(false)
                    .then()
                    .catch(console.error)
                }
              }
            }
          }}
          onPointerUp={(event) => {
            onTouchEnd()
            setIsTalking(false)
            if (!isMuted) sendTalkingMessage(false)
            if (
              simulationSocket &&
              simulationSocket.privateCall &&
              simulationSocket.privateCall.livekitRoom.state === 'connected'
            ) {
              simulationSocket.privateCall.livekitRoom.localParticipant
                .setMicrophoneEnabled(true)
                .then()
                .catch(console.error)
            }
          }}
          onMouseUp={() => {
            onMouseUp()
            setIsTalking(false)
            if (!isMuted) sendTalkingMessage(false)
            if (
              simulationSocket &&
              simulationSocket.privateCall &&
              simulationSocket.privateCall.livekitRoom.state === 'connected'
            ) {
              simulationSocket.privateCall.livekitRoom.localParticipant
                .setMicrophoneEnabled(true)
                .then()
                .catch(console.error)
            }
          }}
          onMouseLeave={() => {
            onMouseLeave()
            setIsTalking(false)
            if (!isMuted) sendTalkingMessage(false)
            if (
              simulationSocket &&
              simulationSocket.privateCall &&
              simulationSocket.privateCall.livekitRoom.state === 'connected'
            ) {
              simulationSocket.privateCall.livekitRoom.localParticipant
                .setMicrophoneEnabled(true)
                .then()
                .catch(console.error)
            }
          }}
          onPointerLeave={() => {
            onTouchEnd()
            setIsTalking(false)
            if (!isMuted) sendTalkingMessage(false)
            if (
              simulationSocket &&
              simulationSocket.privateCall &&
              simulationSocket.privateCall.livekitRoom.state === 'connected'
            ) {
              simulationSocket.privateCall.livekitRoom.localParticipant
                .setMicrophoneEnabled(true)
                .then()
                .catch(console.error)
            }
          }}
        >
          {isTalking ? (
            <MicIcon
              sx={{
                fontSize: '2.5rem',
              }}
            />
          ) : (
            <MicOffIcon
              sx={{
                fontSize: '2.5rem',
              }}
            />
          )}
        </IconButton>
      </Tooltip>
      {/* TODO: discuss about utility of using a global audio mute feature */}
      {/*<Tooltip*/}
      {/*  title={isMuted ? t('tooltips.simulations.running.unmuteAudio') : t('tooltips.simulations.running.muteAudio')}*/}
      {/*  placement={'top'}*/}
      {/*>*/}
      {/*  <IconButton*/}
      {/*    size={'medium'}*/}
      {/*    color={isMuted ? 'error' : 'success'}*/}
      {/*    onClick={() => {*/}
      {/*      if (isTalking) setIsTalking(false)*/}
      {/*      if (isMuted) {*/}
      {/*        setIsMuted(false)*/}
      {/*        if (audioRef.current && audioRef.current.paused) {*/}
      {/*          audioRef.current.play().then().catch()*/}
      {/*        }*/}
      {/*      } else {*/}
      {/*        setIsMuted(true)*/}
      {/*        if (audioRef.current && !audioRef.current.paused) {*/}
      {/*          audioRef.current.pause()*/}
      {/*        }*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {isMuted ? (*/}
      {/*      <VolumeOffIcon*/}
      {/*        sx={{*/}
      {/*          fontSize: '2.5rem',*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    ) : (*/}
      {/*      <VolumeUpIcon*/}
      {/*        sx={{*/}
      {/*          fontSize: '2.5rem',*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </IconButton>*/}
      {/*</Tooltip>*/}

      <StatusPopover socket={socket} frequencies={frequencies ?? []} />

      <SettingsPopover
        inputDevice={simulationSocket?.mediaDevices.inputDevice}
        inputSelect={inputSelect}
        outputDevice={simulationSocket?.mediaDevices.outputDevice}
        outputSelect={outputSelect}
        mediaDevices={mediaDevices}
        volume={volume}
        updateVolume={updateVolume}
      />

      <IconButton onClick={() => setOpenCustomization(true)}>
        <PaletteIcon sx={{ fontSize: '2.5rem', color: 'grey' }} />
      </IconButton>

      <InterfaceCustomizationDialog open={openCustomization} setOpen={setOpenCustomization} />

      <br />
      {/*{frequencies.map((f) => {*/}
      {/*  return <audio key={f.frequency} ref={audioRef} />*/}
      {/*})}*/}

      {mediaDevices && mediaDevices?.input.length > 0 ? (
        <CardFrequencyBox
          frequencies={frequencies}
          setFrequencyState={setFrequencyState}
          setFrequencySid={setFrequencySid}
        />
      ) : (
        <Alert
          severity={'error'}
          sx={{
            width: '50%',
            minWidth: '300px',
            margin: '0 auto',
            textAlign: 'left',
            mt: 3,
          }}
          variant={'outlined'}
        >
          <AlertTitle>{t('simulations.running.alert.title')}</AlertTitle>
          {t('simulations.running.alert.description')}
        </Alert>
      )}

      <AdminProtection mode={'hide'}>
        <Button
          variant={'contained'}
          color={'error'}
          onClick={() => {
            confirm({
              title: 'Are you sure you want to stop the simulation ?',
              description: 'This action is irreversible.',
              confirmationText: 'Delete',
              cancellationText: 'Cancel',
            })
              .then(() => {
                stopSimulation(Number(simulationId)).then().catch()
              })
              .catch(() => {})
          }}
          sx={{ mt: 5 }}
        >
          STOP SIMULATION
        </Button>
      </AdminProtection>

      <PrivateCallAccordion simulationId={Number(simulationId)} callUserList={callUserList ?? []} />
    </>
  )
}

export default Simulation
