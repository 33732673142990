import { deleteR, get, post, put } from '../rest-utils'
import Accent from './enums/Accent'
import Language from './enums/Language'
import { Gender } from './enums/Gender'

export default interface APIUser {
  id: number
  username: string
  lastname: string
  firstname: string
  nickname: string
  phoneNumber: string
  email: string
  age: number
  gender: Gender
  status: string
  creationDate: Date
  lastConnectionDate: Date
  languages: Language[]
  accents: Accent[]
}

export interface APIUserNames {
  id: number
  username: string
  lastname: string
  firstname: string
  nickname: string
}

export function getFormattedAccents(user: APIUser): string {
  return user.accents.map((accent) => Accent[accent as unknown as keyof typeof Accent]).join(', ')
}

export function getFormattedLanguages(user: APIUser): string {
  return user.languages.map((language) => Language[language as unknown as keyof typeof Language]).join(', ')
}

export async function getUser(id: number): Promise<APIUser> {
  return get<APIUser>(`users/user?id=${id}`)
}

export async function getUserByName(username: string): Promise<APIUser> {
  return get<APIUser>(`users/user?username=${username}`)
}

export async function getUserList(username: string[]): Promise<APIUserNames[]> {
  return get<APIUserNames[]>(`users/users?usernames=${username.join(',')}`)
}

export async function getUsers(page: number, size: number): Promise<APIUser[]> {
  return get<APIUser[]>(`users/all/${page}/${size}`)
}

export async function postUser(user: object | JSON, password: string): Promise<APIUser> {
  return post<APIUser>('users', { ...user, password: password }, undefined, false)
}

export async function putUser(user: object | JSON): Promise<APIUser> {
  return put<APIUser>('users', user)
}

export async function deleteUser(id: number): Promise<APIUser> {
  return deleteR<APIUser>(`users`, { id: id })
}
