import { Box, CircularProgress, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { DataGrid, GridActionsCellItem, GridColumns, GridRowsProp, GridToolbar } from '@mui/x-data-grid'
import APIUser, { deleteUser, getFormattedAccents, getFormattedLanguages, getUsers } from '../../rest/user/APIUser'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'

function AccountList() {
  const [users, setUsers] = React.useState<APIUser[]>([])
  const [pageSize, setPageSize] = React.useState(25)
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate()
  const confirm = useConfirm()

  if (users.length === 0) {
    getUsers(0, 10000)
      .then((u) => {
        setUsers(u)
        setLoading(false)
      })
      .catch((e) => {
        navigate('/' + e)
      })
    return (
      <>
        <Typography variant="h2" component="div" gutterBottom>
          Account list
        </Typography>
        <CircularProgress />
      </>
    )
  }

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', width: 70, hideable: false },
    { field: 'username', headerName: 'Username', width: 150, hideable: false },
    { field: 'nickname', headerName: 'Nickname', width: 150 },
    { field: 'firstName', headerName: 'First name', width: 150 },
    { field: 'lastName', headerName: 'Last name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone number', width: 125 },
    { field: 'age', headerName: 'Age', width: 50 },
    { field: 'gender', headerName: 'Gender', width: 80 },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'creationDate', headerName: 'Creation date', width: 105 },
    { field: 'lastConnectionDate', headerName: 'Last connection', width: 155 },
    { field: 'languages', headerName: 'Languages', width: 200 },
    { field: 'accents', headerName: 'Accents', width: 200 },
    { field: 'weeklyAvailability', headerName: 'Weekly availability', width: 200 },
    { field: 'calendarAvailability', headerName: 'Calendar availability', minWidth: 200 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      hideable: false,
      getActions: ({ id }) => {
        const username = users.find((u) => u.id === id)?.username
        return [
          <Tooltip key={id} title={'Edit'} disableInteractive>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              color="primary"
              onClick={() => {
                navigate('/admin/account-list/' + id)
              }}
            />
          </Tooltip>,
          <Tooltip key={id} title="Delete" disableInteractive>
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              className="textPrimary"
              color="error"
              onClick={() => {
                const numId = parseInt(id.toString())
                confirm({
                  title: 'Are you sure you want to delete ' + username + '?',
                  description:
                    "This action is definitive and cannot be undone. Please type 'DELETE " + username + "' to confirm.",
                  confirmationText: 'Delete',
                  cancellationText: 'Cancel',
                  confirmationKeyword: 'DELETE ' + username,
                }).then(() => {
                  deleteUser(numId)
                    .then(() => {
                      toast.success('User ' + username + ' deleted successfully')
                      setUsers(users.filter((u) => u.id !== numId))
                    })
                    .catch((e) => {
                      toast.error('Error while deleting user ' + username + ': ' + e)
                      navigate('/' + e)
                    })
                })
              }}
            />
          </Tooltip>,
        ]
      },
    },
  ]

  const rows: GridRowsProp = users.map((u) => {
    return {
      id: u.id,
      username: u.username,
      nickname: u.nickname,
      firstName: u.firstname,
      lastName: u.lastname,
      email: u.email,
      phoneNumber: u.phoneNumber,
      age: u.age,
      gender: u.gender,
      status: u.status,
      creationDate: new Date(u.creationDate).toLocaleDateString(),
      lastConnectionDate:
        new Date(u.creationDate).toLocaleDateString() + ' ' + new Date(u.creationDate).toLocaleTimeString(),
      languages: getFormattedLanguages(u),
      accents: getFormattedAccents(u),
    }
  })

  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        Account list
      </Typography>
      <Box display="flex" justifyContent="center">
        <Box width="80%" height="450px">
          <DataGrid
            loading={loading}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[25, 50, 100]}
            pagination
            rows={rows}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            sx={{
              borderRadius: 1,
              boxShadow: 5,
              borderColor: 'primary.main',
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  firstName: false,
                  lastName: false,
                  nickname: false,
                  phoneNumber: false,
                  age: false,
                  gender: false,
                  lastConnectionDate: false,
                  languages: false,
                  accents: false,
                  weeklyAvailability: false,
                  calendarAvailability: false,
                },
              },
            }}
          />
        </Box>
      </Box>
    </>
  )
}

export default AccountList
