import SettingsIcon from '@mui/icons-material/Settings'
import { Box, Divider, Grid, Input, MenuItem, Slider, Tooltip, Typography, useTheme } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import GenericIconPopover from '../../../../../components/mui/pops/GenericIconPopover'
import { useTranslation } from 'react-i18next'

interface SettingsPopoverProps {
  inputDevice: MediaDeviceInfo | undefined
  inputSelect: (event: SelectChangeEvent) => void
  outputDevice: MediaDeviceInfo | undefined
  outputSelect: (event: SelectChangeEvent) => void
  mediaDevices: { input: MediaDeviceInfo[]; output: MediaDeviceInfo[] } | undefined
  volume: number | string | Array<number | string>
  updateVolume: (value: number | string | Array<number | string>) => void
}

const SettingsPopover = (props: SettingsPopoverProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { inputDevice, inputSelect, outputDevice, outputSelect, mediaDevices, volume, updateVolume } = props
  const hasInputAccess = !(
    mediaDevices === undefined ||
    mediaDevices?.input.length === 0 ||
    (mediaDevices?.input.length === 1 && mediaDevices?.input[0].label == '')
  )
  const hasOutputAccess = !(
    mediaDevices === undefined ||
    mediaDevices?.output.length === 0 ||
    (mediaDevices?.output.length === 1 && mediaDevices?.output[0].label == '')
  )

  return (
    <>
      <GenericIconPopover
        buttonContent={<SettingsIcon sx={{ fontSize: '2.5rem', color: 'grey' }} />}
        popoverContent={
          <Box
            width={250}
            sx={{
              m: 2,
            }}
          >
            <Box>
              <Typography gutterBottom>{t('simulations.running.settings.inputDevice')}</Typography>
              <Tooltip
                title={!hasInputAccess ? t('tooltips.simulations.running.settings.cannotAccessInput') : null}
                placement={'top'}
              >
                <Select
                  value={inputDevice ? inputDevice.deviceId : hasInputAccess ? mediaDevices?.input[0].deviceId : ''}
                  disabled={!hasInputAccess}
                  onChange={(event) => {
                    inputSelect(event)
                  }}
                  sx={{ width: 250 }}
                >
                  {mediaDevices &&
                    mediaDevices.input.map((d) => {
                      return (
                        <MenuItem key={d.deviceId} value={d.deviceId}>
                          {d.label}
                        </MenuItem>
                      )
                    })}
                </Select>
              </Tooltip>
            </Box>
            <Divider color={theme.palette.primary.main} sx={{ mt: 2, mb: 1.5 }} />
            <Box>
              <Typography gutterBottom>{t('simulations.running.settings.outputDevice')}</Typography>
              <Tooltip
                title={
                  !hasOutputAccess
                    ? t('tooltips.simulations.running.settings.cannotAccessOutput')
                    : 'Output device selection is currently disabled.'
                }
                placement={'top'}
              >
                <Select
                  value={outputDevice ? outputDevice.deviceId : hasOutputAccess ? mediaDevices?.output[0].deviceId : ''}
                  onChange={(event) => {
                    outputSelect(event)
                  }}
                  disabled // TODO: Repair output device selection, and set disabled={!hasOutputAccess}
                  sx={{ width: 250 }}
                >
                  {mediaDevices &&
                    mediaDevices.output.map((d) => {
                      return (
                        <MenuItem key={d.deviceId} value={d.deviceId}>
                          {d.label}
                        </MenuItem>
                      )
                    })}
                </Select>
              </Tooltip>
            </Box>
            <Divider color={theme.palette.primary.main} sx={{ mt: 2, mb: 1.5 }} />
            <Typography gutterBottom>{t('simulations.running.settings.volume')}</Typography>
            <Tooltip title={'Volume control is currently disabled.'}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <VolumeUpIcon />
                </Grid>
                <Grid item xs>
                  <Slider
                    disabled // TODO: REPAIR VOLUME
                    min={0}
                    max={100}
                    value={typeof volume === 'number' ? volume : 0}
                    onChange={(event, value) => {
                      updateVolume(value)
                    }}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item>
                  <Input
                    disabled // TODO: REPAIR VOLUME
                    size="small"
                    sx={{ width: 45 }}
                    value={volume}
                    onChange={(event) => {
                      const value = event.target.value === '' ? 0 : Number(event.target.value)
                      updateVolume(value)
                    }}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
              </Grid>
            </Tooltip>
          </Box>
        }
      />
    </>
  )
}

export default SettingsPopover
