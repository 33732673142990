import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import Chip from '@mui/material/Chip'
import { DatePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import { SimulationRequestComponent } from './SimulationRequestComponent'
import { GenericResponsiveListComponent } from '../../../../components/mui/GenericResponsiveListComponent'
import { APISimulationRequest } from '../../../../rest/simulation/models/APISimulationRequest'
import { getPublishedSimulationRequests } from '../../../../rest/simulation/queries/SimulationRequestQueries'

export const PublishedSimulationList = () => {
  const [simulations, setSimulations] = useState<APISimulationRequest[] | undefined>(undefined)
  const [tagFilters, setTagFilters] = useState<string[]>([])
  const [filteredSimulations, setFilteredSimulations] = useState<APISimulationRequest[] | undefined>(undefined)
  const [dateFilter, setDateFilter] = useState<Date | null>(null)
  const { t } = useTranslation()

  const filterByAllFilters = (localTagFilters: string[], localDateFilter: Date | null) => {
    let tempFilteredSimulations = simulations
    localTagFilters.forEach((f) => {
      tempFilteredSimulations = tempFilteredSimulations?.filter((s: APISimulationRequest) => {
        if (s.simulation.tags !== undefined) {
          let included = false
          s.simulation.tags.forEach((tag) => {
            if (tag.includes(f)) {
              included = true
            }
          })
          return included
        }
        return false
      })
    })
    if (localDateFilter !== null) {
      tempFilteredSimulations = tempFilteredSimulations?.filter((s: APISimulationRequest) => {
        const startDate = new Date(s.startDate)
        startDate.setHours(0, 0, 0, 0)
        const endDate = new Date(s.endDate)
        endDate.setHours(0, 0, 0, 0)
        const dateToFilter = new Date(localDateFilter)
        dateToFilter.setHours(0, 0, 0, 0)
        return startDate <= dateToFilter && endDate >= dateToFilter
      })
    }
    setFilteredSimulations(tempFilteredSimulations)
  }

  const addTagFilter = (filter: string) => {
    setTagFilters([...tagFilters, filter])
    filterByAllFilters([...tagFilters, filter], dateFilter)
  }

  const addDateFilter = (date: Date) => {
    setDateFilter(date)
    filterByAllFilters(tagFilters, date)
  }

  const removeTagFilter = (filter: string) => {
    const newFilters = tagFilters.filter((f) => f !== filter)
    setTagFilters(newFilters)
    filterByAllFilters(newFilters, dateFilter)
  }

  const removeDateFilter = () => {
    setDateFilter(null)
    filterByAllFilters(tagFilters, null)
  }

  const clearTagFilters = () => {
    setTagFilters([])
    filterByAllFilters([], dateFilter)
  }

  if (simulations === undefined) {
    getPublishedSimulationRequests()
      .then((sims) => {
        setSimulations(sims)
        setFilteredSimulations(sims)
      })
      .catch(() => {
        setSimulations([])
        setFilteredSimulations([])
      })
  }

  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        {t('simulations.published.title')}
      </Typography>
      {simulations === undefined ? (
        <CircularProgress />
      ) : simulations.length === 0 ? (
        <Typography>{t('simulations.published.notFound')}</Typography>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Autocomplete
              multiple
              value={tagFilters}
              onChange={(event, newValue) => {
                if (newValue.length > tagFilters.length) {
                  addTagFilter(newValue[newValue.length - 1])
                } else {
                  if (newValue.length !== 0) {
                    const removedFilter = tagFilters.filter((f) => !newValue.includes(f))[0]
                    removeTagFilter(removedFilter)
                  } else {
                    clearTagFilters()
                  }
                }
              }}
              options={simulations
                .flatMap((s) => s.simulation.tags)
                .filter((v, i, a) => a.indexOf(v) === i)
                .sort()}
              freeSolo
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('simulations.published.filterByTags') ?? ''}
                  placeholder={t('simulations.published.addTag') ?? ''}
                />
              )}
              sx={{ width: '40%', minWidth: '225px', maxWidth: '500px' }}
            />
            <DatePicker
              label={t('simulations.published.filterByDate') ?? ''}
              onChange={(value) => {
                const date = new Date(value as Date)
                if (value === null || date.toDateString() === 'Invalid Date') {
                  removeDateFilter()
                  return
                }
                addDateFilter(date)
              }}
              sx={{ width: '20%', minWidth: '125px', maxWidth: '200px' }}
            />
          </Box>
          {filteredSimulations && (
            <GenericResponsiveListComponent<APISimulationRequest>
              tList={filteredSimulations}
              subComponentWidth={320}
              subComponent={(value) => {
                return <SimulationRequestComponent key={value.id} simulation={value} viewRoles={true} />
              }}
            />
          )}
        </>
      )}
    </>
  )
}
