export default {
  ENGLISH: 'Anglais',
  CHINESE: 'Chinois',
  HINDI: 'Hindi',
  SPANISH: 'Espagnol',
  ARABIC: 'Arabe',
  FRENCH: 'Français',
  RUSSIAN: 'Russe',
  PORTUGUESE: 'Portugais',
  JAPANESE: 'Japonais',
  GERMAN: 'Allemand',
  HOLLAND: 'Hollandais',
  ITALIAN: 'Italien',
  OTHER: 'Autre',
}
