import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface FormDialogProps {
  title: string
  content?: React.ReactNode
  description?: string
  actions?: React.ReactNode
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLDivElement>) => void
  onCancel?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onClose?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  sx?: SxProps
}

function FormDialog(props: FormDialogProps): React.ReactElement {
  const { title, content, description, actions, open, setOpen, onConfirm, onClose, onCancel, sx } = props
  const { t } = useTranslation()

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLDivElement>) => {
    if (onConfirm) {
      onConfirm(event)
    }
    setOpen(false)
  }

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onCancel) {
      onCancel(event)
    } else {
      setOpen(false)
    }
  }

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClose) {
      onClose(event)
    } else {
      setOpen(false)
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            handleConfirm(event)
          }
        }}
        sx={sx}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {description && <DialogContentText>{description}</DialogContentText>}
          {content && content}
        </DialogContent>
        {actions ? (
          actions
        ) : (
          <DialogActions>
            <>
              <Button
                variant="contained"
                color={'error'}
                onClick={(event) => {
                  handleCancel(event)
                }}
              >
                {t('button.cancel')}
              </Button>
              <Button
                variant="contained"
                color={'success'}
                sx={{ color: 'white' }}
                onClick={(event) => {
                  handleConfirm(event)
                }}
              >
                {t('button.confirm')}
              </Button>
            </>
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}

export default FormDialog
export type { FormDialogProps }
