export default {
  home: 'Home',
  account: 'Account',
  profile: 'Profile',
  userProfile: "{{username}}'s profile",
  admin: 'Admin',
  adminAccountList: 'Account list',
  adminEditAccount: 'Edit account (id:{{accountId}})',
  adminSimulationHistory: 'Simulation requests history',
  simulations: 'Simulations',
  simulationsCreate: 'Create simulation',
  simulationsList: 'Published simulations',
  simulationsViewRoles: 'Roles of simulation n°{{simulationId}}',
  simulationsRoles: 'Roles',
  simulationsHistory: 'History',
  simulationsManage: 'Manage',
  simulationsManageRoles: 'Manage roles',
  runningSimulation: 'Simulation in progress',
  403: 'Error 403',
  404: 'Error 404',
  500: 'Error 500',
}
