import React from 'react'
import { Card, IconButton, TextField, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'

interface CardConferenceProps {
  id: number
  frequency: string
  handleDeleteFrequency: (id: number) => void
  handleInputText: (event: React.ChangeEvent<HTMLInputElement>) => void
  isSingle: boolean
}

function CardSimulationFormFrequency(props: CardConferenceProps) {
  const [t] = useTranslation()
  const { id, handleDeleteFrequency, handleInputText, frequency, isSingle } = props

  return (
    <Card
      variant={'elevation'}
      sx={{ mb: 2, p: 1, display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: '4em' }}
    >
      <Typography>
        {t('simulations.create.channels.frequency')} {props.id + 1}
      </Typography>

      <TextField
        size={'small'}
        value={frequency}
        label={t('simulations.create.channels.text')}
        onInput={handleInputText}
        placeholder={t('simulations.create.channels.placeholder')?.toString()}
        sx={{ width: '7em' }}
        inputProps={{ maxLength: 20 }}
        error={frequency.length < 2}
      />

      <IconButton disabled={isSingle} sx={{ color: 'red' }} onClick={() => handleDeleteFrequency(id)}>
        <DeleteIcon />
      </IconButton>
    </Card>
  )
}

export default CardSimulationFormFrequency
