import { createContext } from 'react'
import { APISimulationRequest } from '../../rest/simulation/models/APISimulationRequest'

export type SimulationManagementType = {
  simulationRequests: APISimulationRequest[] | undefined
  setSimulationRequests: (simulationRequests: APISimulationRequest[]) => void
  callSimulationRequests: () => void
}

const SimulationManagementContext = createContext<SimulationManagementType | undefined>(undefined)

export default SimulationManagementContext
