import { Card, CardActionArea, CardContent, SxProps, Typography } from '@mui/material'
import React from 'react'
import MediaQuery from 'react-responsive'

interface TextColor {
  titleColor?: string
  descriptionColor?: string
}

interface CardButtonProps {
  title: string
  description: React.ReactNode
  onClick?: () => void
  sx?: SxProps
  textColor?: TextColor
  cardActionProps?: React.ComponentProps<typeof CardActionArea>
  icon?: React.ReactNode
}

function ResponsiveCardButton(props: CardButtonProps) {
  const { title, description, onClick, sx, cardActionProps, textColor, icon } = props

  return (
    <Card
      onClick={onClick}
      sx={{
        ...sx,
        boxShadow: 5,
        m: 1,
        minHeight: '115px',
      }}
    >
      <CardActionArea {...cardActionProps}>
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            color={textColor && textColor.descriptionColor ? textColor.descriptionColor : 'text.primary'}
          >
            {icon} {title} {icon}
          </Typography>
          <Typography
            variant="body2"
            color={textColor && textColor.descriptionColor ? textColor.descriptionColor : 'primary.main'}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

function CardButton(props: CardButtonProps) {
  const { title, description, onClick, sx, textColor, icon } = props

  return (
    <>
      <MediaQuery minWidth={992}>
        <ResponsiveCardButton
          title={title}
          description={description}
          onClick={onClick}
          sx={{
            ...sx,
            maxWidth: '400px',
            minHeight: '115px',
          }}
          textColor={textColor}
          cardActionProps={{
            sx: {
              height: '100%',
              minHeight: '115px',
            },
          }}
          icon={icon}
        />
      </MediaQuery>
      <MediaQuery minWidth={540} maxWidth={991.9}>
        <ResponsiveCardButton
          title={title}
          description={description}
          onClick={onClick}
          sx={{
            ...sx,
            minWidth: '400px',
            minHeight: '115px',
          }}
          textColor={textColor}
          cardActionProps={{
            sx: {
              height: '100%',
              minHeight: '115px',
            },
          }}
          icon={icon}
        />
        <br />
      </MediaQuery>
      <MediaQuery maxWidth={539.99}>
        <ResponsiveCardButton
          title={title}
          description={description}
          onClick={onClick}
          sx={{
            ...sx,
            minWidth: '200px',
            minHeight: '115px',
          }}
          textColor={textColor}
          cardActionProps={{
            sx: {
              height: '100%',
              minHeight: '115px',
            },
          }}
          icon={icon}
        />
        <br />
      </MediaQuery>
    </>
  )
}

export default CardButton
export type { CardButtonProps }
