import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Chip, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import React, { useContext } from 'react'
import SimulationManagementApplicationsContext from '../../../contexts/management-application/SimulationManagementApplicationsContext'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import toast from 'react-hot-toast'
import { useConfirm } from 'material-ui-confirm'
import Candidate from './CandidatesType'
import { useSimulationManagementApplicationsContext } from '../../../hooks/ContextHooks'
import { APISimulationRole } from '../../../rest/simulation/models/APISimulationRole'
import {
  putAcceptParticipation,
  putRejectParticipation,
} from '../../../rest/simulation/queries/SimulationParticipationQueries'
import { APIVoluntaryUser } from '../../../rest/simulation/models/APIVoluntaryUser'

interface ApplicationItemProps {
  filteredCandidate: Candidate
}

function ApplicationItem(props: ApplicationItemProps) {
  const { filteredCandidate } = props
  const navigate = useNavigate()
  const confirm = useConfirm()
  const mode = localStorage.getItem('mode')
  const { simulationRequest, updateVoluntaryUser } = useSimulationManagementApplicationsContext()

  //TODO: Change the text

  function handleAccept() {
    confirm({
      title: t('simulations.applications.accept.title'),
      description: t('simulations.applications.accept.description', {
        user: filteredCandidate?.user.nickname,
        role: simulationRequest?.simulation.roles.find(
          (role: APISimulationRole) => role.id == filteredCandidate?.voluntaryUser.roleId,
        ),
        simulation: simulationRequest?.simulation.name,
      }),
      confirmationText: t('button.confirm'),
      cancellationText: t('button.cancel'),
    }).then(() => {
      if (filteredCandidate?.voluntaryUser?.id != null) {
        putAcceptParticipation(filteredCandidate.voluntaryUser.id)
          .then((voluntaryUserAccepted) => {
            toast.success(t('simulations.applications.accept.success', { user: filteredCandidate.user?.nickname }))
            //TODO: Update the context
            updateVoluntaryUser(voluntaryUserAccepted)
          })
          .catch(() => {
            toast.error(t('simulations.applications.accept.error', { user: filteredCandidate.user?.nickname }))
          })
      }
    })
  }

  function handleReject() {
    confirm({
      title: t('simulations.applications.reject.title'),
      description: t('simulations.applications.reject.description', {
        user: filteredCandidate?.user?.nickname,
        role: simulationRequest?.simulation.roles.find(
          (role: APISimulationRole) => role.id == filteredCandidate?.voluntaryUser.roleId,
        ),
        simulation: simulationRequest?.simulation.name,
      }),
      confirmationText: t('button.confirm'),
      cancellationText: t('button.cancel'),
    }).then(() => {
      if (filteredCandidate?.voluntaryUser?.id != null) {
        putRejectParticipation(filteredCandidate?.voluntaryUser.id)
          .then((voluntaryUserRejected) => {
            toast.success(t('simulations.applications.reject.success', { user: filteredCandidate.user?.nickname }))
            updateVoluntaryUser(voluntaryUserRejected)
          })
          .catch(() => {
            toast.error(t('simulations.applications.reject.error', { user: filteredCandidate.user?.nickname }))
          })
      }
    })
  }

  function viewProfile() {
    navigate('/profile/' + filteredCandidate?.user?.username)
  }

  function chipColor() {
    switch (filteredCandidate?.voluntaryUser.acceptanceStatus) {
      case 'ACCEPTED':
        return 'success'
      case 'REJECTED':
        return 'error'
      case 'PENDING':
        return 'warning'
      default:
        return 'warning'
    }
  }

  return (
    <Card
      sx={{
        width: '100%',
        m: 'auto',
        mt: 1,
        p: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: 3,
        bgcolor: mode === '"dark"' ? 'background.paper' : 'rgba(0, 0, 0, 0.08)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ mr: 1 }}>{filteredCandidate?.user?.nickname}</Typography>
        <Chip
          variant={mode === '"dark"' ? 'outlined' : 'filled'}
          label={t('candidacyStatus.' + filteredCandidate?.voluntaryUser?.acceptanceStatus)}
          color={chipColor()}
        />
      </Box>
      <Box>
        <Button color={'info'} variant={'contained'} onClick={() => viewProfile()}>
          Profile
        </Button>
        <>
          {filteredCandidate?.voluntaryUser.acceptanceStatus === 'PENDING' && (
            <Button color={'error'} variant={'contained'} sx={{ ml: 1 }} onClick={() => handleReject()}>
              Reject
            </Button>
          )}
          {filteredCandidate?.voluntaryUser.acceptanceStatus !== 'ACCEPTED' && (
            <Button color={'success'} variant={'contained'} sx={{ ml: 1 }} onClick={() => handleAccept()}>
              Accept
            </Button>
          )}
        </>
      </Box>
    </Card>
  )
}

interface SimulationManagementApplicationElementProps {
  role: APISimulationRole
  voluntaryUsers: APIVoluntaryUser[] | undefined
  accepted?: boolean
}

function SimulationManagementElement(props: SimulationManagementApplicationElementProps) {
  const { role, voluntaryUsers, accepted } = props
  const candidates = useContext(SimulationManagementApplicationsContext)?.candidates

  if (!voluntaryUsers) return <></>
  else {
    const tempUsers = voluntaryUsers.map((voluntaryUser) => {
      return candidates?.find((candidate) => candidate.voluntaryUser.id == voluntaryUser.id)
    })
    const users = tempUsers.filter((user) => user !== undefined) as Candidate[]

    return (
      <Accordion disableGutters sx={{ mt: 2, boxShadow: 5 }}>
        <AccordionSummary
          disabled={voluntaryUsers?.length === 0}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>{role.name}</Typography>
            {accepted && (
              <Chip
                label={t('simulations.applications.accepted')}
                sx={{
                  ml: 2,
                  color: 'white',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                variant={'filled'}
                color={'success'}
              />
            )}
            {voluntaryUsers?.filter((volUser) => volUser.acceptanceStatus === 'PENDING').length === 0 && !accepted && (
              <Chip
                label={t('simulations.applications.noRequests')}
                sx={{
                  ml: 2,
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                variant={'outlined'}
              />
            )}
            {voluntaryUsers?.filter((volUser) => volUser.acceptanceStatus === 'PENDING').length > 0 && (
              <Chip
                label={
                  voluntaryUsers?.filter((volUser) => volUser.acceptanceStatus === 'PENDING').length +
                  t('simulations.applications.requests')
                }
                sx={{
                  ml: 2,
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                variant={'filled'}
              />
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: 'secondary.light' }}>
          {users.map((candidate) => {
            return <ApplicationItem key={candidate?.voluntaryUser.id} filteredCandidate={candidate} />
          })}
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default SimulationManagementElement
