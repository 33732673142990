import { Accordion, AccordionDetails, AccordionSummary, CardActionArea, SxProps } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { PrivateCall } from '../../../../../contexts/simulation/PrivateCall'

interface CallListAccordionProps {
  privateCalls: PrivateCall[]
  accordionSx?: SxProps
  SummaryComponent: JSX.Element
  CallCardComponent: (props: { call: PrivateCall }) => JSX.Element
}

const CallListAccordion = (props: CallListAccordionProps) => {
  const { privateCalls, accordionSx, SummaryComponent, CallCardComponent } = props

  return (
    <Accordion
      disableGutters
      className={'MuiAccordion-rounded'}
      sx={{
        ...accordionSx,
        borderRadius: '10px',
      }}
    >
      <CardActionArea
        sx={{
          bgcolor: 'secondary.dark',
          color: 'white',
          height: '100%',
          width: '100%',
          ':hover': {
            cursor: 'pointer',
            bgcolor: 'secondary.dark',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color={'secondary'} />}
          sx={{
            bgcolor: 'secondary.dark',
            color: 'white',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        >
          {SummaryComponent}
        </AccordionSummary>
      </CardActionArea>
      <AccordionDetails
        sx={{
          overflowY: 'auto',
          scrollBehaviour: 'smooth',
          maxHeight: 'calc(35vh - 60px)',
          mt: 2,
        }}
      >
        {privateCalls && privateCalls.map((call) => <CallCardComponent call={call} key={call.room} />)}
      </AccordionDetails>
    </Accordion>
  )
}

export default CallListAccordion
