import * as React from 'react'
import Popover from '@mui/material/Popover'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface GenericIconPopoverProps {
  buttonContent: React.ReactNode
  popoverContent: React.ReactNode
  CloseButton?: (props: { onClose: () => void }) => JSX.Element
  iconSx?: React.CSSProperties
  popoverSx?: React.CSSProperties
}

export default function GenericIconPopover(props: GenericIconPopoverProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const { buttonContent, popoverContent, iconSx, popoverSx, CloseButton } = props

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick} sx={iconSx}>
        {buttonContent}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={popoverSx}
      >
        {popoverContent}
        {CloseButton ? (
          <CloseButton onClose={handleClose} />
        ) : (
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleClose}
            size={'small'}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Popover>
    </>
  )
}
