import { Typography } from '@mui/material'

function InternalServerError() {
  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        500
      </Typography>
      <Typography variant="h4" component="div" gutterBottom>
        Internal Server Error
      </Typography>
    </>
  )
}

export default InternalServerError
