import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import SimulationRoleCard from './SimulationRoleCard'
import { GenericResponsiveListComponent } from '../../../components/mui/GenericResponsiveListComponent'
import { useTranslation } from 'react-i18next'
import { APISimulationRequest } from '../../../rest/simulation/models/APISimulationRequest'
import { APISimulationRole } from '../../../rest/simulation/models/APISimulationRole'
import { useJoinedSimulationsContext } from '../../../hooks/ContextHooks'
import { APIIncomingAcceptanceStatus } from '../../../rest/simulation/models/APIIncomingParticipationType'

function SimulationRoleList() {
  const [displaySimulation, setDisplaySimulation] = React.useState<
    {
      simulation: APISimulationRequest
      role: APISimulationRole
      acceptanceStatus: APIIncomingAcceptanceStatus
    }[]
  >([])
  const { t } = useTranslation()
  const participationRequestsList = useJoinedSimulationsContext()?.participationRequestsList

  useEffect(() => {
    if (participationRequestsList !== undefined && participationRequestsList.length !== 0) {
      const tempDisplaySimulation: {
        simulation: APISimulationRequest
        role: APISimulationRole
        acceptanceStatus: APIIncomingAcceptanceStatus
      }[] = []
      participationRequestsList.forEach((p) => {
        tempDisplaySimulation.push({ simulation: p.simulation, role: p.role, acceptanceStatus: p.acceptanceStatus })
      })
      setDisplaySimulation(tempDisplaySimulation)
    }
  }, [participationRequestsList])

  return (
    <>
      <Typography variant={'h4'}>{t('simulations.roles.title')}</Typography>
      {displaySimulation !== undefined && displaySimulation.length !== 0 ? (
        <GenericResponsiveListComponent<{
          simulation: APISimulationRequest
          role: APISimulationRole
          acceptanceStatus: APIIncomingAcceptanceStatus
        }>
          tList={displaySimulation}
          subComponentWidth={300}
          subComponent={(value) => {
            return <SimulationRoleCard key={value.simulation.id + '-' + value.role.id} request={value} />
          }}
        />
      ) : (
        <Typography>{t('simulations.roles.notFound')}</Typography>
      )}
    </>
  )
}

export default SimulationRoleList
