import GenericIconPopover from '../../../../../components/mui/pops/GenericIconPopover'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'
import { SimulationSocket } from '../../../../../contexts/simulation/SimulationSocket'
import { FrequencyType } from '../../FrequencyType'
import { ConnectionState } from 'livekit-client'

interface StatusPopoverProps {
  socket: SimulationSocket | undefined
  frequencies: FrequencyType[]
}

const StatusPopover = (props: StatusPopoverProps) => {
  const { socket, frequencies } = props
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <GenericIconPopover
      buttonContent={<SignalCellularAltIcon sx={{ fontSize: '2.5rem', color: 'grey' }} />}
      popoverContent={
        <Box
          sx={{
            m: 1,
            width: 325,
          }}
        >
          <Typography sx={{ m: 0.25, textAlign: 'center' }}>{t('simulations.running.status.service.title')}</Typography>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Chip
              sx={{ m: 0.25, width: 125, color: 'white' }}
              label={
                socket?.webSocket?.readyState == 0
                  ? t('simulations.running.status.service.connected')
                  : t('simulations.running.status.service.disconnected')
              }
              color={socket?.webSocket?.readyState == 0 ? 'success' : 'error'}
            />
          </Box>
          <Divider sx={{ mt: 0.5, mb: 0.5, ml: 'auto', mr: 'auto', width: '90%' }} color={theme.palette.primary.main} />
          <Typography sx={{ m: 0.25, textAlign: 'center' }}>
            {t('simulations.running.status.audioConnection.title')}
          </Typography>
          {frequencies.map((f) => {
            return (
              <Box key={f.frequency}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Chip sx={{ m: 0.25, width: 85 }} label={f.frequency.substring('radio_'.length).replace('_', '.')} />
                  <Chip
                    sx={{ m: 0.25, width: 150, color: 'white' }}
                    label={t('simulations.running.status.audioConnection.' + f.livekitRoom.state)}
                    color={
                      f.livekitRoom.state === ConnectionState.Connected
                        ? 'success'
                        : f.livekitRoom.state === ConnectionState.Connecting
                        ? 'warning'
                        : f.livekitRoom.state === ConnectionState.Reconnecting
                        ? 'warning'
                        : 'error'
                    }
                  />
                </Box>
                <Divider
                  color={theme.palette.primary.main}
                  sx={{ mt: 0.5, mb: 0.5, ml: 'auto', mr: 'auto', width: '85%' }}
                />
              </Box>
            )
          })}
        </Box>
      }
    />
  )
}

export default StatusPopover
