import React, { useEffect, useState } from 'react'
import './App.css'
import '@aws-amplify/ui-react/styles.css'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import SignUpPage from './pages/home/SignUpPage'
import HomePage from './pages/home/HomePage'
import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import getDesignTokens from './utils/theme/getDesignTokens'
import * as AmplifyUI from '@aws-amplify/ui-react'
import Profile from './pages/account/Profile'
import AccountPage from './pages/account/AccountPage'
import NotFound from './pages/errors/404'
import InternalServerError from './pages/errors/500'
import { useLocalStorage } from 'usehooks-ts'
import { Toaster } from 'react-hot-toast'
import AdminPage from './pages/admin/AdminPage'
import AccountList from './pages/admin/AccountList'
import AdminProtection from './components/admin/AdminProtection'
import Forbidden from './pages/errors/403'
import CustomAuthenticator from './components/user/CustomAuthenticator'
import { ConfirmProvider } from 'material-ui-confirm'
import UserProvider from './contexts/user/UserProvider'
import Simulation from './pages/simulation/running/Simulation'
import SimulationForm from './pages/simulation/form/SimulationForm'
import SimulationSocketProvider from './contexts/simulation/SimulationSocketProvider'
import SimulationHome from './pages/simulation/SimulationHome'
import { LocalizationProvider } from '@mui/x-date-pickers'
import 'dayjs/locale/fr'
import 'dayjs/locale/en-gb'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import i18n from 'i18next'
import { SimulationRequestRoleList } from './pages/simulation/list/roles/SimulationRequestRoleList'
import { PublishedSimulationList } from './pages/simulation/list/global-list/PublishedSimulationList'
import SimulationRoleList from './pages/simulation/roles/SimulationRoleList'
import SimulationManagementList from './pages/simulation/management/SimulationManagementList'
import SimulationManagementProvider from './contexts/management/SimulationManagementProvider'
import SimulationManagementCandidates from './pages/simulation/management/SimulationManagementCandidates'
import SimulationManagementApplicationsProvider from './contexts/management-application/SimulationManagementApplicationsProvider'
import SimulationInterfaceSettingsProvider from './contexts/simulation-interface-customization/SimulationInterfaceSettingsProvider'
import SimulationApplicationsHistory from './pages/simulation/history/SimulationApplicationsHistory'
import SimulationRequestsHistory from './pages/admin/SimulationRequestsHistory'

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
})

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <CustomAuthenticator>
            <HomePage />
          </CustomAuthenticator>
        }
      />
      <Route
        path="/profile"
        element={
          <CustomAuthenticator>
            <Profile />
          </CustomAuthenticator>
        }
      />
      <Route
        path="/profile/:username"
        element={
          <CustomAuthenticator>
            <Profile />
          </CustomAuthenticator>
        }
      />
      <Route
        path="/account"
        element={
          <CustomAuthenticator>
            <AccountPage />
          </CustomAuthenticator>
        }
      />
      <Route
        path="/simulations/:simulationId"
        element={
          <CustomAuthenticator noFooter>
            <Simulation />
          </CustomAuthenticator>
        }
      />
      <Route
        path="/simulations"
        element={
          <CustomAuthenticator>
            <SimulationHome />
          </CustomAuthenticator>
        }
      />
      <Route
        path="/simulations/list"
        element={
          <CustomAuthenticator>
            <PublishedSimulationList />
          </CustomAuthenticator>
        }
      />
      <Route
        path="/simulations/list/:simulationId"
        element={
          <CustomAuthenticator>
            <SimulationManagementApplicationsProvider>
              <SimulationRequestRoleList />
            </SimulationManagementApplicationsProvider>
          </CustomAuthenticator>
        }
      />
      <Route
        path="/simulations/create"
        element={
          <CustomAuthenticator>
            <AdminProtection>
              <SimulationForm />
            </AdminProtection>
          </CustomAuthenticator>
        }
      />
      <Route path="/simulations/manage/">
        <Route
          path=""
          element={
            <CustomAuthenticator>
              <AdminProtection>
                <SimulationManagementList />
              </AdminProtection>
            </CustomAuthenticator>
          }
        />
        <Route
          path=":simulationId"
          element={
            <CustomAuthenticator>
              <AdminProtection>
                <SimulationManagementApplicationsProvider>
                  <SimulationManagementCandidates />
                </SimulationManagementApplicationsProvider>
              </AdminProtection>
            </CustomAuthenticator>
          }
        />
      </Route>
      <Route
        path="/simulations/roles"
        element={
          <CustomAuthenticator>
            <SimulationRoleList />
          </CustomAuthenticator>
        }
      />
      <Route
        path="/simulations/history"
        element={
          <CustomAuthenticator>
            <SimulationApplicationsHistory />
          </CustomAuthenticator>
        }
      />
      <Route path="/admin">
        <Route
          path=""
          element={
            <CustomAuthenticator>
              <AdminProtection>
                <AdminPage />
              </AdminProtection>
            </CustomAuthenticator>
          }
        />
        <Route
          path="account-list/:userId"
          element={
            <CustomAuthenticator>
              <AdminProtection>
                <AccountPage />
              </AdminProtection>
            </CustomAuthenticator>
          }
        />
        <Route
          path="account-list"
          element={
            <CustomAuthenticator>
              <AdminProtection>
                <AccountList />
              </AdminProtection>
            </CustomAuthenticator>
          }
        />
        <Route
          path="simulation-history"
          element={
            <CustomAuthenticator>
              <AdminProtection>
                <SimulationRequestsHistory />
              </AdminProtection>
            </CustomAuthenticator>
          }
        />
      </Route>
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/403" element={<Forbidden />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="/500" element={<InternalServerError />} />
      <Route path="*" element={<NotFound />} />
    </>,
  ),
)

function AppBase() {
  const colorMode = React.useContext(ColorModeContext)

  return (
    <Box className="App">
      <UserProvider>
        <SimulationSocketProvider>
          <SimulationManagementProvider>
            <SimulationInterfaceSettingsProvider>
              <ColorModeContext.Provider value={colorMode}>
                <RouterProvider router={router} />
              </ColorModeContext.Provider>
            </SimulationInterfaceSettingsProvider>
          </SimulationManagementProvider>
        </SimulationSocketProvider>
      </UserProvider>
    </Box>
  )
}

export default function App() {
  const [locale, setLocale] = useState(i18n.language)

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setLocale(lng)
    })

    return function cleanup() {
      i18n.off('languageChanged')
    }
  }, [])

  const [mode, setMode] = useLocalStorage(
    'mode',
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  )
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      },
    }),
    [setMode],
  )
  const theme = React.useMemo(() => {
    if (mode === 'light' || mode === 'dark') {
      return createTheme(getDesignTokens(mode))
    } else {
      return createTheme(getDesignTokens('light'))
    }
  }, [mode])

  function darkModeOverride() {
    const darkMode = AmplifyUI.defaultDarkModeOverride.tokens
    if (darkMode && darkMode.colors && darkMode.colors.background) {
      if (darkMode.colors.background.primary) {
        darkMode.colors.background.primary.value = theme.palette.background.paper
      }
    }
    return darkMode
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <AmplifyUI.ThemeProvider
          theme={{
            name: 'custom',
            overrides: [
              {
                colorMode: 'dark',
                tokens: {
                  ...darkModeOverride(),
                },
              },
            ],
            tokens: {
              components: {
                button: {
                  primary: {
                    backgroundColor: {
                      value: theme.palette.primary.main,
                    },
                  },
                },
              },
            },
          }}
          colorMode={mode === 'dark' ? 'dark' : 'light'}
        >
          <ConfirmProvider
            defaultOptions={{
              confirmationButtonProps: {
                autoFocus: true,
                sx: {
                  color: '#fff',
                  backgroundColor: theme.palette.success.main,
                  ':hover': { backgroundColor: theme.palette.success.dark },
                  m: 1,
                },
              },
              cancellationButtonProps: {
                sx: {
                  color: '#fff',
                  backgroundColor: theme.palette.error.main,
                  ':hover': { backgroundColor: theme.palette.error.dark },
                  m: 1,
                },
              },
              titleProps: { sx: { backgroundColor: theme.palette.secondary.light } },
              contentProps: { sx: { backgroundColor: theme.palette.secondary.light } },
              dialogActionsProps: { sx: { backgroundColor: theme.palette.secondary.light } },
              dialogProps: { fullWidth: true, maxWidth: 'xs' },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale === 'en' ? 'en-gb' : locale}>
              <Toaster />
              <CssBaseline />
              <AppBase />
            </LocalizationProvider>
          </ConfirmProvider>
        </AmplifyUI.ThemeProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}
