export default {
  title: 'Your account',
  profileInformation: {
    title: 'Profile',
    username: 'Username',
    nickname: 'Nickname',
    creationDate: 'Creation date',
  },
  personalInformation: {
    title: 'Personal information',
    firstName: 'First name',
    lastName: 'Last name',
    age: 'Age',
    gender: 'Gender',
  },
  contactInformation: {
    title: 'Contact information',
    email: 'Email',
    phoneNumber: 'Phone number',
  },
  systemInformation: {
    title: 'System information',
    accountRoles: 'Account roles',
    lastConnection: 'Last connection',
  },
  otherInformation: {
    title: 'Other information',
    languages: 'Languages',
    accents: 'Accents',
  },
  formDialog: {
    edit: 'Edit',
    fields: {
      username: 'username',
      email: 'email',
      firstname: 'first name',
      lastname: 'last name',
      nickname: 'nickname',
      age: 'age',
      phoneNumber: 'phone number',
      gender: 'gender',
      languages: 'languages',
      accents: 'accents',
    },
  },
  archive: 'Archive my account',
  changePassword: 'Change my password',
  enable: 'Enable this account',
  confirm: {
    archive: {
      title: 'Are you sure you want to archive your account?',
      content: 'This action cannot be undone without help from an administrator.',
      keywordTextInstructions: "Please type '{{keyword}}' to confirm the archiving of your account:",
    },
    enable: {
      title: 'Are you sure you want to enable this account?',
      content: 'The user will be able to connect with it again.',
      success: 'Successfully enabled {{username}}',
      error: 'Failed to enable user {{username}}',
    },
  },
}
