import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import SimulationSocketContext from '../../../../../contexts/simulation/SimulationSocketContext'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback'
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled'
import * as React from 'react'

export const AcceptOrRejectCallButtons = (props: {
  handleAcceptCall: () => void
  handleRejectCall: () => void
  size: 'small' | 'medium' | 'large'
}) => {
  const { t } = useTranslation()
  const { handleAcceptCall, handleRejectCall, size } = props

  return (
    <>
      <Tooltip title={t('tooltips.simulations.running.privateCall.pickUp')} placement={'top'}>
        <IconButton sx={{ mr: size === 'small' ? 0.5 : 2 }} onClick={handleAcceptCall} size={size}>
          <PhoneCallbackIcon
            sx={{
              fontSize: size === 'small' ? '1.5rem' : size === 'medium' ? '2rem' : '2.5rem',
              color: 'yellowgreen',
            }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('tooltips.simulations.running.privateCall.hangup')} placement={'top'}>
        <IconButton sx={{ ml: size === 'small' ? 0.5 : 2 }} onClick={handleRejectCall} size={size}>
          <PhoneDisabledIcon
            sx={{
              fontSize: size === 'small' ? '1.5rem' : size === 'medium' ? '2rem' : '2.5rem',
              color: 'red',
            }}
          />
        </IconButton>
      </Tooltip>
    </>
  )
}

const CallActions = (props: { handleAcceptCall: () => void; handleRejectCall: () => void }) => {
  const { t } = useTranslation()
  const { handleAcceptCall, handleRejectCall } = props
  const privateCall = useContext(SimulationSocketContext)?.privateCall

  if (!privateCall) {
    return null
  }

  return (
    <Box sx={{ textAlign: 'center', width: '100%' }}>
      <Typography sx={{ mb: 2 }}>
        {t('simulations.running.privateCall.receivingCall', { caller: privateCall.owner?.nickname })}
      </Typography>
      <AcceptOrRejectCallButtons
        handleAcceptCall={handleAcceptCall}
        handleRejectCall={handleRejectCall}
        size={'medium'}
      />
    </Box>
  )
}

export default CallActions
