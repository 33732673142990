import * as React from 'react'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { Card, Checkbox, FormControl, IconButton, Stack, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useState } from 'react'
import { APIScenario } from '../../../rest/simulation/models/APIScenario'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useConfirm } from 'material-ui-confirm'
import { deleteScenario } from '../../../rest/simulation/queries/ScenarioQueries'

export interface ConfirmationDialogRawProps {
  id: string
  keepMounted: boolean
  value: string
  open: boolean
  onClose: (value?: string) => void
  savedScenarios: APIScenario[] | undefined
  setSavedScenarios: React.Dispatch<React.SetStateAction<APIScenario[] | undefined>>
}

function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
  const { onClose, value: valueProp, open, savedScenarios, setSavedScenarios, ...other } = props
  const [value, setValue] = React.useState(valueProp)
  const radioGroupRef = React.useRef<HTMLElement>(null)
  const [selectedScenarios, setSelectedScenarios] = useState<APIScenario[]>([])
  const { t } = useTranslation()
  const confirm = useConfirm()

  const handleScenarioSelection = (event: React.ChangeEvent<HTMLInputElement>, scenario: APIScenario) => {
    const scenarioIndex = selectedScenarios.indexOf(scenario)

    if (scenarioIndex === -1) {
      // Scenario is not currently selected, add it to the list
      setSelectedScenarios([...selectedScenarios, scenario])
    } else {
      // Scenario is currently selected, remove it from the list
      const updatedSelectedScenarios = [...selectedScenarios]
      updatedSelectedScenarios.splice(scenarioIndex, 1)
      setSelectedScenarios(updatedSelectedScenarios)
    }
  }

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp)
    }
  }, [valueProp, open])

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }

  const handleOk = () => {
    onClose(value)
  }

  function handleDelete() {
    if (selectedScenarios.length === 0) {
      return
    }
    confirm({
      title: t('simulations.create.settings.edit.confirm.title'),
      description: t('simulations.create.settings.edit.confirm.description'),
      confirmationText: t('simulations.create.settings.edit.confirm.confirm'),
      cancellationText: t('simulations.create.settings.edit.confirm.cancel'),
    })
      .then(() => {
        selectedScenarios.forEach((scenario: APIScenario) => {
          if (scenario.id) {
            deleteScenario(scenario.id)
              .then(() => {
                setSavedScenarios(
                  savedScenarios?.filter((scenarioF: APIScenario) => !selectedScenarios.includes(scenarioF)),
                )
                setSelectedScenarios([])

                toast.success(t('simulations.create.settings.edit.success'))
              })
              .catch(() => {
                toast.error(t('simulations.create.settings.edit.error'))
              })
          }
        })
      })
      .catch(() => {})
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '1.5em' }}>{t('simulations.create.settings.edit.title')}</Typography>
        <IconButton disabled={selectedScenarios?.length === 0} onClick={handleDelete}>
          <DeleteIcon color={'error'} aria-disabled={selectedScenarios?.length === 0} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Stack>
          <FormControl>
            {savedScenarios &&
              savedScenarios.map((scenario: APIScenario, key: number) => {
                return (
                  <Card
                    key={key}
                    variant={'elevation'}
                    sx={{ display: 'flex', justifyContent: 'space-between', pl: 2, mt: 1, mb: 1 }}
                  >
                    <Typography sx={{ p: 1 }}>{scenario.simulation.name}</Typography>
                    <Checkbox size={'medium'} onChange={(event) => handleScenarioSelection(event, scenario)} />
                  </Card>
                )
              })}
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>{t('simulations.create.settings.edit.close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

interface SimulationFormEditScenariosProps {
  savedScenarios: APIScenario[] | undefined
  setSavedScenarios: React.Dispatch<React.SetStateAction<APIScenario[] | undefined>>
  disabled?: boolean
}

export default function SimulationFormEditScenarios(props: SimulationFormEditScenariosProps) {
  const { savedScenarios, setSavedScenarios, disabled } = props
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState('Dione')

  const handleClickListItem = () => {
    setOpen(true)
  }

  const handleClose = (newValue?: string) => {
    setOpen(false)

    if (newValue) {
      setValue(newValue)
    }
  }

  return (
    <>
      <IconButton onClick={handleClickListItem} disabled={disabled}>
        <EditIcon sx={{ fontSize: '1.2em' }} />
      </IconButton>
      <ConfirmationDialogRaw
        id="ringtone-menu"
        keepMounted
        open={open}
        onClose={handleClose}
        value={value}
        savedScenarios={savedScenarios}
        setSavedScenarios={setSavedScenarios}
      />
    </>
  )
}
