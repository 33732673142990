export default {
  loginPortal: {
    goToSignUp: "Don't have an account? Sign up",
  },
  signUpPage: {
    title: 'Account information',
    errors: {
      required: 'This field is required',
      passwordLength: 'Password must be at least 8 characters',
      passwordMatch: 'Passwords do not match',
      age: 'Age must be between 18 and 99',
      alreadyExists: 'Username already exists',
    },
    username: 'Username',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    firstName: 'First name',
    lastName: 'Last name',
    nickname: 'Nickname',
    age: 'Age',
    phoneNumber: 'Phone number',
    gender: 'Gender',
    languages: 'Languages',
    accents: 'Accents',
    goToSignIn: 'Already have an account? Sign in',
    signUp: 'Sign up',
  },
  home: {
    welcome: 'Welcome to Airgate',
    pageDescriptions: {
      profile: 'In this page you can see your public profile.',
      account: 'In this page you can manage your account.',
      simulations: 'In this page you can see and manage your simulations.',
    },
  },
}
