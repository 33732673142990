import { Box, IconButton, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { DataGrid, GridColumns, GridRowsProp, GridToolbar } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { useConfirm } from 'material-ui-confirm'
import { APISimulationRequest } from '../../rest/simulation/models/APISimulationRequest'
import { forceStartSimulation } from '../../rest/simulation/queries/SimulationConfQueries'
import { getAllSimulationRequests } from '../../rest/simulation/queries/SimulationRequestQueries'

const SimulationRequestsHistory = () => {
  const [simulationRequests, setSimulationRequests] = React.useState<APISimulationRequest[] | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean | undefined>(true)
  const [rows, setRows] = React.useState<GridRowsProp>([])
  const confirm = useConfirm()

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'simulationName', headerName: 'Simulation name', width: 150, hideable: false },
    { field: 'clientUsername', headerName: 'Client username', width: 150 },
    { field: 'requestDate', headerName: 'Request date', width: 175 },
    { field: 'startDate', headerName: 'Start date', width: 175 },
    { field: 'endDate', headerName: 'End date', width: 175 },
    { field: 'status', headerName: 'Status', width: 125 },
    {
      field: 'details',
      type: 'actions',
      headerName: 'Details',
      width: 75,
      cellClassName: 'actions',
      hideable: false,
      getActions: () => {
        return [
          <>
            <IconButton>
              <VisibilityIcon color={'primary'} />
            </IconButton>
          </>,
        ]
      },
    },
    {
      field: 'run',
      type: 'actions',
      headerName: 'Force run',
      width: 100,
      cellClassName: 'actions',
      hideable: false,
      getActions: ({ id }) => {
        const request = simulationRequests?.find((r) => r.id === id)
        const runnable = !(request === undefined || request.status === 'PUBLISHED' || request.status === 'STARTED')

        const runSimulation = () => {
          if (request === undefined || request.id === null) return

          confirm({
            title: 'Are you sure you want to force start simulation ' + request.simulation.name + '?',
            description: (
              <>
                <Typography variant={'body1'}>
                  This will start a simulation as if it was started normally, and can impact the simulation's data.
                </Typography>
                <Typography variant={'body1'} sx={{ fontWeight: 'bold', mb: 1 }} color={'red'}>
                  Please type 'START {request.simulation.name}' to confirm.
                </Typography>
              </>
            ),
            confirmationText: 'Force start',
            cancellationText: 'Cancel',
            confirmationKeyword: 'START ' + request.simulation.name,
          })
            .then(() => {
              if (request.id === null) return
              const tempRequests = simulationRequests?.map((req) => {
                if (req.id === request.id) {
                  req.status = 'STARTED'
                }
                return req
              })
              setSimulationRequests(tempRequests)
              forceStartSimulation(request.id).then().catch()
            })
            .catch()
        }

        return [
          <>
            <IconButton disabled={!runnable} onClick={runSimulation}>
              <PlayArrowIcon color={runnable ? 'success' : 'inherit'} />
            </IconButton>
          </>,
        ]
      },
    },
  ]

  useEffect(() => {
    const tempRows = simulationRequests?.map((request) => {
      return {
        id: request.id,
        simulationName: request.simulation.name,
        clientUsername: request.clientUsername,
        requestDate: request.requestDate
          ? new Date(request.requestDate).toLocaleDateString() +
            ' ' +
            new Date(request.requestDate).toLocaleTimeString()
          : '',
        startDate:
          new Date(request.startDate).toLocaleDateString() + ' ' + new Date(request.startDate).toLocaleTimeString(),
        endDate: new Date(request.endDate).toLocaleDateString() + ' ' + new Date(request.endDate).toLocaleTimeString(),
        status: request.status,
        details: request.simulation,
      }
    })
    setRows(tempRows || [])
  }, [simulationRequests])

  useEffect(() => {
    console.log(rows)
  }, [rows])

  if (simulationRequests === undefined) {
    getAllSimulationRequests()
      .then((requests) => {
        setSimulationRequests(requests)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(undefined)
      })
  }

  return (
    <>
      <Typography variant={'h2'} sx={{ mb: 2 }}>
        Simulation requests history
      </Typography>
      <Box display="flex" justifyContent="center">
        <Box width="80%" height="450px">
          <DataGrid
            loading={loading}
            rowsPerPageOptions={[25, 50, 100]}
            pagination
            rows={rows}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            sx={{
              borderRadius: 1,
              boxShadow: 5,
              borderColor: 'primary.main',
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                  simulationName: true,
                  clientUsername: true,
                  requestDate: true,
                  startDate: true,
                  endDate: false,
                  status: true,
                  details: true,
                },
              },
            }}
          />
        </Box>
      </Box>
    </>
  )
}

export default SimulationRequestsHistory
