import React, { useEffect, useState } from 'react'

export function useHoldPress(callback = () => {}, ms = 300) {
  const [startHoldPress, setStartHoldPress] = useState(false)

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout> | undefined
    if (startHoldPress) {
      timerId = setInterval(callback, ms)
    } else if (timerId) {
      clearTimeout(timerId)
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [callback, ms, startHoldPress])

  const startPress = () => setStartHoldPress(true)
  const stopPress = () => setStartHoldPress(false)

  return {
    onMouseDown: startPress,
    onMouseUp: stopPress,
    onMouseLeave: stopPress,
    onTouchStart: startPress,
    onTouchEnd: stopPress,
  }
}
