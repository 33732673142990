import { Box, Breadcrumbs, Button, LinkProps, Typography } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useTranslation } from 'react-i18next'

const breadcrumbNameMap: { [key: string]: string } = {
  '/': 'home',
  '/account': 'account',
  '/profile': 'profile',
  '/admin': 'admin',
  '/admin/account-list': 'adminAccountList',
  '/admin/simulation-history': 'adminSimulationHistory',
  '/simulations/simulation': 'simulation',
  '/simulations': 'simulations',
  '/simulations/create': 'simulationsCreate',
  '/simulations/list': 'simulationsList',
  '/simulations/roles': 'simulationsRoles',
  '/simulations/history': 'simulationsHistory',
  '/simulations/manage': 'simulationsManage',
  '/403': '403',
  '/404': '404',
  '/500': '500',
}

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

function LinkRouter(props: LinkRouterProps) {
  return (
    <RouterLink
      to={props.to}
      replace={props.replace}
      style={{
        textDecoration: 'none',
      }}
    >
      <Button color={'primary'}>
        <Typography variant={'h6'}>{props.children}</Typography>
      </Button>
    </RouterLink>
  )
}

function CustomBreadcrumbs() {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)
  const { t } = useTranslation()

  function getBreadcrumbName(key: string): string {
    if (breadcrumbNameMap[key] === undefined) {
      if (key.startsWith('/admin/account-list/')) {
        return t('breadcrumbs.adminEditAccount', { accountId: key.substring('/admin/account-list/'.length) })
      } else if (key.startsWith('/simulations/list/')) {
        return t('breadcrumbs.simulationsViewRoles', { simulationId: key.substring('/simulations/list/'.length) })
      } else if (key.startsWith('/simulations/manage/')) {
        return t('breadcrumbs.simulationsManageRoles', { simulationId: key.substring('/simulations/manage/'.length) })
      } else if (key.startsWith('/profile/')) {
        return t('breadcrumbs.userProfile', { username: key.substring('/profile/'.length) })
      } else if (key.startsWith('/simulations/')) {
        return t('breadcrumbs.runningSimulation')
      }
    }
    return t(`breadcrumbs.${breadcrumbNameMap[key]}`)
  }

  return (
    <Box
      sx={{
        width: '100%',
        m: '0 auto',
        justifyContent: 'center',
        display: 'flex',
        mb: 2,
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="medium" />}
        sx={{
          '& ol': {
            justifyContent: 'center',
            margin: 'auto',
          },
        }}
      >
        <LinkRouter variant={'h6'} underline="hover" color="inherit" to="/">
          {t('breadcrumbs.home')}
        </LinkRouter>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1
          const to = `/${pathnames.slice(0, index + 1).join('/')}`

          return last ? (
            <Box key={to}>
              <Button>
                <Typography variant={'h6'} color="text.primary">
                  {getBreadcrumbName(to)}
                </Typography>
              </Button>
            </Box>
          ) : (
            <Box key={to}>
              <LinkRouter variant={'h6'} underline="hover" color="inherit" to={to}>
                {getBreadcrumbName(to)}
              </LinkRouter>
            </Box>
          )
        })}
      </Breadcrumbs>
    </Box>
  )
}

export default CustomBreadcrumbs
