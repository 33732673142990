import { Typography } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import { SimulationRequestComponent } from '../list/global-list/SimulationRequestComponent'
import { GenericResponsiveListComponent } from '../../../components/mui/GenericResponsiveListComponent'
import { useSimulationManagementContext } from '../../../hooks/ContextHooks'
import { APISimulationRequest } from '../../../rest/simulation/models/APISimulationRequest'

function SimulationManagementList() {
  const { simulationRequests, callSimulationRequests } = useSimulationManagementContext() //The list of simulation requests
  const [called, setCalled] = React.useState<boolean>(false)

  if (!called) {
    callSimulationRequests()
    setCalled(true)
  }

  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom>
        {t('simulations.manage.title')}
      </Typography>
      {(simulationRequests === undefined || simulationRequests.length === 0) && (
        <Typography>{t('simulations.manage.notFound')}</Typography>
      )}
      {simulationRequests !== undefined && (
        <GenericResponsiveListComponent<APISimulationRequest>
          tList={simulationRequests}
          subComponentWidth={320}
          subComponent={(value) => {
            return <SimulationRequestComponent key={value.id} simulation={value} manageApplications={true} />
          }}
        />
      )}
    </div>
  )
}

export default SimulationManagementList
