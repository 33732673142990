import { createContext } from 'react'
import { SimulationSocket, SimulationStatus } from './SimulationSocket'
import { Room } from './Room'
import { PrivateCall } from './PrivateCall'

export type SimulationSocketContextType = {
  frequencies: Room[]
  socket: SimulationSocket | undefined
  freqStatus: { [room: string]: SimulationStatus }
  privateCall: PrivateCall
  incomingPrivateCalls: PrivateCall[]
  pausedPrivateCalls: PrivateCall[]
  pausePrivateCall: () => void
  resumePrivateCall: (privateCall: PrivateCall) => void
  isError: boolean
  mediaDevices: {
    inputDevice: MediaDeviceInfo | undefined
    outputDevice: MediaDeviceInfo | undefined
    setInputDevice: (device?: MediaDeviceInfo) => void
    setOutputDevice: (device?: MediaDeviceInfo) => void
  }
}

const SimulationSocketContext = createContext<SimulationSocketContextType | undefined>(undefined)

export default SimulationSocketContext
