import React, { ChangeEvent } from 'react'
import {
  Box,
  Card,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import MultipleSelectChip from '../../../../components/mui/MultipleSelectChip'
import MediaQuery from 'react-responsive'
import { useConfirm } from 'material-ui-confirm'
import Language from '../../../../rest/user/enums/Language'
import Accent, { getAccentsByLanguages } from '../../../../rest/user/enums/Accent'
import { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import { APISimulationConf } from '../../../../rest/simulation/models/APISimulationConf'

interface CardSimulationFormRoleProps {
  simulation: APISimulationConf
  setSimulation: React.Dispatch<React.SetStateAction<APISimulationConf>>
  id: number
  frequencies: string[]
}

function CardSimulationFormRole(props: CardSimulationFormRoleProps) {
  const { t } = useTranslation()
  const { simulation, setSimulation, id, frequencies } = props
  const roleTypeKeys = ['PSEUDO_PILOT', 'AIR_CONTROLLER', 'OTHER']
  const genders = ['MALE', 'FEMALE', 'OTHER']
  const confirm = useConfirm()

  function handleSetFrequencies(selectedFrequencyList: string[]) {
    const simulationCopy = { ...simulation }
    simulationCopy.roles[id].frequencies = selectedFrequencyList
    setSimulation(simulationCopy)
  }

  function handleSetRoleType(event: SelectChangeEvent) {
    const simulationCopy = { ...simulation }
    simulationCopy.roles[id].roleType = event.target.value
    setSimulation(simulationCopy)
  }

  function handleInputRoleName(event: React.ChangeEvent<HTMLInputElement>) {
    const simulationCopy = { ...simulation }
    simulationCopy.roles[id].name = event.target.value
    setSimulation(simulationCopy)
  }

  function handleDeleteRole() {
    if (simulation.roles[id].name.trim() === '' && simulation.roles[id].frequencies.length === 0) {
      const simulationCopy = { ...simulation }
      simulationCopy.roles.splice(id, 1)
      setSimulation(simulationCopy)
      return
    } else {
      confirm({
        title: t('simulations.create.roles.delete'),
        description: t('simulations.create.roles.deleteMessage'),
        confirmationText: t('simulations.create.roles.confirm'),
        cancellationText: t('simulations.create.roles.cancel'),
      })
        .then(() => {
          const simulationCopy = { ...simulation }
          simulationCopy.roles.splice(id, 1)
          setSimulation(simulationCopy)
        })
        .catch()
    }
  }

  function handleSetLanguages(selectedLanguages: string[]) {
    const simulationCopy = { ...simulation }
    simulationCopy.roles[id].languages = selectedLanguages as unknown as Language[]
    simulationCopy.roles[id].accents = simulationCopy.roles[id].accents.filter((accent: Accent) =>
      getAccentsByLanguages(selectedLanguages as unknown as Language[]).includes(accent),
    )
    setSimulation(simulationCopy)
  }

  function handleSetAccents(selectedAccents: string[]) {
    const simulationCopy = { ...simulation }
    simulationCopy.roles[id].accents = selectedAccents as unknown as Accent[]
    setSimulation(simulationCopy)
  }

  function handleSetGender(event: SelectChangeEvent) {
    const simulationCopy = { ...simulation }
    simulationCopy.roles[id].gender = event.target.value
    setSimulation(simulationCopy)
  }

  function handleSetAgeMin(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const regex = /^[0-9\b]+$/
    if (parseInt(event.target.value) < 80 && (regex.test(event.target.value) || event.target.value === '')) {
      const simulationCopy = { ...simulation }
      simulationCopy.roles[id].minAge = parseInt(event.target.value)
      setSimulation(simulationCopy)
    }
  }

  function handleSetAgeMax(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const regex = /^[0-9\b]+$/
    if (parseInt(event.target.value) <= 80 && (regex.test(event.target.value) || event.target.value === '')) {
      const simulationCopy = { ...simulation }
      simulationCopy.roles[id].maxAge = parseInt(event.target.value)
      setSimulation(simulationCopy)
    }
  }

  return (
    <Card sx={{ mb: 2, p: 2, borderRadius: 2, boxShadow: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          value={simulation.roles[id].name}
          onChange={handleInputRoleName}
          label={t('simulations.create.roles.name')}
          error={simulation.roles[id].name.trim() === ''}
          sx={{ width: '100%' }}
        />
        <MediaQuery minWidth={520}>
          <FormControl sx={{ width: 300, height: 56, ml: 2 }}>
            <InputLabel id="demo-simple-select-label">{t('simulations.create.roles.roleType')}</InputLabel>
            <Select
              value={simulation.roles[id].roleType}
              label={t('simulations.create.roles.roleType')}
              onChange={handleSetRoleType}
              renderValue={(selected: string) => <Chip label={t('roleTypes.' + selected)} />}
              color="primary"
              input={<OutlinedInput label={'Role type'} required />}
              sx={{
                height: 56,
              }}
            >
              {roleTypeKeys.map((key) => (
                <MenuItem key={key} value={key}>
                  {t('roleTypes.' + key)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MediaQuery>

        <IconButton disabled={simulation.roles.length === 1} sx={{ color: 'red', ml: 1 }} onClick={handleDeleteRole}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <MediaQuery maxWidth={519}>
        <FormControl sx={{ width: '100%', height: 56, mt: 2 }}>
          <InputLabel>{t('simulations.create.roles.name')}</InputLabel>
          <Select
            value={simulation.roles[id].roleType}
            label={t('simulations.create.roles.roleType')}
            onChange={handleSetRoleType}
            renderValue={(selected: string) => <Chip label={t('roleTypes.' + selected)} />}
            color="primary"
            input={<OutlinedInput label={t('simulations.create.roles.roleType')} required />}
            sx={{
              contentAlign: 'right',
              height: 56,
            }}
          >
            {roleTypeKeys.map((key) => (
              <MenuItem key={key} value={key}>
                {t('roleTypes.' + key)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MediaQuery>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControl sx={{ minWidth: '8em', mt: 2, mr: 2 }}>
          <InputLabel>{t('simulations.create.roles.gender')}</InputLabel>
          <Select
            value={simulation.roles[id].gender}
            label={t('simulations.create.roles.gender')}
            renderValue={(selected) => <Chip label={t('gender.' + selected)} />}
            onChange={handleSetGender}
          >
            {genders.map((key) => (
              <MenuItem key={key} value={key}>
                {t('gender.' + key)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <MultipleSelectChip
          list={frequencies}
          label={t('simulations.create.roles.frequencies')}
          items={simulation.roles[id].frequencies}
          setItems={handleSetFrequencies}
          material
          error={simulation.roles[id].frequencies.length === 0}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <TextField
          fullWidth
          label={t('simulations.create.roles.ageMin')}
          value={simulation.roles[id].minAge}
          onChange={handleSetAgeMin}
          helperText={t('simulations.create.roles.ageMinHelper')}
          sx={{ mr: 2 }}
          error={
            simulation.roles[id].minAge > simulation.roles[id].maxAge ||
            simulation.roles[id].minAge === null ||
            simulation.roles[id].minAge < 18
          }
        />
        <TextField
          fullWidth
          label={t('simulations.create.roles.ageMax')}
          value={simulation.roles[id].maxAge}
          onChange={handleSetAgeMax}
          helperText={t('simulations.create.roles.ageMaxHelper')}
          error={simulation.roles[id].minAge > simulation.roles[id].maxAge && simulation.roles[id].maxAge !== null}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <MultipleSelectChip
          material
          list={Object.values(Language)}
          formattedList={(lang) => t(`languages.${lang}`)}
          label={t('simulations.create.roles.languages')}
          items={simulation.roles[id].languages}
          formattedItem={(lang) => t(`languages.${lang}`)}
          setItems={handleSetLanguages}
          error={simulation.roles[id].languages.length === 0}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <MultipleSelectChip
          material
          list={getAccentsByLanguages(simulation.roles[id].languages)}
          formattedList={(acc) => t(`accents.${acc}`)}
          label={t('simulations.create.roles.accents')}
          items={simulation.roles[id].accents}
          formattedItem={(acc) => t(`accents.${acc}`)}
          setItems={handleSetAccents}
          error={simulation.roles[id].accents.length === 0}
        />
      </Box>
    </Card>
  )
}

export default CardSimulationFormRole
