import Language from './Language'

enum Accent {
  ENGLISH_UK = 'ENGLISH_UK',
  ENGLISH_US = 'ENGLISH_US',
  ENGLISH_AU = 'ENGLISH_AU',
  ENGLISH_IN = 'ENGLISH_IN',
  ENGLISH_CA = 'ENGLISH_CA',
  FRENCH_FR = 'FRENCH_FR',
  FRENCH_CA = 'FRENCH_CA',
  SPANISH_ES = 'SPANISH_ES',
  PORTUGUESE_PT = 'PORTUGUESE_PT',
  PORTUGUESE_BR = 'PORTUGUESE_BR',
  GERMAN_DE = 'GERMAN_DE',
  CHINESE_CN = 'CHINESE_CN',
  JAPANESE_JP = 'JAPANESE_JP',
  HINDI_IN = 'HINDI_IN',
  ARABIC_SA = 'ARABIC_SA',
  RUSSIAN_RU = 'RUSSIAN_RU',
  HOLLAND_NL = 'HOLLAND_NL',
  ITALIAN_IT = 'ITALIAN_IT',
  OTHER = 'OTHER',
}

type LanguageAccentsMap = {
  [key in Language]: Accent[]
}

const languageAccents: LanguageAccentsMap = {
  [Language.ENGLISH]: [Accent.ENGLISH_UK, Accent.ENGLISH_US, Accent.ENGLISH_AU, Accent.ENGLISH_IN, Accent.ENGLISH_CA],
  [Language.FRENCH]: [Accent.FRENCH_FR, Accent.FRENCH_CA],
  [Language.SPANISH]: [Accent.SPANISH_ES],
  [Language.PORTUGUESE]: [Accent.PORTUGUESE_PT, Accent.PORTUGUESE_BR],
  [Language.GERMAN]: [Accent.GERMAN_DE],
  [Language.CHINESE]: [Accent.CHINESE_CN],
  [Language.JAPANESE]: [Accent.JAPANESE_JP],
  [Language.HINDI]: [Accent.HINDI_IN],
  [Language.ARABIC]: [Accent.ARABIC_SA],
  [Language.RUSSIAN]: [Accent.RUSSIAN_RU],
  [Language.HOLLAND]: [Accent.HOLLAND_NL],
  [Language.ITALIAN]: [Accent.ITALIAN_IT],
  [Language.OTHER]: [Accent.OTHER],
}

export function getLanguageFromAccent(accent: Accent): Language | undefined {
  for (const language in languageAccents) {
    if (languageAccents[language as unknown as Language].includes(accent)) {
      return language as unknown as Language
    }
  }
  return undefined // Accent not found in any language
}

export function getAccentsByLanguage(language: Language): Accent[] {
  return languageAccents[language]
}

export function getAccentsByLanguages(languages: Language[]): Accent[] {
  return languages.flatMap((lang) => getAccentsByLanguage(lang))
}

export default Accent
