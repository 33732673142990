import { ParticipantLoop, useParticipantContext, useRemoteParticipants } from '@livekit/components-react'
import { Box, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Participant } from 'livekit-client'
import SpatialAudioRenderer from '../../../../../utils/audio/SpatialAudioRenderer'
import GenericIconPopover from '../../../../../components/mui/pops/GenericIconPopover'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import UserAutocomplete from './UserAutocomplete'
import PhoneIcon from '@mui/icons-material/Phone'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled'
import * as React from 'react'
import PhonePausedIcon from '@mui/icons-material/PhonePaused'
import { useSimulationSocketContext, useUserContext } from '../../../../../hooks/ContextHooks'
import MicOffIcon from '@mui/icons-material/MicOff'

const CustomParticipantComponent = () => {
  const participant = useParticipantContext()

  return (
    <>
      <Chip
        key={participant?.sid}
        label={
          <Box sx={{ width: '100%' }}>
            <Typography variant={'body2'}>
              {participant?.name}
              {!participant?.isMicrophoneEnabled && <MicOffIcon sx={{ fontSize: '1rem', ml: 'auto', mr: 0 }} />}{' '}
            </Typography>
          </Box>
        }
        color={participant?.isSpeaking ? 'success' : !participant?.isMicrophoneEnabled ? 'error' : 'default'}
        sx={{
          color: participant?.isSpeaking ? 'white' : participant?.isMicrophoneEnabled ? 'text.primary' : 'white',
          width: 110,
          m: 1,
        }}
      />
    </>
  )
}

const CustomRoomComponent = (props: { userList: { label: string; value: string }[] }) => {
  const { t } = useTranslation()
  const { userList } = props
  const [selectedUser, setSelectedUser] = useState<{ label: string; value: string }>({ label: '', value: '' })
  const { privateCall, socket, pausePrivateCall } = useSimulationSocketContext()
  const webSocket = socket?.webSocket
  const { user } = useUserContext()
  const remoteParticipants = useRemoteParticipants()
  const localParticipant = privateCall.livekitRoom.localParticipant
  const participants = [...remoteParticipants, localParticipant].sort((a, b) =>
    a.identity.localeCompare(b.identity),
  ) as Participant[]

  if (!privateCall || !webSocket || !user) {
    return null
  }

  const handleDisconnectCall = () => {
    webSocket.sendJsonMessage({
      user: {
        username: user.username,
        nickname: user.nickname,
      },
      action: 'DISCONNECT_CALL',
      target: null,
      room: privateCall.room,
    })
  }

  const handleSendCall = () => {
    webSocket.sendJsonMessage({
      user: {
        username: user.username,
        nickname: user.nickname,
      },
      action: 'SEND_CALL',
      target: {
        username: selectedUser.value,
        nickname: selectedUser.label,
      },
      room: privateCall.room,
    })
  }

  const handlePauseCall = () => {
    pausePrivateCall()
  }

  return (
    <>
      {participants && participants.length > 1 ? (
        <ParticipantLoop participants={participants}>
          <CustomParticipantComponent />
        </ParticipantLoop>
      ) : (
        <CircularProgress />
      )}
      <br />
      <SpatialAudioRenderer position={'RIGHT'} />
      <GenericIconPopover
        buttonContent={
          <Tooltip title={t('tooltips.simulations.running.privateCall.add')} placement={'top'}>
            <AddCircleOutlineOutlinedIcon sx={{ fontSize: '2rem', color: 'yellowgreen' }} />
          </Tooltip>
        }
        popoverContent={
          <Box
            sx={{
              m: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 300,
            }}
          >
            <UserAutocomplete
              userList={userList.filter((u) => u.value !== user.id + '')}
              sx={{ width: '90%', mt: 2 }}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </Box>
        }
        CloseButton={(p) => {
          return (
            <Box
              sx={{
                m: 1,
                width: 300,
                textAlign: 'center',
              }}
            >
              <Tooltip title={t('tooltips.simulations.running.privateCall.add')} placement={'top'}>
                <IconButton
                  sx={{ width: 'fit-content' }}
                  onClick={() => {
                    handleSendCall()
                    p.onClose()
                  }}
                >
                  <PhoneIcon sx={{ fontSize: '2rem', color: 'primary.main' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('tooltips.simulations.running.privateCall.cancel')} placement={'top'}>
                <IconButton sx={{ width: 'fit-content' }} onClick={() => p.onClose()}>
                  <CancelOutlinedIcon sx={{ fontSize: '2rem', color: 'red' }} />
                </IconButton>
              </Tooltip>
            </Box>
          )
        }}
      />
      <Tooltip title={t('tooltips.simulations.running.privateCall.hold')} placement={'top'}>
        <IconButton onClick={handlePauseCall}>
          <PhonePausedIcon
            sx={{
              fontSize: '2rem',
              color: 'primary.main',
            }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('tooltips.simulations.running.privateCall.hangup')} placement={'top'}>
        <IconButton onClick={handleDisconnectCall}>
          <PhoneDisabledIcon sx={{ fontSize: '2rem', color: 'red' }} />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default CustomRoomComponent
