import React from 'react'
import { AItem, AListElement } from './AItem'
import { Box, List, Typography } from '@mui/material'
import { InformationItemProps } from './props/InformationItemProps'
import { useTranslation } from 'react-i18next'

const ContactInformationItem = (props: InformationItemProps): React.ReactElement => {
  const { user, setUser, setField } = props
  const { t } = useTranslation()

  return (
    <AItem>
      <Box>
        <Typography variant="h4" component="div" gutterBottom>
          {t('account.contactInformation.title')}
        </Typography>
        <List>
          <AListElement
            primary={t('account.contactInformation.email')}
            secondary={user.email}
            user={user}
            setUser={setUser}
            fieldId={'email'}
            editable
            setField={setField}
          />
          <AListElement
            primary={t('account.contactInformation.phoneNumber')}
            secondary={user.phoneNumber}
            user={user}
            setUser={setUser}
            fieldId={'phoneNumber'}
            editable
            setField={setField}
          />
        </List>
      </Box>
    </AItem>
  )
}

export default ContactInformationItem
