export default {
  title: 'Votre compte',
  profileInformation: {
    title: 'Profil',
    username: "Nom d'utilisateur",
    nickname: 'Pseudo',
    creationDate: 'Date de création',
  },
  personalInformation: {
    title: 'Informations personnelles',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    age: 'Âge',
    gender: 'Genre',
  },
  contactInformation: {
    title: 'Informations de contact',
    email: 'E-mail',
    phoneNumber: 'Numéro de téléphone',
  },
  systemInformation: {
    title: 'Informations système',
    accountRoles: 'Rôles du compte',
    lastConnection: 'Dernière connexion',
  },
  otherInformation: {
    title: 'Autres informations',
    languages: 'Langues',
    accents: 'Accents',
  },
  formDialog: {
    edit: 'Modifier',
    fields: {
      username: "nom d'utilisateur",
      email: 'adresse e-mail',
      firstname: 'prénom',
      lastname: 'nom de famille',
      nickname: 'pseudo',
      age: 'âge',
      phoneNumber: 'numéro de téléphone',
      gender: 'genre',
      languages: 'langues',
      accents: 'accents',
    },
  },
  archive: 'Archiver mon compte',
  changePassword: 'Changer mon mot de passe',
  enable: 'Activer ce compte',
  confirm: {
    archive: {
      title: 'Êtes-vous sûr de vouloir archiver votre compte ?',
      content: 'Cette action ne peut pas être annulée sans l’aide d’un administrateur.',
      keywordTextInstructions: 'Veuillez taper "{{keyword}}" pour confirmer l\'archivage de votre compte :',
    },
    enable: {
      title: 'Êtes-vous sûr de vouloir activer ce compte?',
      content: 'L’utilisateur pourra se connecter avec à nouveau.',
      success: 'Successfully enabled {{username}}',
      error: 'Failed to enable user {{username}}',
    },
  },
}
