import React, { useCallback, useContext, useEffect } from 'react'
import userContext from '../user/UserContext'
import { getSimulationRequestByClientUsername } from '../../rest/simulation/queries/SimulationRequestQueries'
import { APISimulationRequest } from '../../rest/simulation/models/APISimulationRequest'

const useSimulationManagement = () => {
  const frontUser = useContext(userContext)?.user
  const [simulationRequests, setSimulationRequests] = React.useState<APISimulationRequest[] | undefined>(undefined)

  const callSimulationRequests = useCallback(() => {
    if (frontUser) {
      getSimulationRequestByClientUsername(frontUser.username)
        .then((response) => {
          setSimulationRequests(response)
        })
        .catch((error) => {
          error.log(error)
        })
    }
  }, [frontUser])

  useEffect(() => {
    callSimulationRequests()
  }, [callSimulationRequests])

  return { simulationRequests, setSimulationRequests, callSimulationRequests }
}

export default useSimulationManagement
