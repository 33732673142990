import React, { ReactElement, useContext } from 'react'
import {
  AppBar,
  Box,
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MaterialUISwitch from '../mui/MUISwitch'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useAuth } from '@aws-amplify/ui-react/internal'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../contexts/user/UserContext'
import { useLocalStorage } from 'usehooks-ts'
import { FlagSelect } from './flags/FlagSelect'
import { useTranslation } from 'react-i18next'

const pages = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'Simulations',
    path: '/simulations',
  },
]

function MenuItems(props: { handleCloseUserMenu: () => void }): ReactElement {
  const { signOut } = useAuthenticator((context) => [context.user])
  const frontUser = useContext(UserContext)
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Box>
      <MenuItem
        sx={{ ':hover': { bgcolor: 'primary.light', color: '#fff' } }}
        key={'Profile'}
        onClick={() => {
          navigate('/profile')
          props.handleCloseUserMenu()
        }}
      >
        <Typography textAlign="center">{t('pageNames.profile')}</Typography>
      </MenuItem>
      <MenuItem
        sx={{ ':hover': { bgcolor: 'primary.light', color: '#fff' } }}
        key={'Account'}
        onClick={() => {
          navigate('/account')
          props.handleCloseUserMenu()
        }}
      >
        <Typography textAlign="center">{t('pageNames.account')}</Typography>
      </MenuItem>
      {frontUser?.isAdmin && (
        <MenuItem
          sx={{ ':hover': { bgcolor: 'primary.light', color: '#fff' } }}
          key={'Admin'}
          onClick={() => {
            navigate('/admin')
            props.handleCloseUserMenu()
          }}
        >
          <Typography textAlign="center">{t('pageNames.admin')}</Typography>
        </MenuItem>
      )}
      <MenuItem
        sx={{ ':hover': { bgcolor: 'primary.light', color: '#fff' } }}
        key={'Logout'}
        onClick={() => {
          signOut()
          navigate('/')
          props.handleCloseUserMenu()
        }}
      >
        <Typography textAlign="center">{t('pageNames.logout')}</Typography>
      </MenuItem>
    </Box>
  )
}

function Settings(props: {
  anchorElUser: null | HTMLElement
  handleOpenUserMenu: (event: React.MouseEvent<HTMLElement>) => void
  handleCloseUserMenu: () => void
}): ReactElement {
  const { user } = useAuth()
  const { anchorElUser, handleOpenUserMenu, handleCloseUserMenu } = props
  const { t } = useTranslation()

  if (user) {
    return (
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title={t('tooltips.navbar.accountOptions') ?? 'Account options'}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <AccountCircleIcon fontSize={'large'} sx={{ color: 'appbar.text' }} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
          PaperProps={{
            sx: {
              bgcolor: 'background.default',
            },
          }}
        >
          <MenuItems handleCloseUserMenu={handleCloseUserMenu} />
        </Menu>
      </Box>
    )
  }
  return <></>
}

function NavBar(props: { onClickDarkMode: () => void }): ReactElement {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const { onClickDarkMode } = props
  const navigate = useNavigate()
  const [mode] = useLocalStorage('mode', window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
  const [darkMode, setDarkMode] = React.useState(mode.toString() == 'dark')
  const { t } = useTranslation()
  const imgSrc = darkMode ? '/logo512.png' : '/logo512_white.png'

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenPage = (page: string) => {
    navigate(page)
    handleCloseNavMenu()
  }

  const getPageName = (path: string): string => {
    const page = pages.find((p) => p.path === path)
    if (page) {
      return t(`pageNames.${page.name.toLowerCase()}`)
    }
    return 'Unknown'
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: 'appbar.primary',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <Button
              onClick={() => navigate('/')}
              sx={{
                boxShadow: 5,
                bgcolor: 'appbar.primary',
                borderColor: 'transparent',
                '&:hover': {
                  borderColor: 'transparent',
                },
              }}
              variant={mode === 'light' ? 'contained' : 'outlined'}
            >
              <img src={imgSrc} width="40px" height="40px" style={{ marginRight: 10 }} alt="Airgate logo" />
              <Typography
                variant="h6"
                noWrap
                onClick={() => navigate('/')}
                sx={{
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  color: 'secondary.main',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                Airgate
              </Typography>
            </Button>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: 'appbar.text' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={() => handleOpenPage(page.path)}>
                  <Typography
                    textAlign="center"
                    sx={{
                      color: 'text.secondary',
                    }}
                  >
                    {getPageName(page.path)}
                  </Typography>
                </MenuItem>
              ))}
              <FlagSelect />
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, width: '50%' }}>
            <Button
              onClick={() => navigate('/')}
              sx={{
                bgcolor: 'appbar.primary',
                borderColor: 'transparent',
                '&:hover': {
                  borderColor: 'transparent',
                },
              }}
              variant={mode === 'light' ? 'contained' : 'outlined'}
            >
              <img src={imgSrc} width="30px" height="30px" style={{ marginRight: 10 }} alt="Airgate logo" />
              <Typography
                variant="h5"
                noWrap
                onClick={() => navigate('/')}
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  color: 'secondary.main',
                  textDecoration: 'none',
                }}
              >
                Airgate
              </Typography>
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => handleOpenPage(page.path)}
                sx={{
                  my: 2,
                  color: 'appbar.text',
                  display: 'block',
                  borderColor: 'transparent',
                  boxShadow: 0,
                  '&:hover': {
                    borderColor: 'transparent',
                    boxShadow: 0,
                    color: '#fff',
                  },
                }}
                variant={mode === 'light' ? 'contained' : 'outlined'}
              >
                <Typography>{getPageName(page.path)}</Typography>
              </Button>
            ))}
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <FlagSelect />
          </Box>

          <Tooltip
            title={
              darkMode
                ? t('tooltips.navbar.toggleLightTheme') ?? 'Toggle light theme'
                : t('tooltips.navbar.toggleDarkTheme') ?? 'Toggle dark mode'
            }
          >
            <Box sx={{ flexGrow: 0 }}>
              <FormGroup sx={{ alignItems: 'end' }}>
                <FormControlLabel
                  control={<MaterialUISwitch checked={darkMode} />}
                  label=""
                  onClick={() => {
                    onClickDarkMode()
                    setDarkMode(!darkMode)
                  }}
                />
              </FormGroup>
            </Box>
          </Tooltip>

          <Settings
            anchorElUser={anchorElUser}
            handleOpenUserMenu={handleOpenUserMenu}
            handleCloseUserMenu={handleCloseUserMenu}
          />
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default NavBar
