export default {
  ENGLISH: 'English',
  CHINESE: 'Chinese',
  HINDI: 'Hindi',
  SPANISH: 'Spanish',
  ARABIC: 'Arabic',
  FRENCH: 'French',
  RUSSIAN: 'Russian',
  PORTUGUESE: 'Portuguese',
  JAPANESE: 'Japanese',
  GERMAN: 'German',
  HOLLAND: 'Holland',
  ITALIAN: 'Italian',
  OTHER: 'Other',
}
