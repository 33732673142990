import { Box } from '@mui/material'
import { useMediaQuery } from 'react-responsive'
import React from 'react'

interface CardButtonBoxProps {
  children: React.ReactNode
}

function CardButtonBox(props: CardButtonBoxProps) {
  const { children } = props
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 991.9 })
  return (
    <Box
      {...(isDesktopOrLaptop && {
        sx: {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          overflowX: 'auto',
        },
      })}
      {...(isTabletOrMobile && {
        sx: {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        },
      })}
    >
      {children}
    </Box>
  )
}

export default CardButtonBox
