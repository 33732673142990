import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { useSimulationManagementContext } from '../../hooks/ContextHooks'
import { getUserList } from '../../rest/user/APIUser'
import Candidate from '../../pages/simulation/management/CandidatesType'
import { APIVoluntaryUser } from '../../rest/simulation/models/APIVoluntaryUser'
import { APISimulationRequest } from '../../rest/simulation/models/APISimulationRequest'

const useSimulationManagementApplications = () => {
  const { simulationId } = useParams() //The id of the simulation
  const { simulationRequests } = useSimulationManagementContext() //The list of simulation requests
  const [simulationRequest, setSimulationRequest] = useState<APISimulationRequest | undefined>(
    simulationRequests?.find((value) => {
      return value.id?.toString() == simulationId
    }),
  ) //The simulation request of the simulation
  const [voluntaryUsers, setVoluntaryUsers] = useState<APIVoluntaryUser[] | undefined>(
    simulationRequest?.voluntaryUsers,
  ) //The list of voluntary users of the simulation
  const [usernames, setUsernames] = useState<string[] | undefined>(
    Array.from(new Set(voluntaryUsers?.map((user) => user.username))),
  ) //The ids of the users of the simulation
  const [candidates, setCandidates] = useState<Candidate[] | undefined>(undefined) //The list of candidates

  useEffect(() => {
    setSimulationRequest(
      simulationRequests?.find((value) => {
        return value.id?.toString() == simulationId
      }),
    )
  }, [simulationRequests, simulationId])

  useEffect(() => {
    setVoluntaryUsers(simulationRequest?.voluntaryUsers)
  }, [simulationRequest])

  useEffect(() => {
    setUsernames(Array.from(new Set(voluntaryUsers?.map((user) => user.username))))
  }, [voluntaryUsers])

  const refreshCandidates = useCallback(() => {
    if (!usernames || usernames.length < 1) return
    getUserList(usernames)
      .then((users) => {
        const candidatesList: Candidate[] = []
        users.forEach((user) => {
          if (voluntaryUsers === undefined) return
          const volUsers: APIVoluntaryUser[] = voluntaryUsers.filter(
            (voluntaryUser) => voluntaryUser.username == user.username,
          )
          volUsers.forEach((voluntaryUser) => {
            candidatesList.push({ user: user, voluntaryUser: voluntaryUser })
          })
        })
        setCandidates(candidatesList)
      })
      .catch((e) => console.error(e))
  }, [usernames, voluntaryUsers])

  useEffect(() => {
    refreshCandidates()
  }, [refreshCandidates])

  const updateVoluntaryUser = useCallback(
    (voluntaryUser: APIVoluntaryUser) => {
      const newCandidates = candidates?.map((candidate) => {
        if (candidate.voluntaryUser.id == voluntaryUser.id) {
          return { ...candidate, voluntaryUser: voluntaryUser }
        }
        return candidate
      })
      setCandidates(newCandidates)
    },
    [candidates],
  )

  return { candidates, simulationRequest, updateVoluntaryUser, refreshCandidates }
}

export default useSimulationManagementApplications
