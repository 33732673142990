import React from 'react'
import { AItem, AListElement } from './AItem'
import { Box, List, Typography } from '@mui/material'
import { InformationItemProps } from './props/InformationItemProps'
import { useTranslation } from 'react-i18next'

const PersonalInformationItem = (props: InformationItemProps): React.ReactElement => {
  const { user, setUser, setField } = props
  const { t } = useTranslation()

  return (
    <AItem>
      <Box>
        <Typography variant="h4" component="div" gutterBottom>
          {t('account.personalInformation.title')}
        </Typography>
        <List>
          <AListElement
            primary={t('account.personalInformation.firstName')}
            secondary={user.firstname}
            user={user}
            setUser={setUser}
            fieldId={'firstname'}
            editable
            setField={setField}
          />
          <AListElement
            primary={t('account.personalInformation.lastName')}
            secondary={user.lastname}
            user={user}
            setUser={setUser}
            fieldId={'lastname'}
            editable
            setField={setField}
          />
          <AListElement
            primary={t('account.personalInformation.age')}
            secondary={user.age + ' years old'}
            user={user}
            setUser={setUser}
            fieldId={'age'}
            editable
            setField={setField}
          />
          <AListElement
            primary={t('account.personalInformation.gender')}
            secondary={t(`gender.${user.gender}`)}
            user={user}
            setUser={setUser}
            fieldId={'gender'}
            editable
            setField={setField}
          />
        </List>
      </Box>
    </AItem>
  )
}

export default PersonalInformationItem
