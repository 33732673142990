import React from 'react'
import SimulationSocketContext from './SimulationSocketContext'
import useSimulationSocket from './useSimulationSocket'

const SimulationSocketProvider = (props: { children: React.ReactNode }) => {
  const {
    simulationSocket,
    freqStatus,
    frequencies,
    privateCall,
    incomingPrivateCalls,
    pausedPrivateCalls,
    pausePrivateCall,
    resumePrivateCall,
    isError,
    mediaDevices,
  } = useSimulationSocket()
  const { children } = props

  return (
    <SimulationSocketContext.Provider
      value={{
        frequencies: frequencies,
        socket: simulationSocket,
        freqStatus: freqStatus,
        privateCall: privateCall,
        incomingPrivateCalls: incomingPrivateCalls,
        pausedPrivateCalls: pausedPrivateCalls,
        pausePrivateCall: pausePrivateCall,
        resumePrivateCall: resumePrivateCall,
        isError: isError,
        mediaDevices: mediaDevices,
      }}
    >
      {children}
    </SimulationSocketContext.Provider>
  )
}

export default SimulationSocketProvider
