import { useLocalParticipant, useTracks } from '@livekit/components-react'
import { SpatialAudioController } from './SpatialAudioController'

interface SpatialAudioRendererProps {
  position: 'LEFT' | 'RIGHT' | 'CENTER'
}

/**
 * This is a stub for the SpatialAudioRenderer component.
 * @param props - position: 'LEFT' | 'RIGHT' | 'CENTER'
 * @prereq Needs to be in a LiveKit RoomContext
 */
const SpatialAudioRenderer = (props: SpatialAudioRendererProps) => {
  const { position } = props
  const tracks = useTracks()
  const participant = useLocalParticipant()
  const trackPositions = tracks
    .filter((track) => track.participant.identity !== participant.localParticipant.identity)
    .map((track) => {
      return {
        trackPublication: track.publication,
        position: {
          x: position === 'LEFT' ? -1 : position === 'RIGHT' ? 1 : 0,
          y: 0,
        },
      }
    })

  return (
    <>
      <SpatialAudioController myPosition={{ x: 0, y: 0 }} trackPositions={trackPositions} maxHearableDistance={300} />
    </>
  )
}

export default SpatialAudioRenderer
