import CardButton, { CardButtonProps } from './CardButton'
import CardButtonBox from './CardButtonBox'
import { useMediaQuery } from 'react-responsive'

interface CardButtonGridProps {
  cardButtonsList: CardButtonProps[]
}

function CardButtonGrid(props: CardButtonGridProps) {
  const { cardButtonsList } = props
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 })
  const isTablet = useMediaQuery({ minWidth: 540, maxWidth: 991.9 })

  return (
    <CardButtonBox>
      {cardButtonsList.map((cardButton: CardButtonProps) => (
        <CardButton
          key={cardButton.title}
          title={cardButton.title}
          description={cardButton.description}
          onClick={cardButton.onClick}
          sx={
            cardButton.sx
              ? {
                  ...cardButton.sx,
                  width: isDesktopOrLaptop ? '30%' : isTablet ? '50%' : '75%',
                }
              : {
                  width: isDesktopOrLaptop ? '30%' : isTablet ? '50%' : '75%',
                }
          }
          textColor={cardButton.textColor}
          icon={cardButton.icon}
        />
      ))}
    </CardButtonBox>
  )
}

export default CardButtonGrid
