import PhoneIcon from '@mui/icons-material/Phone'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Collapse,
  Divider,
  IconButton,
  SxProps,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled'
import { useState } from 'react'
import { RoomContext } from '@livekit/components-react'
import * as React from 'react'
import { WebAudioContext } from '../../../../../utils/audio/webAudio'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import UserAutocomplete from './UserAutocomplete'
import CustomRoomComponent from './CustomRoomComponent'
import CallActions, { AcceptOrRejectCallButtons } from './CallActions'
import { PrivateCall } from '../../../../../contexts/simulation/PrivateCall'
import {
  useSimulationInterfaceSettingsContext,
  useSimulationSocketContext,
  useUserContext,
} from '../../../../../hooks/ContextHooks'
import CallListAccordion from './CallListAccordion'
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded'

export interface PrivateCallAccordionProps {
  userList: { label: string; value: string }[]
  selectedUser: { label: string; value: string }
  setSelectedUser: (user: { label: string; value: string }) => void
  sx?: SxProps
}

const PrivateCallAccordion = (props: { simulationId: number; callUserList: { label: string; value: string }[] }) => {
  const { simulationId, callUserList } = props
  const userList = [
    { label: 'admin', value: 'admin' },
    { label: 'user', value: 'user' },
    { label: 'test', value: 'test' },
    { label: 'user1', value: 'user1' },
    { label: 'user2', value: 'user2' },
    { label: 'user3', value: 'user3' },
    { label: 'user4', value: 'user4' },
    { label: 'user5', value: 'user5' },
    { label: 'William DOE', value: 'wdoe' },
    { label: 'Jérôme FERNANDEZ', value: 'jfernandez' },
    { label: 'Alan PRAT', value: 'aprat' },
    { label: 'Céline VILLAUME', value: 'cvillaume' },
    { label: 'Rémi MARTY', value: 'rmarty' },
    { label: 'Thierry DESMETD', value: 'tdesmetd' },
    { label: 'Antonio SARMENTO', value: 'asarmento' },
    { label: 'Mickaël CONDE', value: 'mconde' },
    { label: 'Camille DUPUY', value: 'cdupuy' },
    { label: 'Andy AUTUORI', value: 'aautuori' },
    { label: 'Luc ESQUIÉ', value: 'lesquie' },
    { label: 'Sébastien KUNSTOWICZ', value: 'skunstowicz' },
  ]
  const { t } = useTranslation()
  const { user } = useUserContext()
  const [selectedUser, setSelectedUser] = useState<{ label: string; value: string }>({ label: '', value: '' })
  const { privateCall, incomingPrivateCalls, socket, pausedPrivateCalls, resumePrivateCall } =
    useSimulationSocketContext()
  const webSocket = socket?.webSocket
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const [collapseOpen, setCollapseOpen] = useState(false)
  const simulationInterfaceSettings = useSimulationInterfaceSettingsContext()
  const { privateCallAlignment } = simulationInterfaceSettings.settings.privateCallSettings

  const getUserList = () => {
    if (simulationId === 1) {
      return userList
    } else {
      return callUserList
    }
  }

  if (!privateCall || !webSocket || !user) {
    return null
  }

  const handleStartCall = () => {
    if (selectedUser.value === '') {
      return
    }
    webSocket.sendJsonMessage({
      user: {
        username: user.username,
        nickname: user.nickname,
      },
      action: 'START_CALL',
      target: {
        username: selectedUser.value,
        nickname: selectedUser.label,
      },
      room: '',
    })
    setSelectedUser({ label: '', value: '' })
  }

  const handleAcceptCall = (call?: PrivateCall) => {
    if (call) {
      webSocket.sendJsonMessage({
        user: {
          username: user.username,
          nickname: user.nickname,
        },
        action: 'ACCEPT_CALL',
        target: {
          username: call.owner?.username,
          nickname: call.owner?.nickname,
        },
        room: call.room,
      })
      if (call.audioContext.state === 'suspended') {
        privateCall.audioContext.resume().then().catch(console.error)
      }
    } else {
      webSocket.sendJsonMessage({
        user: {
          username: user.username,
          nickname: user.nickname,
        },
        action: 'ACCEPT_CALL',
        target: {
          username: privateCall.owner?.username,
          nickname: privateCall.owner?.nickname,
        },
        room: privateCall.room,
      })
    }
    if (privateCall.audioContext.state === 'suspended') {
      privateCall.audioContext.resume().then().catch(console.error)
    }
  }

  const handleRejectCall = (call?: PrivateCall) => {
    if (call) {
      webSocket.sendJsonMessage({
        user: {
          username: user.username,
          nickname: user.nickname,
        },
        action: 'REJECT_CALL',
        target: {
          username: call.owner?.username,
          nickname: call.owner?.nickname,
        },
        room: call.room,
      })
    } else {
      webSocket.sendJsonMessage({
        user: {
          username: user.username,
          nickname: user.nickname,
        },
        action: 'REJECT_CALL',
        target: {
          username: privateCall.owner?.username,
          nickname: privateCall.owner?.nickname,
        },
        room: privateCall.room,
      })
    }
  }

  const handleCancelCall = () => {
    webSocket.sendJsonMessage({
      user: {
        username: user.username,
        nickname: user.nickname,
      },
      action: 'REJECT_CALL',
      target: {
        username: privateCall.lastCalledUser?.username,
        nickname: privateCall.lastCalledUser?.nickname,
      },
      room: privateCall.room,
    })
  }

  const handleResumeCall = (call: PrivateCall) => {
    resumePrivateCall(call)
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          right: privateCallAlignment.includes('right') ? (isMobile ? 10 : 20) : 'auto',
          left: privateCallAlignment.includes('left') ? (isMobile ? 10 : 20) : 'auto',
          bottom: privateCallAlignment.includes('bottom') ? 20 : 'auto',
          top: privateCallAlignment.includes('top') ? (isMobile ? 80 : 90) : 'auto',
          display: 'flex',
          flexDirection: privateCallAlignment.includes('bottom') ? 'column' : 'column-reverse',
          pointerEvents: 'none',
        }}
      >
        <Card
          sx={{
            bgcolor: 'secondary.light',
            display: 'flex',
            flexDirection: privateCallAlignment.includes('right') ? 'row' : 'row-reverse',
            height: '45vh',
            alignSelf: privateCallAlignment.includes('right') ? 'flex-end' : 'flex-start',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            pointerEvents: 'auto',
            mb: privateCallAlignment.includes('bottom') ? 3 : 0,
            mt: privateCallAlignment.includes('top') ? 3 : 0,
            boxShadow: 5,
          }}
        >
          <CardActionArea
            sx={{ bgcolor: 'secondary.dark', color: 'white', width: isMobile ? 30 : 40 }}
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            {privateCallAlignment.includes('right') ? (
              collapseOpen ? (
                <KeyboardArrowRightIcon />
              ) : (
                <KeyboardArrowLeftIcon />
              )
            ) : collapseOpen ? (
              <KeyboardArrowLeftIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </CardActionArea>
          <Collapse
            orientation={'horizontal'}
            in={collapseOpen}
            sx={{
              overflowY: 'auto',
              scrollBehaviour: 'smooth',
              maxHeight: '45vh',
            }}
          >
            <CardContent
              sx={{
                width: isMobile ? 270 : 260,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  height: '60px',
                }}
              >
                <Typography variant={'h6'}>{t('simulations.running.privateCall.callList.title')}</Typography>
                <Divider sx={{ mt: 1, bgcolor: 'primary.main' }} />
              </Box>

              <CallListAccordion
                privateCalls={incomingPrivateCalls}
                SummaryComponent={
                  <>
                    <Typography variant={'body1'}>
                      {t('simulations.running.privateCall.callList.incomingCalls')}
                    </Typography>
                    <Chip
                      sx={{
                        color: 'white',
                        ml: 'auto',
                        mr: 1,
                        ':hover': {
                          cursor: 'pointer',
                        },
                      }}
                      color={incomingPrivateCalls?.length >= 1 ? 'warning' : 'info'}
                      size={'small'}
                      label={incomingPrivateCalls?.length}
                    />
                  </>
                }
                CallCardComponent={(propsCallCard) => {
                  const { call } = propsCallCard

                  return (
                    <Card
                      sx={{
                        bgcolor: 'secondary.dark',
                        color: 'white',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        m: 'auto',
                        alignItems: 'center',
                        mb: 1,
                        height: 50,
                        p: 1,
                      }}
                    >
                      <Box>
                        <Typography variant={'body2'}>{call.owner?.nickname}</Typography>
                      </Box>
                      <Box>
                        <AcceptOrRejectCallButtons
                          handleAcceptCall={() => handleAcceptCall(call)}
                          handleRejectCall={() => handleRejectCall(call)}
                          size={'small'}
                        />
                      </Box>
                    </Card>
                  )
                }}
              />

              <CallListAccordion
                privateCalls={pausedPrivateCalls}
                accordionSx={{ mt: 2 }}
                SummaryComponent={
                  <>
                    <Typography variant={'body1'}>
                      {t('simulations.running.privateCall.callList.pausedCalls')}
                    </Typography>
                    <Chip
                      sx={{
                        color: 'white',
                        ml: 'auto',
                        mr: 1,
                        ':hover': {
                          cursor: 'pointer',
                        },
                      }}
                      color={pausedPrivateCalls?.length >= 1 ? 'warning' : 'info'}
                      size={'small'}
                      label={pausedPrivateCalls?.length}
                    />
                  </>
                }
                CallCardComponent={(propsCallCard) => {
                  const { call } = propsCallCard

                  return (
                    <Card
                      key={call.room}
                      sx={{
                        bgcolor: 'secondary.dark',
                        color: 'white',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        m: 'auto',
                        alignItems: 'center',
                        mb: 1,
                        height: 50,
                        p: 1,
                      }}
                    >
                      <Box>
                        <Typography variant={'body2'}>
                          {user?.id.toString() === call.owner?.username ? call.users[1].nickname : call.owner?.nickname}
                        </Typography>
                      </Box>
                      <Tooltip title={t('tooltips.simulations.running.privateCall.resume')} placement={'top'}>
                        <IconButton onClick={() => handleResumeCall(call)} size={'small'}>
                          <PhoneForwardedIcon sx={{ color: 'secondary.main', fontSize: '1.5rem' }} />
                        </IconButton>
                      </Tooltip>
                    </Card>
                  )
                }}
              />
            </CardContent>
          </Collapse>
        </Card>

        <Accordion
          sx={{
            bgcolor: 'secondary.light',
            borderRadius: '10px',
            boxShadow: 5,
            width: 300,
            pointerEvents: 'auto',
          }}
          disableGutters
          className={'MuiAccordion-rounded'}
        >
          <CardActionArea
            sx={{
              bgcolor: 'secondary.dark',
              color: 'white',
              height: '100%',
              width: '100%',
              ':hover': {
                cursor: 'pointer',
                bgcolor: 'secondary.dark',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color={'secondary'} />}
              sx={{
                bgcolor: 'secondary.dark',
                color: 'white',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              }}
            >
              <Typography>{t('simulations.running.privateCall.title')}</Typography>
              <Chip
                sx={{
                  color: 'white',
                  ml: 'auto',
                  mr: 1,
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                color={
                  privateCall.status === 'NO_CALL'
                    ? 'info'
                    : privateCall.status === 'CALLING'
                    ? 'warning'
                    : privateCall.status === 'IN_CALL'
                    ? 'success'
                    : privateCall.status === 'RECEIVING_CALL'
                    ? 'error'
                    : 'error'
                }
                size={'small'}
                label={t('simulations.running.privateCall.status.' + privateCall.status)}
              />
            </AccordionSummary>
          </CardActionArea>

          <AccordionDetails>
            {privateCall.status === 'NO_CALL' ? (
              <>
                <Box sx={{ textAlign: 'center', width: '100%' }}>
                  <Typography>{t('simulations.running.privateCall.selectSomeone')}</Typography>
                </Box>

                <UserAutocomplete
                  userList={getUserList().filter((u) => u.value !== user.username + '')}
                  sx={{ m: 2 }}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                />

                <Tooltip title={t('tooltips.simulations.running.privateCall.call')} placement={'top'}>
                  <span>
                    <IconButton disabled={selectedUser.value === ''} onClick={handleStartCall}>
                      <PhoneIcon
                        sx={{ fontSize: '2rem', color: selectedUser.value === '' ? 'disabled' : 'primary.main' }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            ) : privateCall.status === 'RECEIVING_CALL' ? (
              <>
                <CallActions handleAcceptCall={() => handleAcceptCall()} handleRejectCall={() => handleRejectCall()} />
              </>
            ) : (
              <>
                {privateCall.status === 'CALLING' ? (
                  <>
                    <Box sx={{ textAlign: 'center', width: '100%' }}>
                      <Typography sx={{ mb: 2 }}>{t('simulations.running.privateCall.calling')}</Typography>
                      <Tooltip title={t('tooltips.simulations.running.privateCall.hangup')} placement={'top'}>
                        <IconButton onClick={handleCancelCall}>
                          <PhoneDisabledIcon sx={{ fontSize: '2rem', color: 'red' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </>
                ) : privateCall.status === 'IN_CALL' &&
                  privateCall.token &&
                  privateCall.livekitRoom &&
                  privateCall.audioContext ? (
                  <>
                    <RoomContext.Provider value={privateCall.livekitRoom}>
                      <WebAudioContext.Provider value={privateCall.audioContext}>
                        <CustomRoomComponent userList={getUserList()} />
                      </WebAudioContext.Provider>
                    </RoomContext.Provider>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}

export default PrivateCallAccordion
