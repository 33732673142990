export default {
  home: 'Home',
  simulations: 'Simulations',
  simulation: {
    participation: 'Participate',
    application: 'Apply',
    creation: 'Create and manage',
  },
  myaccount: 'My account',
  myprofile: 'My profile',
  account: 'Account',
  profile: 'Profile',
  admin: 'Admin',
  logout: 'Logout',
}
