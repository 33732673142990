import React, { useCallback, useEffect, useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import APIUser, { getUserByName } from '../../rest/user/APIUser'
import toast from 'react-hot-toast'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const useFrontUser = () => {
  const [apiUser, setApiUser] = useState<APIUser | undefined>(undefined)
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined)
  const [token, setToken] = useState<string | undefined>(undefined)
  const [roles, setRoles] = useState<string[]>([])
  const { user } = useAuthenticator((context) => [context.user])
  const { t } = useTranslation()

  const refreshUser = useCallback(() => {
    if (!user || !user.getSignInUserSession() || !user.username) {
      setApiUser(undefined)
      setRoles([])
      setIsAdmin(undefined)
      setToken(undefined)
      return
    }
    const cognitoRoles = user.getSignInUserSession()?.getIdToken().payload['cognito:groups']
    getUserByName(user.username)
      .then((u) => {
        setApiUser(u)
        setRoles(cognitoRoles ? cognitoRoles : [])
        setIsAdmin(cognitoRoles ? cognitoRoles.includes('ADMIN') : false)
        setToken(user.getSignInUserSession()?.getAccessToken().getJwtToken())
      })
      .catch((reason) => {
        console.error(reason)
        setApiUser(undefined)
        setRoles([])
        setIsAdmin(false)
        toast.error(
          () => (
            <Box textAlign={'center'}>
              <Typography variant={'body1'}>{t('toast.signIn.error1')}</Typography>
              <Typography variant={'body1'}>{t('toast.signIn.error2')}</Typography>
            </Box>
          ),
          {
            duration: 7000,
          },
        )
      })
  }, [user, t])

  useEffect(() => {
    refreshUser()
  }, [refreshUser])

  return { apiUser, isAdmin, roles, refreshUser, token }
}

export default useFrontUser
