import CardButtonBox from '../../../components/mui/cardbutton/CardButtonBox'
import React from 'react'
import { Alert, AlertTitle, Box, CircularProgress, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate } from 'react-router-dom'
import CardButtonAction from '../../../components/mui/cardbutton/CardButtonAction'
import { useTranslation } from 'react-i18next'
import { useJoinedSimulationsContext, useUserContext } from '../../../hooks/ContextHooks'
import JoinedSimulationCard, { JoinedSimulationCardTemp } from './JoinedSimulationCard'
import { TitleDivider } from '../SimulationHome'
import { GenericResponsiveListComponent } from '../../../components/mui/GenericResponsiveListComponent'

const JoinedSimulations = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const joinedSimulationsContext = useJoinedSimulationsContext()
  const incomingParticipationRequestsList = joinedSimulationsContext?.incomingParticipationRequestsList
  const activeParticipationList = joinedSimulationsContext?.activeParticipationList
  const { user } = useUserContext()

  const FindBox = () => (
    <CardButtonAction
      description={t('simulations.home.joined.findcard')}
      sx={{
        m: 2,
        minWidth: '250px',
        height: '220px',
        boxShadow: 5,
        borderRadius: 3,
        '&:hover': {
          boxShadow: 15,
        },
      }}
      icon={
        <SearchIcon
          sx={{
            fontSize: 50,
          }}
        />
      }
      cardActionProps={{ sx: { height: '100%' } }}
      onClick={() => {
        navigate('/simulations/list')
      }}
    />
  )

  if (
    joinedSimulationsContext === undefined ||
    incomingParticipationRequestsList === undefined ||
    activeParticipationList === undefined
  ) {
    if (!user) {
      return (
        <Alert
          severity={'error'}
          sx={{
            width: '40%',
            minWidth: '300px',
            margin: '0 auto',
            textAlign: 'left',
          }}
          variant={'outlined'}
        >
          <AlertTitle>{t('simulations.home.joined.alert.title')}</AlertTitle>
          {t('simulations.home.joined.alert.description')}
        </Alert>
      )
    }
    return <CircularProgress />
  } else {
    return (
      <>
        <Typography variant="h4" component="div" gutterBottom>
          {t('simulations.home.joined.titleRunning')}
        </Typography>
        <TitleDivider />
        <CardButtonBox>
          <JoinedSimulationCardTemp />
          {activeParticipationList.map((participation, index) => (
            <JoinedSimulationCard
              simulation={participation.simulation}
              role={participation.role}
              status={participation.simulation.status}
              key={index}
            />
          ))}
          <FindBox />
        </CardButtonBox>

        {incomingParticipationRequestsList.length > 0 && (
          <>
            <Box marginBottom={4} />
            <Typography variant="h4" component="div" gutterBottom>
              {t('simulations.home.joined.titleIncoming')}
            </Typography>
            <TitleDivider />
            <GenericResponsiveListComponent
              tList={incomingParticipationRequestsList}
              subComponentWidth={270}
              subComponent={(participation) => (
                <JoinedSimulationCard
                  simulation={participation.simulation}
                  role={participation.role}
                  status={participation.simulation.status}
                  key={participation.role.id}
                />
              )}
            />
          </>
        )}
      </>
    )
  }
}

export default JoinedSimulations
