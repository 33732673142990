export default {
  ENGLISH_UK: 'Anglais (UK)',
  ENGLISH_US: 'Anglais (US)',
  ENGLISH_AU: 'Anglais (AU)',
  ENGLISH_IN: 'Anglais (IN)',
  ENGLISH_CA: 'Anglais (CA)',
  FRENCH_FR: 'Français (FR)',
  FRENCH_CA: 'Français (CA)',
  SPANISH_ES: 'Espagnol (ES)',
  PORTUGUESE_PT: 'Portugais (PT)',
  PORTUGUESE_BR: 'Portugais (BR)',
  GERMAN_DE: 'Allemand (DE)',
  CHINESE_CN: 'Chinois (CN)',
  JAPANESE_JP: 'Japonais (JP)',
  HINDI_IN: 'Hindi (IN)',
  ARABIC_SA: 'Arabe (SA)',
  RUSSIAN_RU: 'Russe (RU)',
  HOLLAND_NL: 'Néerlandais (NL)',
  ITALIAN_IT: 'Italien (IT)',
  OTHER: 'Autre',
}
