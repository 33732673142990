import homeMessages from './categories/pages/homeMessages'
import pageNames from './categories/pageNames'
import tooltips from './categories/tooltipsMessages'
import accountMessages from './categories/pages/accountMessages'
import footerMessages from './categories/components/footerMessages'
import breadcrumbsMessages from './categories/components/breadcrumbsMessages'
import buttonMessages from './categories/components/buttonMessages'
import simulationsMessages from './categories/pages/simulationsMessages'
import toastMessages from './categories/toastMessages'
import languageTranslations from './categories/values/languageTranslations'
import genderTranslations from './categories/values/genderTranslations'
import accentTranslations from './categories/values/accentTranslations'
import weatherTranslations from './categories/values/weatherTranslations'
import windTranslations from './categories/values/windTranslations'
import roleTypesTranslations from './categories/values/roleTypesTranslations'
import candidacyStatusTranslations from './categories/values/candidacyStatusTranslations'

export default {
  home: homeMessages,
  account: accountMessages,
  pageNames: pageNames,
  tooltips: tooltips,
  footer: footerMessages,
  breadcrumbs: breadcrumbsMessages,
  button: buttonMessages,
  simulations: simulationsMessages,
  toast: toastMessages,
  languages: languageTranslations,
  gender: genderTranslations,
  accents: accentTranslations,
  weather: weatherTranslations,
  wind: windTranslations,
  roleTypes: roleTypesTranslations,
  candidacyStatus: candidacyStatusTranslations,
}
