import React from 'react'
import { useConfirm } from 'material-ui-confirm'
import { Box, Button } from '@mui/material'
import CardSimulationFormFrequency from './CardSimulationFormFrequency'
import ConferenceCreationStepper from '../SimulaionFormStepper'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import CardButtonAction from '../../../../components/mui/cardbutton/CardButtonAction'
import { useTranslation } from 'react-i18next'
import { APISimulationConf } from '../../../../rest/simulation/models/APISimulationConf'

interface ConferencefrequencyProps {
  simulation: APISimulationConf
  setSimulation: React.Dispatch<React.SetStateAction<APISimulationConf>>
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  frequencies: string[]
  setFrequencies: React.Dispatch<React.SetStateAction<string[]>>
}

function SimulationFormFrequency(props: ConferencefrequencyProps) {
  const [t] = useTranslation()
  const { step, setStep, frequencies, setFrequencies, simulation } = props
  const confirm = useConfirm()

  function handleAddFrequency() {
    setFrequencies([...frequencies, ''])
  }

  function handleDeleteFrequency(id: number) {
    if (frequencies[id] === '') {
      setFrequencies(frequencies.filter((frequency, index) => index !== id))
    } else {
      confirm({
        title: t('simulations.create.channels.delete.title'),
        description: t('simulations.create.channels.delete.description'),
      })
        .then(() => {
          const updatedFrequencies = frequencies.filter((frequency, index) => index !== id)
          setFrequencies(updatedFrequencies)
          simulation.roles.forEach((role) => {
            role.frequencies = role.frequencies.filter((frequency) => frequency !== frequencies[id])
          })
        })
        .catch()
    }
  }

  function handleInputText(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const frequenciesCopy = [...frequencies]
    frequenciesCopy[index] = event.target.value
    setFrequencies(frequenciesCopy)
  }

  function handleContinue() {
    let valid = frequencies.length === new Set(frequencies).size
    frequencies.forEach((frequency) => {
      if (frequency.length < 2) {
        valid = false
      }
    })
    if (valid) {
      setStep(step + 1)
    }
  }

  return (
    <>
      <ConferenceCreationStepper step={step} />
      <Box sx={{ width: 400, maxWidth: '85%', margin: 'auto', marginTop: '40px' }}>
        {frequencies.map((frequency, index) => (
          <CardSimulationFormFrequency
            key={index}
            id={index}
            handleDeleteFrequency={handleDeleteFrequency}
            handleInputText={(event: React.ChangeEvent<HTMLInputElement>) => handleInputText(event, index)}
            isSingle={frequencies.length === 1}
            frequency={frequency}
          />
        ))}
        <CardButtonAction
          description={t('simulations.create.channels.new')}
          sx={{ width: '100%', borderRadius: 3, boxShadow: 2, mr: 1 }}
          icon={<AddCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />}
          onClick={handleAddFrequency}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mt: 4 }}>
          <Button onClick={() => setStep(step - 1)} variant={'outlined'}>
            {t('simulations.create.buttons.return')}
          </Button>
          <Button variant={'contained'} color={'info'} sx={{ marginLeft: 5 }} onClick={handleContinue}>
            {t('simulations.create.buttons.continue')}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default SimulationFormFrequency
