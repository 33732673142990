import { Typography } from '@mui/material'

function Forbidden() {
  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        403
      </Typography>
      <Typography variant="h4" component="div" gutterBottom>
        Forbidden
      </Typography>
    </>
  )
}

export default Forbidden
