export default {
  navbar: {
    toggleDarkTheme: 'Toggle dark mode',
    toggleLightTheme: 'Toggle light mode',
    accountOptions: 'Account options',
  },
  account: {
    edit: 'Edit',
  },
  simulations: {
    running: {
      pttMic: 'Hold P to talk',
      pttMicOffMobile: 'Hold to talk',
      pttMicOnMobile: 'Release to stop talking',
      muteAudio: 'Press M to mute audio',
      unmuteAudio: 'Press M to unmute audio',
      settings: {
        cannotAccessInput: 'Cannot access input devices',
        cannotAccessOutput: 'Cannot access output devices',
      },
      privateCall: {
        call: 'Start a private call',
        hangup: 'Hangup',
        pickUp: 'Pick up',
        hold: 'Hold',
        resume: 'Resume',
        add: 'Add someone to the call',
        cancel: 'Cancel',
      },
    },
  },
}
