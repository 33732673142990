import { CircularProgress, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { SimulationRequestRoleComponent } from './SimulationRequestRoleComponent'
import { GenericResponsiveListComponent } from '../../../../components/mui/GenericResponsiveListComponent'
import UserContext from '../../../../contexts/user/UserContext'
import { getSimulationRequestById } from '../../../../rest/simulation/queries/SimulationRequestQueries'
import { APISimulationRole } from '../../../../rest/simulation/models/APISimulationRole'
import { APISimulationRequest } from '../../../../rest/simulation/models/APISimulationRequest'

export const SimulationRequestRoleList = () => {
  const [simulation, setSimulation] = useState<APISimulationRequest | undefined>(undefined)
  const { simulationId } = useParams()
  const navigate = useNavigate()
  const [disabledRoles, setDisabledRoles] = useState<number[]>([])
  const user = useContext(UserContext)?.user

  if (simulation === undefined) {
    if (!simulationId) {
      return <Navigate to={'/404'} />
    }
    getSimulationRequestById(simulationId)
      .then((s) => {
        if (s.voluntaryUsers && user) {
          const ids = s.voluntaryUsers
            .filter((u) => u.username === user.username || u.acceptanceStatus === 'ACCEPTED')
            .map((u) => u.roleId) as number[]
          setDisabledRoles(ids)
        }
        setSimulation(s)
      })
      .catch((e) => navigate('/' + e))
    return <CircularProgress />
  }

  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        {simulation.simulation.name}
      </Typography>
      {simulation.status === 'PUBLISHED' ? (
        <GenericResponsiveListComponent<APISimulationRole>
          tList={simulation.simulation.roles}
          subComponentWidth={350}
          subComponent={(value) => {
            return (
              <SimulationRequestRoleComponent
                key={value.id}
                role={value}
                simulation={simulation}
                setDisabledRoles={setDisabledRoles}
                disabled={disabledRoles.includes(value.id as number)}
              />
            )
          }}
        />
      ) : (
        <Navigate to={'/404'} />
      )}
    </>
  )
}
