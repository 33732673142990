import { Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import resources from '../../aws-resources'
import CardButtonGrid from '../../components/mui/cardbutton/CardButtonGrid'

function AdminPage() {
  const navigate = useNavigate()

  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        Admin page
      </Typography>
      <CardButtonGrid
        cardButtonsList={[
          {
            title: 'Account list',
            description: 'In this page you can manage all the accounts of the application.',
            onClick: () => navigate('/admin/account-list'),
          },
          {
            title: 'User pool',
            description: 'Redirection to the Cognito user pool page',
            onClick: () =>
              window.open(
                'https://eu-west-3.console.aws.amazon.com/cognito/v2/idp/user-pools/' +
                  resources.connection.aws_user_pools_id +
                  '/users?region=eu-west-3',
                '_blank',
              ),
          },
        ]}
      />
      <CardButtonGrid
        cardButtonsList={[
          {
            title: 'Simulation requests history',
            description: 'In this page you can see all past simulation request, and edit some informations.',
            onClick: () => navigate('/admin/simulation-history'),
          },
        ]}
      />
    </>
  )
}

export default AdminPage
