import { get, post, put } from '../../rest-utils'
import ApiResources from '../../../api-resources'
import { APIVoluntaryUser } from '../models/APIVoluntaryUser'
import { APIActiveParticipationType } from '../models/APIActiveParticipationType'
import { APIIncomingParticipationType } from '../models/APIIncomingParticipationType'

export async function postParticipation(
  username?: string,
  requestId?: number,
  roleId?: number,
): Promise<APIVoluntaryUser> {
  return post(
    'simulation-conf/participation',
    { username: username, requestId: requestId, roleId: roleId },
    ApiResources.api_simulation_conf_url,
  )
}

export async function putAcceptParticipation(id: number): Promise<APIVoluntaryUser> {
  return put(`simulation-conf/participation/accept?voluntaryUserId=${id}`, {}, ApiResources.api_simulation_conf_url)
}

export async function putRejectParticipation(id: number): Promise<APIVoluntaryUser> {
  return put(`simulation-conf/participation/reject?voluntaryUserId=${id}`, {}, ApiResources.api_simulation_conf_url)
}

export async function getRolesRequestedByUsername(username: string): Promise<APIVoluntaryUser[]> {
  return get<APIVoluntaryUser[]>(
    `simulation-conf/participation?username=${username}`,
    ApiResources.api_simulation_conf_url,
  )
}

export async function getActiveSimulationsParticipationByUsername(
  username: string,
): Promise<APIActiveParticipationType[]> {
  return get<APIActiveParticipationType[]>(
    `simulation-conf/participation/active-simulations/participation?username=${username}`,
    ApiResources.api_simulation_conf_url,
  )
}

export async function getIncomingSimulationsParticipationByUsername(
  username: string,
): Promise<APIIncomingParticipationType[]> {
  return get<APIIncomingParticipationType[]>(
    `simulation-conf/participation/incoming-simulations/participation?username=${username}`,
    ApiResources.api_simulation_conf_url,
  )
}
