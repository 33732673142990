import React from 'react'
import { Box, List, Typography } from '@mui/material'
import { AItem, AListElement } from './AItem'
import { InformationItemProps } from './props/InformationItemProps'
import { useTranslation } from 'react-i18next'

const ProfileInformationItem = (props: InformationItemProps): React.ReactElement => {
  const { user, setUser, setField } = props
  const { t } = useTranslation()

  return (
    <AItem>
      <Box>
        <Typography variant="h4" component="div" gutterBottom>
          {t('account.profileInformation.title')}
        </Typography>
        <List>
          <AListElement
            primary={t('account.profileInformation.username')}
            secondary={user.username}
            user={user}
            setUser={setUser}
            fieldId={'username'}
          />
          <AListElement
            primary={t('account.profileInformation.nickname')}
            secondary={user.nickname}
            user={user}
            setUser={setUser}
            fieldId={'nickname'}
            editable
            setField={setField}
          />
          <AListElement
            primary={t('account.profileInformation.creationDate')}
            secondary={
              new Date(user.creationDate).toLocaleDateString() + ' ' + new Date(user.creationDate).toLocaleTimeString()
            }
            user={user}
            setUser={setUser}
            fieldId={'creationDate'}
          />
        </List>
      </Box>
    </AItem>
  )
}

export default ProfileInformationItem
