import { useContext } from 'react'
import SimulationSocketContext, { SimulationSocketContextType } from '../contexts/simulation/SimulationSocketContext'
import { FrontUser } from '../contexts/user/FrontUser'
import UserContext from '../contexts/user/UserContext'
import SimulationManagementContext, {
  SimulationManagementType,
} from '../contexts/management/SimulationManagementContext'
import JoinedSimulationsContext, {
  JoinedSimulationsType,
} from '../contexts/joined-simulations/JoinedSimulationsContext'
import SimulationManagementApplicationsContext, {
  SimulationManagementApplicationsType,
} from '../contexts/management-application/SimulationManagementApplicationsContext'
import SimulationInterfaceSettingsContext, {
  SimulationInterfaceSettingsContextType,
} from '../contexts/simulation-interface-customization/SimulationInterfaceSettingsContext'

export function useSimulationSocketContext(): SimulationSocketContextType {
  const context = useContext(SimulationSocketContext)
  if (!context) {
    throw new Error('useSocketContext must be used within a SimulationSocketProvider')
  }
  return context
}

export function useUserContext(): FrontUser {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}

export function useSimulationManagementContext(): SimulationManagementType {
  const context = useContext(SimulationManagementContext)
  if (!context) {
    throw new Error('useSimulationManagementContext must be used within a SimulationManagementProvider')
  }
  return context
}

export function useSimulationManagementApplicationsContext(): SimulationManagementApplicationsType {
  const context = useContext(SimulationManagementApplicationsContext)
  if (!context) {
    throw new Error('useSimulationManagementApplicationsContext must be used within a SimulationManagementProvider')
  }
  return context
}

export function useJoinedSimulationsContext(): JoinedSimulationsType | undefined {
  const context = useContext(JoinedSimulationsContext)
  if (context) return context
}

export function useSimulationInterfaceSettingsContext(): SimulationInterfaceSettingsContextType {
  return useContext(SimulationInterfaceSettingsContext)
}
