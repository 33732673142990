import { deleteR, get, post } from '../../rest-utils'
import ApiResources from '../../../api-resources'
import { APIScenario } from '../models/APIScenario'

export async function getScenariosByClientUsername(clientUsername: string): Promise<APIScenario[]> {
  return get<APIScenario[]>(
    `simulation-conf/scenario?clientUsername=${clientUsername}`,
    ApiResources.api_simulation_conf_url,
  )
}

export async function postScenario(scenario: APIScenario): Promise<APIScenario> {
  return post('simulation-conf/scenario', scenario, ApiResources.api_simulation_conf_url)
}

export async function deleteScenario(scenarioId: number): Promise<APIScenario> {
  return deleteR<APIScenario>(`simulation-conf/scenario`, { id: scenarioId }, ApiResources.api_simulation_conf_url)
}
