import { PaletteOptions } from '@mui/material'

export const darkPalette: PaletteOptions = {
  primary: {
    main: '#a9b8e8',
    light: '#26556A',
    dark: '#3fbfd9',
  },
  secondary: {
    main: '#a9b8e8',
    light: '#444a73',
    dark: '#2f334d',
  },
  appbar: {
    primary: '#191a2a',
    text: '#a9b8e8',
  },
  info: {
    main: '#3fbfd9',
  },
  background: {
    default: '#222436',
    paper: '#191a2a',
  },
  text: {
    primary: '#fff',
    secondary: '#7DD6E8',
  },
}
