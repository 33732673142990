import { Box } from '@mui/material'
import React from 'react'

export function GenericResponsiveListComponent<T>(props: {
  tList: T[]
  subComponentWidth: number
  subComponent: (value: T) => JSX.Element
}) {
  const { tList, subComponentWidth, subComponent } = props
  const [width, setWidth] = React.useState(window.innerWidth)
  const theoricCardsPerRow = Math.floor(width / subComponentWidth)
  const linesQty = Math.ceil(tList.length / theoricCardsPerRow)
  const cardsPerRow = Math.ceil(tList.length / linesQty)
  const lines = []

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // display width / 160 cards per row
  for (let i = 0; i < tList.length; i += cardsPerRow) {
    lines.push(tList.slice(i, i + cardsPerRow))
  }

  return (
    <>
      {lines.map((line, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {line.map((value) => subComponent(value))}
        </Box>
      ))}
    </>
  )
}
