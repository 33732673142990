import APIresources from '../api-resources'
import { Amplify } from 'aws-amplify'

async function getResponse(resource: string, url?: string) {
  // console.log(`url : ${resources.api_base_url}/${resource}`)
  const userSession = await Amplify.Auth.currentSession()
  const token = userSession.getAccessToken().getJwtToken()
  const apiUrl = url ? url : APIresources.api_user_url
  const response = await fetch(`${apiUrl}/${resource}`, {
    method: 'GET',
    headers: {
      Authorization: `${token}`,
    },
  })
  // console.log(response)
  return response
}

export async function get<R>(resource: string, url?: string) {
  const response = await getResponse(resource, url)

  if (response.ok) {
    return (await response.json()) as Promise<R>
  } else {
    return Promise.reject<R>(response.status)
  }
}

async function postResponse(resource: string, body: string | object | JSON, url?: string, isAuth?: boolean) {
  if (isAuth === undefined) isAuth = true

  if (isAuth) {
    const userSession = await Amplify.Auth.currentSession()
    const token = userSession.getAccessToken().getJwtToken()
    const apiUrl = url ? url : APIresources.api_user_url
    return fetch(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  } else {
    const apiUrl = url ? url : APIresources.api_user_url
    return fetch(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  }
}

export async function post<R>(resource: string, body: object, url?: string, isAuth?: boolean) {
  const response = await postResponse(resource, body, url, isAuth)

  if (response.ok) {
    return (await response.json()) as Promise<R>
  } else {
    return Promise.reject<R>({
      status: response.status,
      body: await response
        .json()
        .then((value) => {
          return value
        })
        .catch((reason) => {
          return reason
        }),
    })
  }
}

async function putResponse(resource: string, body: string | object | JSON, url?: string) {
  const userSession = await Amplify.Auth.currentSession()
  const token = userSession.getAccessToken().getJwtToken()
  const apiUrl = url ? url : APIresources.api_user_url
  const response = await fetch(`${apiUrl}/${resource}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      Authorization: `${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
  return response
}

export async function put<R>(resource: string, body: object | JSON, url?: string) {
  const response = await putResponse(resource, body, url)

  if (response.ok) {
    return (await response.json()) as Promise<R>
  } else {
    return Promise.reject<R>({
      status: response.status,
      body: await response
        .json()
        .then((value) => {
          return value
        })
        .catch((reason) => {
          return reason
        }),
    })
  }
}

async function deleteResponse(resource: string, body: string | object | JSON, url?: string) {
  const userSession = await Amplify.Auth.currentSession()
  const token = userSession.getAccessToken().getJwtToken()
  const apiUrl = url ? url : APIresources.api_user_url
  const response = await fetch(`${apiUrl}/${resource}`, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: {
      Authorization: `${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
  return response
}

export async function deleteR<R>(resource: string, body: string | object | JSON, url?: string) {
  const response = await deleteResponse(resource, body, url)

  if (response.ok) {
    return response.status as unknown as Promise<R>
  } else {
    return Promise.reject<R>(response.status)
  }
}
