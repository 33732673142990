export default {
  home: {
    title: 'Simulation home',
    description: {
      participation: 'In this page, you can participate in one of the simulations you have been accepted to.',
      application: 'In this page, you can apply to a simulation.',
      creation: 'In this page, you can create and manage your simulations.',
    },
    joined: {
      titleRunning: 'Running simulations',
      titleIncoming: 'Incoming simulations',
      findcard: 'Find a new simulation',
      text_actually: 'This simulation is actually',
      available: 'available',
      unavailable: 'unavailable',
      starting: 'starting',
      participate: 'Join',
      alert: {
        title: 'Unable to load simulations:',
        description: 'The user is not loaded, please try again later',
      },
      join_confirm: {
        title: 'Join simulation "{{simulationName}}"?',
        description:
          'Are you sure you want to join this simulation? \nIt will automatically connect you to the simulation audio conversation.',
        join: 'Join',
        cancel: 'Cancel',
      },
    },
    role: {
      title: 'Applications history',
      cardCurrent: 'Manage my current applications',
      cardHistory: 'View my applications history',
    },
    create: {
      title: 'Create a simulation',
      card: 'Create a new simulation',
    },
    manage: {
      card: 'Manage my simulations',
    },
  },
  published: {
    title: 'Published simulations',
    filterByTags: 'Filter by tags',
    addTag: 'Add a tag',
    filterByDate: 'Filter by date',
    viewRoles: 'View available roles',
    notFound: 'No simulations found',
    roles: {
      apply: 'Apply for this role',
      viewDetails: 'View details',
      details: {
        title: 'Role details',
      },
      applyTitle: 'Apply for the role {{role}} in the simulation {{simulation}}',
      applyDescription:
        "You are about to apply for the role of '{{role}}' in the simulation '{{simulation}}'. Please confirm your application.",
      cancel: 'Cancel',
    },
  },
  create: {
    stepper: {
      settings: 'Settings',
      channels: 'Channels configuration',
      roles: 'Roles configuration',
      review: 'Review',
      publication: 'Publication',
    },
    buttons: {
      clear: 'Clear',
      continue: 'Continue',
      return: 'Return',
      save: 'Save',
      publish: 'Publish',
    },
    settings: {
      switch: {
        new: 'New simulation',
        load: 'Load simulation',
      },
      saved: 'Saved scenarios',
      name: 'Simulation name',
      nameExistError: 'A simulation with this name already exists',
      weather: 'Weather',
      wind: 'Wind',
      tags: 'Tags',
      helperTag: 'Type the tag then press enter to add it to the list',
      edit: {
        title: 'Edit scenarios',
        close: 'Close',
        success: 'Simulation deleted successfully',
        error: 'An error occurred while deleting the simulation',
        confirm: {
          title: 'Delete scenario',
          description: 'Are you sure you want to delete this scenario?',
          cancel: 'Cancel',
          confirm: 'Confirm',
        },
      },
    },
    channels: {
      frequency: 'Frequency',
      text: 'Text',
      placeholder: 'xxx.xxx',
      new: 'Create a new frequency',
      delete: {
        title: 'Delete frequency',
        description: 'Are you sure you want to delete this frequency?',
      },
    },
    roles: {
      name: 'Role name',
      roleType: 'Role type',
      gender: 'Gender',
      frequencies: 'Frequencies',
      ageMin: 'Minimum age',
      ageMax: 'Maximum age',
      age: 'Age',
      ageMinHelper: 'Minimum age of the role is 18',
      ageMaxHelper: 'Maximum age of the role is 80',
      languages: 'Languages',
      accents: 'Accents',
      new: 'Create a new role',
      delete: 'Delete role',
      deleteMessage: 'Are you sure you want to delete this role?',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    review: {
      scenarioAlreadyExists: 'A simulation with this name already exists',
      scenarioSaved: 'Simulation saved',
      scenarioSavedError: 'An error occurred while saving the simulation',
    },
    publish: {
      title: 'Choose the date and time of the simulation',
      start: 'Start',
      end: 'End',
      success: 'Simulation published successfully',
      error: 'An error occurred while publishing the simulation',
    },
  },
  roles: {
    title: 'My roles',
    notFound: 'No roles found',
  },
  history: {
    title: 'My applications history',
    notFound: 'No applications found',
  },
  running: {
    alert: {
      title: 'Unable to connect to the simulation:',
      description:
        'The browser does not have access to your microphone. Please allow access to the microphone to be able to participate in the simulation.',
    },
    status: {
      service: {
        title: 'Service connection status',
        connected: 'Connected',
        disconnected: 'Disconnected',
      },
      audioConnection: {
        title: 'Audio connection status',
        connected: 'Connected',
        disconnected: 'Disconnected',
        connecting: 'Connecting',
        reconnecting: 'Reconnecting',
      },
    },
    settings: {
      inputDevice: 'Input device',
      outputDevice: 'Output device',
      volume: 'Volume',
    },
    privateCall: {
      title: 'Private call',
      selectSomeone: 'Select someone to start a private call:',
      selectSomeoneLabel: 'Select someone',
      receivingCall: 'Receiving call from {{caller}}',
      calling: 'Calling...',
      status: {
        NO_CALL: 'No call',
        CALLING: 'Calling',
        IN_CALL: 'In call',
        RECEIVING_CALL: 'Receiving call',
      },
      callList: {
        title: 'Call list',
        incomingCalls: 'Incoming calls',
        pausedCalls: 'Calls on hold',
      },
    },
    loadingDialog: {
      title: 'Loading simulation data...',
      goBack: 'Go back',
    },
    customizationSettings: {
      title: 'Interface customization',
      preview: 'Preview interface:',
      frequencyBlock: {
        title: 'Frequency block settings',
        width: 'Width of the frequency block (percent of window width):',
        alignment: 'Frequency block alignment:',
        left: 'Left',
        right: 'Right',
        center: 'Center',
        frequenciesPerLine: '{{count}} frequencies per line',
      },
      privateCall: {
        title: 'Private call settings',
        alignment: 'Private call alignment:',
        topLeft: 'Top left',
        topRight: 'Top right',
        bottomLeft: 'Bottom left',
        bottomRight: 'Bottom right',
      },
    },
  },
  manage: {
    title: 'My simulations',
    notFound: 'No simulations found',
    card: 'Manage the applications',
    validate: 'Validate',
  },
  validate: {
    title: 'Validate the applications',
    description: 'Are you sure you want to validate the simulation {{simulation}} in its current state?',
    success: 'Successfully validated the simulation {{simulation}}',
    error: 'Failed to validate the simulation {{simulation}}',
  },
  applications: {
    title: 'Roles applications',
    noRequests: 'No request',
    requests: ' request(s)', //keep the space before the word
    accepted: 'Accepted',
    accept: {
      title: 'Accept the application',
      description:
        'Are you sure you want to accept {{user}} for the role of {{role}} in the simulation {{simulation}}? This action will automatically reject all other applications for this role.',
      success: 'Successfully accepted {{user}}',
      error: 'Failed to accept {{user}}',
    },
    reject: {
      title: 'Reject the application',
      description:
        'Are you sure you want to reject {{user}} for the role of {{role}} in the simulation {{simulation}}?',
      success: 'Successfully rejected {{user}}',
      error: 'Failed to reject {{user}}',
    },
  },
}
