import { Box, Divider, Typography, useTheme } from '@mui/material'
import React from 'react'
import CardButtonBox from '../../components/mui/cardbutton/CardButtonBox'
import { useNavigate } from 'react-router-dom'
import CardButtonAction from '../../components/mui/cardbutton/CardButtonAction'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import AdminProtection from '../../components/admin/AdminProtection'
import { useTranslation } from 'react-i18next'
import { AssignmentInd } from '@mui/icons-material'
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket'
import JoinedSimulations from './joined/JoinedSimulations'

export const TitleDivider = () => {
  const theme = useTheme()
  return (
    <Divider
      color={theme.palette.primary.main}
      sx={{
        width: '300px',
        margin: '0 auto',
        marginBottom: 2,
      }}
    />
  )
}

const SimulationHome = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        {t('simulations.home.title')}
      </Typography>

      {/*<CardButtonGrid*/}
      {/*  cardButtonsList={[*/}
      {/*    {*/}
      {/*      title: t('pageNames.simulation.participation'),*/}
      {/*      description: t('simulations.home.description.participation'),*/}
      {/*      onClick: () => navigate('/profile'),*/}
      {/*    },*/}
      {/*    {*/}
      {/*      title: t('pageNames.simulation.application'),*/}
      {/*      description: t('simulations.home.description.application'),*/}
      {/*      onClick: () => navigate('/account'),*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*/>*/}
      {/*<CardButtonGrid*/}
      {/*  cardButtonsList={[*/}
      {/*    {*/}
      {/*      title: t('pageNames.simulation.creation'),*/}
      {/*      description: t('simulations.home.description.creation'),*/}
      {/*      onClick: () => navigate('/simulations'),*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*/>*/}

      <JoinedSimulations />

      <Box marginBottom={4} />

      <Typography variant="h4" component="div" gutterBottom>
        {t('simulations.home.role.title')}
      </Typography>

      <TitleDivider />

      <CardButtonBox>
        <CardButtonAction
          description={t('simulations.home.role.cardCurrent')}
          sx={{
            width: '250px',
            borderRadius: 5,
            mb: 3,
            m: 2,
            height: '150px',
            boxShadow: 5,
            '&:hover': {
              boxShadow: 15,
            },
          }}
          icon={<AssignmentInd sx={{ fontSize: 50 }} />}
          cardActionProps={{ sx: { height: '100%' } }}
          onClick={() => {
            navigate('/simulations/roles')
          }}
        />
        <CardButtonAction
          description={t('simulations.home.role.cardHistory')}
          sx={{
            width: '250px',
            height: '150px',
            borderRadius: 5,
            mb: 3,
            m: 2,
            boxShadow: 5,
            '&:hover': {
              boxShadow: 15,
            },
          }}
          icon={<AssignmentInd sx={{ fontSize: 50 }} />}
          cardActionProps={{ sx: { height: '100%' } }}
          onClick={() => {
            navigate('/simulations/history')
          }}
        />
      </CardButtonBox>

      <Box marginBottom={4} />

      <AdminProtection mode="hide">
        <Typography variant="h4" component="div" gutterBottom>
          {t('simulations.home.create.title')}
        </Typography>
        <TitleDivider />
        <CardButtonBox>
          <CardButtonAction
            description={t('simulations.home.create.card')}
            sx={{
              width: '250px',
              height: '150px',
              borderRadius: 5,
              boxShadow: 5,
              m: 2,
              '&:hover': {
                boxShadow: 15,
              },
            }}
            icon={<AddCircleOutlineOutlinedIcon sx={{ fontSize: 50 }} />}
            cardActionProps={{ sx: { height: '100%' } }}
            onClick={() => {
              navigate('/simulations/create')
            }}
          />
          <CardButtonAction
            description={t('simulations.home.manage.card')}
            sx={{
              width: '250px',
              height: '150px',
              borderRadius: 5,
              boxShadow: 5,
              m: 2,
              '&:hover': {
                boxShadow: 15,
              },
            }}
            icon={<AirplaneTicketIcon sx={{ fontSize: 50 }} />}
            cardActionProps={{ sx: { height: '100%' } }}
            onClick={() => {
              navigate('/simulations/manage')
            }}
          />
        </CardButtonBox>
      </AdminProtection>
    </>
  )
}

export default SimulationHome
