export default {
  home: {
    description: {
      participation: 'Dans cette page, vous pouvez participer à une des simulations où vous avez été accepté.',
      application: 'Dans cette page, vous pouvez candidater à une simulation.',
      creation: 'Dans cette page, vous pouvez créer et gérer vos simulations.',
    },
    title: 'Accueil des simulations',
    joined: {
      titleRunning: 'Simulations en cours',
      titleIncoming: 'Prochaines simulations',
      findcard: 'Trouver une nouvelle simulation',
      text_actually: 'Cette simulation est actuellement',
      available: 'disponible',
      unavailable: 'indisponible',
      starting: 'en cours de démarrage',
      participate: 'Rejoindre',
      alert: {
        title: 'Impossible de charger les simulations :',
        description: "L'utilisateur n'est pas chargé, réessayez plus tard",
      },
      join_confirm: {
        title: 'Rejoindre la simulation "{{simulationName}}" ?',
        description:
          'Êtes-vous sûr de vouloir rejoindre cette simulation ? \nCela vous connectera automatiquement à la conversation audio de la simulation.',
        join: 'Rejoindre',
        cancel: 'Annuler',
      },
    },
    role: {
      title: 'Historique des candidatures',
      cardCurrent: 'Gérer mes candidatures en cours',
      cardHistory: 'Voir mon historique de candidatures',
    },
    create: {
      title: 'Créer une simulation',
      card: 'Créer une nouvelle simulation',
    },
    manage: {
      card: 'Gérer mes simulations',
    },
  },
  published: {
    title: 'Simulations publiées',
    filterByTags: 'Filtrer par mots-clés',
    addTag: 'Ajouter un mot-clé',
    filterByDate: 'Filtrer par date',
    viewRoles: 'Voir les rôles disponibles',
    notFound: 'Aucune simulation trouvée',
    roles: {
      apply: 'Postuler pour ce rôle',
      viewDetails: 'Voir les détails',
      details: {
        title: 'Détails du rôle',
      },
      applyTitle: 'Postuler pour le rôle {{role}} dans la simulation {{simulation}}',
      applyDescription:
        "Vous êtes sur le point de postuler pour le rôle de '{{role}}' dans la simulation '{{simulation}}'. Veuillez confirmer votre candidature.",
      cancel: 'Annuler',
    },
  },
  create: {
    stepper: {
      settings: 'Paramètres',
      channels: 'Configuration des fréquences',
      roles: 'Configuration des rôles',
      review: 'Résumé',
      publication: 'Publication',
    },
    buttons: {
      clear: 'Effacer',
      continue: 'Continuer',
      return: 'Retour',
      save: 'Sauvegarder',
      publish: 'Publier',
    },
    settings: {
      switch: {
        new: 'Nouvelle simulation',
        load: 'Charger une simulation',
      },
      saved: 'Scénarios sauvegardés',
      name: 'Nom de la simulation',
      nameExistError: 'Une simulation avec ce nom existe déjà',
      weather: 'Météo',
      wind: 'Vent',
      tags: 'Mots-clés',
      helperTag: "Entrez le mot-clé avant d'appuyer sur entrée pour l'ajouter à la liste",
      edit: {
        title: 'Editer les scénarios',
        close: 'Fermer',
        success: 'La simulation a été supprimée avec succès',
        error: 'Erreur lors de la suppression de la simulation',
        confirm: {
          title: 'Supprimer le scénario',
          description: 'Êtes-vous sûr de vouloir supprimer ce scénario ?',
          cancel: 'Annuler',
          confirm: 'Confirmer',
        },
      },
    },
    channels: {
      frequency: 'Fréquence',
      text: 'Texte',
      placeholder: 'xxx.xxx',
      new: 'Créer une nouvelle fréquence',
      delete: {
        title: 'Supprimer la fréquence',
        description: 'Êtes-vous sûr de vouloir supprimer cette fréquence ?',
      },
    },
    roles: {
      name: 'Nom du rôle',
      roleType: 'Type du rôle',
      gender: 'Genre',
      frequencies: 'Fréquences',
      ageMin: 'Age minimum',
      ageMax: 'Age maximum',
      age: 'Age',
      ageMinHelper: "L'age minimum du rôle est 18",
      ageMaxHelper: "L'age maximum du rôle est 80",
      languages: 'Langues',
      accents: 'Accents',
      new: 'Créer un nouveau rôle',
      delete: 'Supprimer le rôle',
      deleteMessage: 'Êtes-vous sûr de vouloir supprimer ce rôle ?',
      confirm: 'Confirmer',
      cancel: 'Annuler',
    },
    review: {
      scenarioAlreadyExists: 'Une simulation avec ce nom existe déjà',
      scenarioSaved: 'Simulation sauvegardée',
      scenarioSavedError: 'Erreur lors de la sauvegarde de la simulation',
    },
    publish: {
      title: "Choisir la date et l'heure de la simulation",
      start: 'Début',
      end: 'Fin',
      success: 'Simulation published successfully',
      error: 'An error occurred while publishing the simulation',
    },
  },
  roles: {
    title: 'Mes rôles',
    notFound: 'Aucun rôle trouvé',
  },
  history: {
    title: 'Historique des candidatures',
    notFound: 'Aucune candidature trouvée',
  },
  running: {
    alert: {
      title: 'Impossible de se connecter à la simulation :',
      description:
        "Le navigateur n'a pas accès à votre microphone. Veuillez autoriser l'accès au microphone pour pouvoir participer à la simulation.",
    },
    status: {
      service: {
        title: 'Statut du service',
        connected: 'Connecté',
        disconnected: 'Déconnecté',
      },
      audioConnection: {
        title: 'État des connexions audio',
        connected: 'Connecté',
        disconnected: 'Déconnecté',
        connecting: 'Connexion...',
        reconnecting: 'Reconnexion...',
      },
    },
    settings: {
      inputDevice: 'Périphérique d’entrée',
      outputDevice: 'Périphérique de sortie',
      volume: 'Volume',
    },
    privateCall: {
      title: 'Appel privé',
      selectSomeone: 'Sélectionnez quelqu’un pour démarrer un appel privé :',
      selectSomeoneLabel: 'Sélectionnez quelqu’un',
      receivingCall: 'Appel entrant de {{caller}}',
      calling: 'Appel en cours',
      status: {
        NO_CALL: 'Pas d’appel',
        CALLING: 'Appel en cours',
        IN_CALL: 'En appel',
        RECEIVING_CALL: 'Appel entrant',
      },
      callList: {
        title: 'Liste des appels',
        incomingCalls: 'Appels entrants',
        pausedCalls: 'Appels en attente',
      },
    },
    loadingDialog: {
      title: 'Chargement des données de la simulation...',
      goBack: 'Retour',
    },
    customizationSettings: {
      title: 'Paramètres de personnalisation',
      preview: 'Aperçu de l’interface :',
      frequencyBlock: {
        title: 'Paramètres du bloc de fréquences',
        width: 'Largeur du bloc de fréquences (pourcentage de la largeur de la fenêtre) :',
        alignment: 'Alignement du bloc de fréquences :',
        left: 'Gauche',
        right: 'Droite',
        center: 'Centre',
        frequenciesPerLine: '{{count}} fréquences par ligne',
      },
      privateCall: {
        title: 'Paramètres des appels privés',
        alignment: 'Alignement des appels privés :',
        topLeft: 'En haut à gauche',
        topRight: 'En haut à droite',
        bottomLeft: 'En bas à gauche',
        bottomRight: 'En bas à droite',
      },
    },
  },
  manage: {
    title: 'Mes simulations',
    notFound: 'Aucune simulation trouvée',
    card: 'Gérer les candidatures',
    validate: 'Valider',
  },
  validate: {
    title: 'Valider les candidatures',
    description: 'Êtes-vous sûr de vouloir valider la simulation {{simulation}} dans son état actuel ?',
  },
  applications: {
    title: 'Candidatures',
    noRequests: 'Aucune candidature',
    requests: ' candidature(s)', //keep the space before the word
    accepted: 'Acceptée',
    accept: {
      title: 'Accepter la candidature',
      description:
        'Êtes-vous sûr de vouloir accepter {{user}} pour le rôle de {{role}} dans la simulation {{simulation}} ? Cette action rejettera automatiquement toutes les autres candidatures pour ce rôle.',
      success: 'Candidature de {{user}} acceptée',
      error: 'Erreur lors de l’acceptation de la candidature de {{user}}',
    },
    reject: {
      title: 'Rejeter la candidature',
      description:
        'Êtes-vous sûr de vouloir rejeter {{user}} pour le rôle de {{role}} dans la simulation {{simulation}} ?',
      success: 'Candidature de {{user}} rejetée',
      error: 'Erreur lors du rejet de la candidature de {{user}}',
    },
  },
}
