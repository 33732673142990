import { PaletteMode, ThemeOptions } from '@mui/material'
import { lightPalette } from './lightPalette'
import { darkPalette } from './darkPalette'

declare module '@mui/material/styles' {
  interface PaletteOptions {
    appbar?: {
      primary?: string
      secondary?: string
      text?: string
    }
  }
}

function getDesignTokens(mode: PaletteMode): ThemeOptions {
  return {
    palette: {
      mode,
      ...(mode.toString() === 'light' && lightPalette),
      ...(mode.toString() === 'dark' && darkPalette),
      success: {
        main: '#4caf50',
        light: '#81c784',
        dark: '#388e3c',
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          ...(mode.toString() === 'dark' && {
            root: {
              minWidth: 0,
              '&:hover': {
                color: '#fff',
              },
            },
            contained: {
              color: '#fff',
            },
            containedError: {
              backgroundColor: '#e72616',
              '&:hover': {
                backgroundColor: '#b71f11',
              },
            },
            containedInfo: {
              backgroundColor: '#7d94ec',
              '&:hover': {
                backgroundColor: '#5c6bc0',
              },
            },
          }),
          ...(mode.toString() === 'light' && {
            root: {
              minWidth: 0,
            },
            contained: {
              color: '#fff',
            },
            containedError: {
              backgroundColor: '#e72616',
              '&:hover': {
                backgroundColor: '#b71f11',
              },
            },
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            '&:before': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          ...(mode.toString() === 'light' && {
            colorSuccess: {
              color: '#fff',
            },
          }),
        },
      },
    },
  }
}

export default getDesignTokens
