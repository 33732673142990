import { Box, Step, StepLabel, Stepper } from '@mui/material'
import React from 'react'
import MediaQuery from 'react-responsive'
import { useTranslation } from 'react-i18next'

interface ConferenceCreationStepperProps {
  step: number
}

function ConferenceCreationStepper(props: ConferenceCreationStepperProps) {
  const { step } = props
  const { t } = useTranslation()
  const steps = [
    t('simulations.create.stepper.settings'),
    t('simulations.create.stepper.channels'),
    t('simulations.create.stepper.roles'),
    t('simulations.create.stepper.review'),
    t('simulations.create.stepper.publication'),
  ]

  return (
    <>
      <MediaQuery minWidth={540}>
        <Box sx={{ width: '450px', m: 'auto', marginBottom: '2em' }}>
          <Stepper activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </MediaQuery>
      <MediaQuery maxWidth={539}>
        <Box sx={{ ml: 5, marginBottom: '2em' }}>
          <Stepper activeStep={step} orientation={'vertical'}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </MediaQuery>
    </>
  )
}

export default ConferenceCreationStepper
