import { createContext } from 'react'
import Candidate from '../../pages/simulation/management/CandidatesType'
import { APISimulationRequest } from '../../rest/simulation/models/APISimulationRequest'
import { APIVoluntaryUser } from '../../rest/simulation/models/APIVoluntaryUser'

export type SimulationManagementApplicationsType = {
  candidates: Candidate[] | undefined
  simulationRequest: APISimulationRequest | undefined
  updateVoluntaryUser: (voluntaryUser: APIVoluntaryUser) => void
  refreshCandidates: () => void
}

const SimulationManagementApplicationsContext = createContext<SimulationManagementApplicationsType | undefined>(
  undefined,
)

export default SimulationManagementApplicationsContext
