import { Typography } from '@mui/material'

function NotFound() {
  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" component="div" gutterBottom>
        Page not found
      </Typography>
    </>
  )
}

export default NotFound
