import { Button, Card, CardActions, CardContent, Typography, useTheme } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfirm } from 'material-ui-confirm'
import { useNavigate } from 'react-router-dom'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { APISimulationRole } from '../../../rest/simulation/models/APISimulationRole'
import { APISimulationRequest } from '../../../rest/simulation/models/APISimulationRequest'
import { APIActiveSimulation } from '../../../rest/simulation/models/APIActiveSimulation'

export type JoinedSimulationCardProps = {
  simulation: APISimulationRequest | APIActiveSimulation
  role: APISimulationRole
  status: APIActiveSimulation['status'] | APISimulationRequest['status']
}

type SimulationAvailability = 'AVAILABLE' | 'UNAVAILABLE' | 'STARTING'

const JoinedSimulationCard = (props: JoinedSimulationCardProps) => {
  const { simulation, role, status } = props
  const { t } = useTranslation()
  const [availability, setAvailability] = useState<SimulationAvailability>('UNAVAILABLE')
  const confirm = useConfirm()
  const navigate = useNavigate()
  const theme = useTheme()

  useEffect(() => {
    if (status === 'ACTIVE') {
      setAvailability('AVAILABLE')
    } else if (status === 'PENDING') {
      setAvailability('STARTING')
    } else {
      setAvailability('UNAVAILABLE')
    }
  }, [status])

  return (
    <Card
      key={simulation.id}
      variant={'outlined'}
      sx={{
        minWidth: '250px',
        height: '220px',
        borderRadius: 3,
        boxShadow: 5,
        m: 2,
        '&:hover': {
          boxShadow: 15,
        },
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {new Date(simulation.startDate).toLocaleDateString() +
            ' ' +
            new Date(simulation.startDate).toLocaleTimeString()}
        </Typography>
        <Typography variant="h5" component="div">
          {simulation.simulation.name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {role.name}
        </Typography>
        <Typography variant="body2">
          {t('simulations.home.joined.text_actually')} <br />
          {availability === 'UNAVAILABLE' ? (
            <CancelOutlinedIcon
              color="error"
              sx={{
                verticalAlign: 'middle',
              }}
            />
          ) : availability === 'STARTING' ? (
            <ErrorOutlineOutlinedIcon
              color="warning"
              sx={{
                verticalAlign: 'middle',
              }}
            />
          ) : (
            <CheckCircleOutlinedIcon
              color="success"
              sx={{
                verticalAlign: 'middle',
              }}
            />
          )}{' '}
          <b
            style={{
              color:
                availability === 'UNAVAILABLE'
                  ? theme.palette.error.main
                  : availability === 'STARTING'
                  ? theme.palette.warning.main
                  : theme.palette.success.main,
            }}
          >
            {availability === 'UNAVAILABLE'
              ? t('simulations.home.joined.unavailable')
              : availability === 'STARTING'
              ? t('simulations.home.joined.starting')
              : t('simulations.home.joined.available')}
          </b>
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          disabled={availability !== 'AVAILABLE'}
          onClick={() => {
            confirm({
              title: t('simulations.home.joined.join_confirm.title', {
                simulationName: simulation.simulation.name,
              }),
              description: t('simulations.home.joined.join_confirm.description'),
              confirmationText: t('simulations.home.joined.join_confirm.join'),
              cancellationText: t('simulations.home.joined.join_confirm.cancel'),
            })
              .then(() => {
                navigate('/simulations/' + simulation.id)
              })
              .catch(() => {})
          }}
        >
          {t('simulations.home.joined.participate')}
        </Button>
      </CardActions>
    </Card>
  )
}

export const JoinedSimulationCardTemp = () => {
  const { t } = useTranslation()
  const confirm = useConfirm()
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Card
      variant={'outlined'}
      sx={{
        minWidth: '250px',
        height: '220px',
        borderRadius: 3,
        boxShadow: 5,
        m: 2,
        '&:hover': {
          boxShadow: 15,
        },
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()}
        </Typography>
        <Typography variant="h5" component="div">
          DAILY
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Special room
        </Typography>
        <Typography variant="body2">
          {t('simulations.home.joined.text_actually')} <br />
          <CheckCircleOutlinedIcon
            color="success"
            sx={{
              verticalAlign: 'middle',
            }}
          />{' '}
          <b
            style={{
              color: theme.palette.success.main,
            }}
          >
            {t('simulations.home.joined.available')}
          </b>
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          sx={{ minWidth: 50 }}
          onClick={() => {
            confirm({
              title: t('simulations.home.joined.join_confirm.title', {
                simulationName: 'DAILY',
              }),
              description: t('simulations.home.joined.join_confirm.description'),
              confirmationText: t('simulations.home.joined.join_confirm.join'),
              cancellationText: t('simulations.home.joined.join_confirm.cancel'),
            })
              .then(() => {
                navigate('/simulations/1')
              })
              .catch(() => {})
          }}
        >
          {t('simulations.home.joined.participate')}
        </Button>
      </CardActions>
    </Card>
  )
}

export default JoinedSimulationCard
