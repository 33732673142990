export default {
  home: 'Accueil',
  simulations: 'Simulations',
  simulation: {
    participation: 'Participer',
    application: 'Candidater',
    creation: 'Créer et gérer',
  },
  myaccount: 'Mon compte',
  myprofile: 'Mon profil',
  account: 'Compte',
  profile: 'Profil',
  admin: 'Admin',
  logout: 'Déconnexion',
}
