import { Box, Button, TextField, Typography, useTheme } from '@mui/material'
import { Masonry } from '@mui/lab'
import { useConfirm } from 'material-ui-confirm'
import ProfileInformationItem from './items/ProfileInformationItem'
import PersonalInformationItem from './items/PersonalInformationItem'
import ContactInformationItem from './items/ContactInformationItem'
import SystemInformationItem from './items/SystemInformationItem'
import OtherInformation from './items/OtherInformationItem'
import React from 'react'
import APIUser, { putUser } from '../../rest/user/APIUser'
import FormDialog from '../../components/mui/FormDialog'
import UniqueSelectChip from '../../components/mui/UniqueSelectChip'
import toast from 'react-hot-toast'
import Language from '../../rest/user/enums/Language'
import MultipleSelectChip from '../../components/mui/MultipleSelectChip'
import Accent, { getAccentsByLanguages } from '../../rest/user/enums/Accent'
import { useMediaQuery } from 'react-responsive'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface AccountFieldsProps {
  user: APIUser
  setUser: React.Dispatch<APIUser>
}

function AccountFields(props: AccountFieldsProps): React.ReactElement {
  const { user, setUser } = props
  const [value, setValue] = React.useState<string>('')
  const [values, setValues] = React.useState<string[]>([])
  const [fieldId, setFieldId] = React.useState<string>('')
  const isLaptop = useMediaQuery({ minWidth: 950 })
  const theme = useTheme()
  const mode = localStorage.getItem('mode')
  const confirm = useConfirm()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()

  const handleClose = () => {
    setValue('')
    setValues([])
    setOpen(false)
  }

  const handleConfirm = () => {
    let updatedUser
    if (fieldId === 'languages') {
      const languages = values
      const accents: Accent[] = []
      for (const accent of user.accents) {
        accents.push(accent)
      }
      updatedUser = { ...user, languages: languages, accents: accents }
    } else if (fieldId === 'gender') {
      const gender = value.toUpperCase()
      updatedUser = { ...user, gender: gender }
    } else if (fieldId === 'accents') {
      const accents = values.map((accent) => accent)
      updatedUser = { ...user, accents: accents }
    } else if (fieldId === 'nickname' && value.toUpperCase() === 'ONESKY') {
      updatedUser = { ...user, nickname: '✈✈✈✈✈' }
    } else {
      updatedUser = { ...user, [fieldId]: value }
    }
    handleClose()
    putUser(updatedUser)
      .then((u) => {
        if (fieldId === 'calendarAvailability') {
          handleClose()
        }
        setUser(u)
        toast.success('Successfully updated user')
      })
      .catch((err) => {
        const status = err.status
        const body = err.body
        if (status === 400) {
          if (body.violations) {
            const violationsMessages = body.violations.map((violation: typeof body.violations[0]) => violation.message)
            toast.error(() => (
              <Box textAlign={'center'}>
                <Typography variant={'body1'}>{'Failed to update user\n'}</Typography>
                <Typography variant={'body1'} color={'red'}>
                  {' '}
                  <b>{'- ' + violationsMessages.join('\n- ')} </b>
                </Typography>
              </Box>
            ))
          } else {
            toast.error('Failed to update user: \nStatus: ' + status)
          }
        } else {
          toast.error('Failed to update user: \nStatus: ' + status)
        }
      })
  }

  function confirmEnable() {
    confirm({
      title: t('account.confirm.enable.title'),
      description: t('account.confirm.enable.content'),
      confirmationText: t('button.enable'),
      cancellationText: t('button.cancel'),
    }).then(() => {
      user.status = 'ACTIVE'
      putUser(user)
        .then(() => {
          toast.success(t('account.confirm.enable.success', { username: user.username }))
          navigate('/admin/account-list')
        })
        .catch(() => {
          toast.error(t('account.confirm.enable.success', { username: user.username }))
        })
    })
  }

  function EnableButton() {
    return (
      <Button variant={'contained'} color={'success'} sx={{ mb: 4, color: 'white' }} onClick={confirmEnable}>
        {t('account.enable')}
      </Button>
    )
  }

  return (
    <>
      <FormDialog
        title={t('account.formDialog.edit') + ' ' + t(`account.formDialog.fields.${fieldId ? fieldId : 'username'}`)}
        content={
          fieldId === 'age' ? (
            <TextField
              id={'age'}
              type={'number'}
              InputProps={{ inputProps: { min: 18, max: 100 } }}
              label={t(`account.formDialog.fields.${fieldId}`)}
              value={value}
              onChange={(event) => setValue(event.target.value)}
              fullWidth
              margin={'normal'}
            />
          ) : fieldId === 'phoneNumber' ? (
            <PhoneInput
              autoFormat
              alwaysDefaultMask={false}
              country={'fr'}
              value={value.substring(1)}
              onChange={(phone) => setValue('+' + phone)}
              inputStyle={{
                width: '100%',
                backgroundColor: 'transparent',
                color: theme.palette.text.primary,
                borderColor: theme.palette.text.secondary,
              }}
              containerStyle={{
                marginTop: '10px',
              }}
              disableDropdown
            />
          ) : fieldId === 'gender' ? (
            <UniqueSelectChip
              list={['MALE', 'FEMALE', 'OTHER']}
              formattedList={(g) => t(`gender.${g}`)}
              label={t(`account.formDialog.fields.${fieldId}`)}
              item={value === '' ? 'MALE' : value}
              formattedItem={(g) => t(`gender.${g}`)}
              setItem={setValue}
            />
          ) : fieldId === 'languages' ? (
            <MultipleSelectChip
              list={Object.values(Language)}
              formattedList={(language) => t(`languages.${language}`)}
              label={t(`account.formDialog.fields.${fieldId}`)}
              items={values}
              formattedItem={(language) => t(`languages.${language}`)}
              setItems={(items: string[]) => setValues(items)}
              material
            />
          ) : fieldId === 'accents' ? (
            <MultipleSelectChip
              list={getAccentsByLanguages(user.languages)}
              formattedList={(acc) => t(`accents.${acc}`)}
              label={t(`account.formDialog.fields.${fieldId}`)}
              items={values}
              formattedItem={(acc) => t(`accents.${acc}`)}
              setItems={setValues}
              material
            />
          ) : (
            <TextField
              id={fieldId}
              value={value}
              label={t(`account.formDialog.fields.${fieldId ? fieldId : 'username'}`)}
              onChange={(event) => setValue(event.target.value)}
              fullWidth
              margin={'normal'}
            />
          )
        }
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        onCancel={handleClose}
        onConfirm={handleConfirm}
        sx={{
          '& .special-label': {
            color: theme.palette.text.primary,
            backgroundColor: mode === '"dark"' ? '#376276' : theme.palette.background.paper,
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Masonry columns={isLaptop ? 2 : 1} spacing={2} sx={{ mt: 3 }}>
          <ProfileInformationItem
            user={user}
            setUser={setUser}
            setField={(fid) => {
              setOpen(true)
              setFieldId(fid)
            }}
          />
          <PersonalInformationItem
            user={user}
            setUser={setUser}
            setField={(fid: string) => {
              setOpen(true)
              setFieldId(fid)
            }}
          />
          <ContactInformationItem
            user={user}
            setUser={setUser}
            setField={(fid: string) => {
              setOpen(true)
              setFieldId(fid)
            }}
          />
          <SystemInformationItem user={user} setUser={setUser} />
        </Masonry>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Masonry columns={1} spacing={2} sx={{ mb: 2 }}>
          <OtherInformation
            user={user}
            setUser={setUser}
            setField={(fid: string) => {
              setOpen(true)
              setFieldId(fid)
            }}
          />
        </Masonry>
      </Box>
      {user.status === 'ARCHIVED' && <EnableButton />}
    </>
  )
}

export default AccountFields
