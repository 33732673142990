import React, { useContext } from 'react'
import { Button, Card, Divider, PasswordField, TextField } from '@aws-amplify/ui-react'
import { Navigate, useNavigate } from 'react-router-dom'
import MultipleSelectChip from '../../components/mui/MultipleSelectChip'
import Language from '../../rest/user/enums/Language'
import Accent, { getAccentsByLanguages } from '../../rest/user/enums/Accent'
import UniqueSelectChip from '../../components/mui/UniqueSelectChip'
import Box from '@mui/material/Box'
import { postUser } from '../../rest/user/APIUser'
import { CircularProgress, useTheme } from '@mui/material'
import toast from 'react-hot-toast'
import PhoneInput from 'react-phone-input-2'
import UserContext from '../../contexts/user/UserContext'
import { ColorModeContext } from '../../App'
import NavBar from '../../components/global/NavBar'
import { useTranslation } from 'react-i18next'

const defaultValues = {
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
  nickname: '',
  phoneNumber: '',
  age: 0,
  gender: 'OTHER',
  languages: [] as Language[],
  accents: [] as Accent[],
}

const defaultErrors = {
  username: { check: false, message: 'There is an error' },
  email: { check: false, message: 'There is an error' },
  password: { check: false, message: 'There is an error' },
  confirmPassword: { check: false, message: 'There is an error' },
  firstName: { check: false, message: 'There is an error' },
  lastName: { check: false, message: 'There is an error' },
  nickname: { check: false, message: 'There is an error' },
  age: { check: false, message: 'There is an error' },
  phoneNumber: { check: false, message: 'There is an error' },
}

function SignUp() {
  const navigate = useNavigate()
  const [formValues, setFormValues] = React.useState(defaultValues)
  const [formErrors, setFormErrors] = React.useState(defaultErrors)
  const [loading, setLoading] = React.useState(false)
  const theme = useTheme()
  const frontUser = useContext(UserContext)
  const colorTheme = localStorage.getItem('mode')
  const colorMode = useContext(ColorModeContext)
  const { t } = useTranslation()

  if (frontUser?.user) {
    return <Navigate to={'/'} />
  }

  const handleClickLogin = () => {
    navigate('/')
  }

  const formIsValid = () => {
    return (
      !formErrors.username.check &&
      formValues.username !== '' &&
      !formErrors.email.check &&
      formValues.email !== '' &&
      !formErrors.password.check &&
      formValues.password !== '' &&
      !formErrors.confirmPassword.check &&
      formValues.confirmPassword !== '' &&
      !formErrors.firstName.check &&
      formValues.firstName !== '' &&
      !formErrors.lastName.check &&
      formValues.lastName !== '' &&
      !formErrors.nickname.check &&
      formValues.nickname !== '' &&
      !formErrors.age.check &&
      formValues.age !== undefined &&
      !formErrors.phoneNumber.check &&
      formValues.phoneNumber !== '' &&
      formValues.gender !== '' &&
      formValues.languages.length > 0 &&
      formValues.accents.length > 0
    )
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    setFormValues({
      ...formValues,
      [id]: value,
    })
    if (value === '') {
      setFormErrors({
        ...formErrors,
        [id]: { check: true, message: t('home.signUpPage.errors.required') },
      })
    } else {
      setFormErrors({
        ...formErrors,
        [id]: { check: false, message: '' },
      })
    }
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    setFormValues({
      ...formValues,
      [id]: value,
    })
    if (value === '') {
      setFormErrors({
        ...formErrors,
        [id]: { check: true, message: t('home.signUpPage.errors.required') },
      })
    } else if (value.length < 8) {
      setFormErrors({
        ...formErrors,
        [id]: { check: true, message: t('home.signUpPage.errors.passwordLength') },
      })
    } else {
      setFormErrors({
        ...formErrors,
        [id]: { check: false, message: '' },
      })
    }
  }

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    setFormValues({
      ...formValues,
      [id]: value,
    })
    if (value === '') {
      setFormErrors({
        ...formErrors,
        [id]: { check: true, message: t('home.signUpPage.errors.required') },
      })
    } else if (value !== formValues.password) {
      setFormErrors({
        ...formErrors,
        [id]: { check: true, message: t('home.signUpPage.errors.passwordMatch') },
      })
    } else {
      setFormErrors({
        ...formErrors,
        [id]: { check: false, message: '' },
      })
    }
  }

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target
    if (value === '') {
      setFormValues({
        ...formValues,
        [id]: value,
      })
    } else {
      setFormValues({
        ...formValues,
        [id]: parseInt(value),
      })
    }
    if (value === '') {
      setFormErrors({
        ...formErrors,
        [id]: { check: true, message: t('home.signUpPage.errors.required') },
      })
    } else if (parseInt(value) < 18 || parseInt(value) > 99) {
      setFormErrors({
        ...formErrors,
        [id]: { check: true, message: t('home.signUpPage.errors.age') },
      })
    } else {
      setFormErrors({
        ...formErrors,
        [id]: { check: false, message: '' },
      })
    }
  }

  const handlePhoneNumberChange = (value: string) => {
    setFormValues({
      ...formValues,
      phoneNumber: '+'.concat(value),
    })
    if (value === '') {
      setFormErrors({
        ...formErrors,
        phoneNumber: { check: true, message: t('home.signUpPage.errors.required') },
      })
    } else {
      setFormErrors({
        ...formErrors,
        phoneNumber: { check: false, message: '' },
      })
    }
  }

  const setCustomStates = (id: string, value: string[]) => {
    setFormValues({
      ...formValues,
      [id]: value,
    })
  }

  const handleClickSubmit = () => {
    if (formIsValid()) {
      const user = {
        username: formValues.username,
        lastname: formValues.lastName,
        firstname: formValues.firstName,
        nickname: formValues.nickname,
        phoneNumber: formValues.phoneNumber,
        email: formValues.email,
        age: formValues.age,
        gender: formValues.gender.toUpperCase(),
        languages: formValues.languages.map((language) => language),
        accents: formValues.accents.map((accent) => accent),
      }
      setLoading(true)
      postUser(user, formValues.password)
        .then(() => {
          console.log('ttttt')
          setLoading(false)
          toast.success(t('toast.signUp.success'), { duration: 4000 })
          navigate('/')
        })
        .catch((err) => {
          setLoading(false)
          const formErrorsTemp = Object.assign({}, formErrors)
          const status = err.status
          const body = err.body
          if (status === 409) {
            formErrorsTemp.username = { check: true, message: t('home.signUpPage.errors.alreadyExists') }
          } else if (status === 400) {
            for (const violation of body.violations) {
              const violationField = violation.field.replace('addUser.inputUser.', '') as keyof typeof formErrors
              formErrorsTemp[violationField] = { check: true, message: violation.message }
            }
          } else if (status === 500) {
            navigate('/500')
          }
          setFormErrors(formErrorsTemp)
        })
    }
  }

  return (
    <>
      <Box className="NavBar" sx={{ mb: 10 }}>
        <NavBar
          onClickDarkMode={() => {
            colorMode.toggleColorMode()
          }}
        />
      </Box>
      <Box hidden={loading}>
        <Card
          variation="elevated"
          border={'1px solid #838C95'}
          borderRadius={'0'}
          width={{ large: '30rem', base: '25rem' }}
          margin={'auto'}
          padding={'1.5rem 2rem'}
          maxWidth={'85%'}
        >
          <Divider marginTop={'1rem'} marginBottom={'2rem'} label={t('home.signUpPage.title') ?? ''} />
          <TextField
            id={'username'}
            label=""
            placeholder={t('home.signUpPage.username') ?? ''}
            onInput={handleInputChange}
            value={formValues.username}
            hasError={formErrors.username.check}
            errorMessage={formErrors.username.message}
          />
          <TextField
            id={'email'}
            marginTop={'0.5rem'}
            marginBottom={'0.5rem'}
            label=""
            placeholder={t('home.signUpPage.email') ?? ''}
            onInput={handleInputChange}
            value={formValues.email}
            hasError={formErrors.email.check}
            errorMessage={formErrors.email.message}
          />
          <PasswordField
            id={'password'}
            crossOrigin={'react'}
            autoComplete="new-password"
            label=""
            placeholder={t('home.signUpPage.password') ?? ''}
            onInput={handlePasswordChange}
            value={formValues.password}
            hasError={formErrors.password.check}
            errorMessage={formErrors.password.message}
          />
          <Box marginTop={'0.5rem'} />
          <PasswordField
            id={'confirmPassword'}
            crossOrigin={'react'}
            autoComplete="new-password"
            label=""
            placeholder={t('home.signUpPage.confirmPassword') ?? ''}
            onInput={handleConfirmPasswordChange}
            value={formValues.confirmPassword}
            hasError={formErrors.confirmPassword.check}
            errorMessage={formErrors.confirmPassword.message}
          />
          <TextField
            id={'firstName'}
            marginTop={'0.5rem'}
            label=""
            placeholder={t('home.signUpPage.firstName') ?? ''}
            onInput={handleInputChange}
            value={formValues.firstName}
            hasError={formErrors.firstName.check}
            errorMessage={formErrors.firstName.message}
          />
          <TextField
            id={'lastName'}
            marginTop={'0.5rem'}
            label=""
            placeholder={t('home.signUpPage.lastName') ?? ''}
            onInput={handleInputChange}
            value={formValues.lastName}
            hasError={formErrors.lastName.check}
            errorMessage={formErrors.lastName.message}
          />
          <TextField
            id={'nickname'}
            marginTop={'0.5rem'}
            label=""
            placeholder={t('home.signUpPage.nickname') ?? ''}
            onInput={handleInputChange}
            value={formValues.nickname}
            hasError={formErrors.nickname.check}
            errorMessage={formErrors.nickname.message}
          />
          <TextField
            id={'age'}
            marginTop={'0.5rem'}
            label=""
            type={'number'}
            placeholder={t('home.signUpPage.age') ?? ''}
            value={formValues.age === 0 ? '' : formValues.age}
            onInput={handleAgeChange}
            hasError={formErrors.age.check}
            errorMessage={formErrors.age.message}
          />
          <Box
            marginTop={'1rem'}
            sx={{
              '& .react-tel-input': {
                '& .form-control': {
                  width: '100%',
                  height: '3rem',
                  backgroundColor: 'transparent',
                  borderColor: '#838C95',
                  color: theme.palette.text.primary,
                  fontFamily: '"Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif',
                  '&:focus': {
                    borderColor: theme.palette.text.primary,
                    boxShadow: 'var(--amplify-components-fieldcontrol-focus-box-shadow)',
                  },
                },
                '& .country-list': {
                  '& .search': {
                    backgroundColor: theme.palette.background.paper,
                  },
                  '& .country': {
                    '&:hover': {
                      backgroundColor: colorTheme === '"light"' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)',
                    },
                    '&.highlight': {
                      backgroundColor:
                        colorTheme === '"light"' ? 'rgba(63, 81, 181, 0.12)' : 'rgba(125, 214, 232, 0.24)',
                    },
                    '& .dial-code': {
                      color: theme.palette.text.secondary,
                    },
                  },
                },
              },
            }}
          >
            <PhoneInput
              enableSearch
              specialLabel={''}
              autoFormat
              alwaysDefaultMask={false}
              country={'fr'}
              value={formValues.phoneNumber.substring(1)}
              onChange={handlePhoneNumberChange}
              containerStyle={{
                marginTop: '10px',
              }}
              searchStyle={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}
              dropdownStyle={{
                backgroundColor: theme.palette.secondary.light,
              }}
            />
          </Box>
          <UniqueSelectChip
            list={['MALE', 'FEMALE', 'OTHER']}
            formattedList={(g) => t(`gender.${g}`)}
            label={t('home.signUpPage.gender') ?? ''}
            item={formValues.gender}
            formattedItem={(g) => t(`gender.${g}`)}
            material={false}
            setItem={(value: string) => setFormValues({ ...formValues, ['gender']: value })}
          />
          <MultipleSelectChip
            list={Object.values(Language)}
            formattedList={(lang) => t(`languages.${lang}`)}
            label={t('home.signUpPage.languages') ?? ''}
            items={formValues.languages}
            formattedItem={(lang) => t(`languages.${lang}`)}
            setItems={(items: string[]) => {
              const formValuesCopy = { ...formValues }
              formValuesCopy.languages = items as unknown as Language[]
              const availableAccents = items as unknown as Accent[]
              formValuesCopy.accents = formValuesCopy.accents.filter((accent) => availableAccents.includes(accent))
              setFormValues(formValuesCopy)
            }}
          />
          <MultipleSelectChip
            disabled={formValues.languages.length === 0}
            list={getAccentsByLanguages(formValues.languages)}
            formattedList={(acc) => t(`accents.${acc}`)}
            label={t('home.signUpPage.accents') ?? ''}
            items={formValues.accents}
            formattedItem={(acc) => t(`accents.${acc}`)}
            setItems={(items: string[]) => setCustomStates('accents', items)}
          />
          {formValues.accents.some((e) => e === Accent.OTHER) && (
            <TextField marginTop={'0.5rem'} label="" placeholder="Other accents" />
          )}
          <Button
            type={'submit'}
            marginTop={'1rem'}
            isFullWidth={true}
            variation="primary"
            onClick={(event) => {
              event.preventDefault()
              handleClickSubmit()
            }}
          >
            {t('home.signUpPage.signUp')}
          </Button>
        </Card>
        <div>
          <Button size={'small'} variation={'link'} marginBottom={30} onClick={handleClickLogin}>
            {t('home.signUpPage.goToSignIn') ?? ''}
          </Button>
        </div>
      </Box>
      <Box hidden={!loading} marginTop={'1rem'}>
        <CircularProgress hidden={!loading} />
      </Box>
    </>
  )
}

export default SignUp
