import SimulaionFormStepper from './SimulaionFormStepper'
import React, { useContext } from 'react'
import { Box, Button, Card, Typography } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import userContext from '../../../contexts/user/UserContext'
import toast from 'react-hot-toast'
import dayjs, { Dayjs } from 'dayjs'
import { LoadingButton } from '@mui/lab'
import MediaQuery from 'react-responsive'
import { useSimulationManagementContext } from '../../../hooks/ContextHooks'
import { APISimulationRole } from '../../../rest/simulation/models/APISimulationRole'
import { APISimulationConf } from '../../../rest/simulation/models/APISimulationConf'
import { APISimulationRequest } from '../../../rest/simulation/models/APISimulationRequest'
import { postSimulationRequest } from '../../../rest/simulation/queries/SimulationRequestQueries'

interface SimulationFormPublishProps {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  simulation: APISimulationConf
  setSimulation: React.Dispatch<React.SetStateAction<APISimulationConf>>
}

function SimulationFormPublish(props: SimulationFormPublishProps) {
  const { simulation, setStep, step } = props
  const { t } = useTranslation()
  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs().add(1, 'hour'))
  const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs().add(2, 'hour'))
  const [dateError, setDateError] = React.useState(false)
  const navigate = useNavigate()
  const frontUser = useContext(userContext)
  const [loadingPublish, setLoadingPublish] = React.useState(false)
  const { setSimulationRequests, simulationRequests } = useSimulationManagementContext() //The list of simulation requests

  function handlePublish() {
    setLoadingPublish(true)
    const simulationRequest = {
      id: null,
      simulation: {
        name: simulation.name,
        wind: simulation.wind.toUpperCase().replace(' ', '_'),
        weather: simulation.weather.toUpperCase().replace(' ', '_'),
        tags: simulation.tags,
        roles: simulation.roles.map((role: APISimulationRole) => {
          return {
            id: null,
            name: role.name,
            frequencies: role.frequencies.map((freq) => 'radio_' + freq.replace('.', '_')),
            roleType: role.roleType.toUpperCase().replace(' ', '_'),
            gender: role.gender.toUpperCase(),
            languages: role.languages.map((lang) => lang),
            accents: role.accents.map((accent) => accent),
            minAge: role.minAge,
            maxAge: role.maxAge,
          } as APISimulationRole
        }),
      } as APISimulationConf,
      startDate: startDate?.toDate(),
      endDate: endDate?.toDate(),
      clientUsername: frontUser?.user?.username,
    } as APISimulationRequest
    postSimulationRequest(simulationRequest)
      .then((value) => {
        setTimeout(() => {
          toast.success(t('simulations.create.publish.success'))
        }, 400)
        setLoadingPublish(false)
        navigate('/simulations')
        if (simulationRequests) {
          setSimulationRequests([...simulationRequests, value])
        } else {
          setSimulationRequests([value])
        }
      })
      .catch(() => {
        setTimeout(() => {
          toast.error(t('simulations.create.publish.error'))
        }, 400)
        setLoadingPublish(false)
      })
  }

  function handleStartTimeChange(date: dayjs.Dayjs | null) {
    if (date?.isAfter(endDate)) {
      setDateError(true)
    } else {
      setDateError(false)
    }
    if (date?.isBefore(dayjs().add(10, 'minute'))) {
      setStartDate(dayjs().add(10, 'minute'))
    } else {
      setStartDate(date)
    }
  }

  function handleEndTimeChange(date: dayjs.Dayjs | null) {
    if (date?.isBefore(startDate)) {
      setDateError(true)
    } else {
      setDateError(false)
    }
    setEndDate(date)
  }

  return (
    <>
      <SimulaionFormStepper step={step}></SimulaionFormStepper>
      <Box sx={{ width: 600, maxWidth: '90%', margin: 'auto', marginTop: '40px' }}>
        <Card variant={'elevation'} sx={{ p: 3 }}>
          <Typography variant={'h6'}>{t('simulations.create.publish.title')}</Typography>
          <MediaQuery minWidth={560}>
            <Box sx={{ mt: 3, display: 'flex' }}>
              <DateTimePicker
                label={t('simulations.create.publish.start')}
                sx={{ mr: 2, width: '100%' }}
                value={startDate}
                disablePast
                slotProps={{ textField: { variant: 'outlined', error: dateError } }}
                onChange={(newValue) => {
                  handleStartTimeChange(newValue)
                }}
              />
              <DateTimePicker
                label={t('simulations.create.publish.end')}
                sx={{ width: '100%' }}
                value={endDate}
                disablePast
                slotProps={{ textField: { variant: 'outlined', error: dateError } }}
                onChange={(newValue) => {
                  handleEndTimeChange(newValue)
                }}
              />
            </Box>
          </MediaQuery>
          <MediaQuery maxWidth={559}>
            <Box sx={{ mt: 3 }}>
              <DateTimePicker
                label={t('simulations.create.publish.start')}
                sx={{ width: '100%' }}
                value={startDate}
                disablePast
                slotProps={{ textField: { variant: 'outlined', error: dateError } }}
                onChange={(newValue) => {
                  handleStartTimeChange(newValue)
                }}
              />
              <DateTimePicker
                label={t('simulations.create.publish.end')}
                sx={{ mt: 2, width: '100%' }}
                value={endDate}
                disablePast
                slotProps={{ textField: { variant: 'outlined', error: dateError } }}
                onChange={(newValue) => {
                  handleEndTimeChange(newValue)
                }}
              />
            </Box>
          </MediaQuery>
        </Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mt: 4 }}>
          <Button onClick={() => setStep(step - 1)} variant={'outlined'}>
            {t('simulations.create.buttons.return')}
          </Button>
          <LoadingButton
            loading={loadingPublish}
            variant={'contained'}
            color={'success'}
            sx={{ color: 'white' }}
            onClick={handlePublish}
            disabled={!startDate || !endDate || startDate.isAfter(endDate, 'm')}
          >
            {t('simulations.create.buttons.publish')}
          </LoadingButton>
        </Box>
      </Box>
    </>
  )
}

export default SimulationFormPublish
