import { MenuItem, Select, useTheme } from '@mui/material'
import { useState } from 'react'
import FrenchFlag from './images/fr.png'
import EnglishFlag from './images/gb.png'
import { SelectChangeEvent } from '@mui/material/Select'
import i18n from 'i18next'
import { KeyboardArrowDown } from '@mui/icons-material'
import getDesignTokens from '../../../utils/theme/getDesignTokens'
import { useMediaQuery } from 'react-responsive'

interface Country {
  code: string
  flag: string
}

const countries: { [key: string]: Country } = {
  fr: {
    code: 'fr',
    flag: FrenchFlag,
  },
  en: {
    code: 'en',
    flag: EnglishFlag,
  },
}

export const FlagSelect = () => {
  const [language, setLanguage] = useState<Country>(
    window.localStorage.getItem('i18nextLng') === 'fr' ? countries.fr : countries.en,
  )
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery({ query: `(max-width: 899.5px)` })

  const handleChange = (event: SelectChangeEvent) => {
    i18n
      .changeLanguage(event.target.value as string)
      .then(() => {
        setLanguage(countries[event.target.value as string])
      })
      .catch()
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Select
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={language.code}
      name="country"
      onChange={handleChange}
      size={'small'}
      variant={'outlined'}
      sx={{
        height: '40px',
        mr: 2,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '.MuiSvgIcon-root ': {
          fill: isMobile
            ? theme.palette.primary.main + ' !important'
            : getDesignTokens(theme.palette.mode).palette?.appbar?.text + ' !important',
        },
      }}
      // change arrow icon color
      IconComponent={(props) => {
        return <KeyboardArrowDown {...props} />
      }}
    >
      {Object.keys(countries).map((key) => (
        <MenuItem
          key={key}
          value={countries[key].code}
          sx={{
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={countries[key].flag} alt={countries[key].code} width={30} />
        </MenuItem>
      ))}
    </Select>
  )
}
