export default {
  signUp: {
    success: 'Vous vous êtes inscrit avec succès !',
  },
  signIn: {
    error1: "Une erreur s'est produite lors de la récupération de l'utilisateur.",
    error2: 'Réessayez plus tard.',
  },
  simulations: {
    roles: {
      applySuccess: 'Vous avez candidaté avec succès pour le rôle {{role}} dans la simulation {{simulation}} !',
      applyError:
        "Une erreur s'est produite lors de la candidature au rôle {{role}} dans la simulation {{simulation}}.",
    },
    joined: {
      error_title: "Une erreur s'est produite lors de la récupération des simulations rejointes :",
      error_description: 'Réessayez plus tard.',
    },
  },
}
