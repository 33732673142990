import React from 'react'
import SimulationManagementContext from './SimulationManagementContext'
import useSimulationManagement from './useSimulationManagement'

const SimulationManagementProvider = (props: { children: React.ReactNode }) => {
  const { children } = props
  const { simulationRequests, setSimulationRequests, callSimulationRequests } = useSimulationManagement()

  return (
    <SimulationManagementContext.Provider
      value={{
        simulationRequests: simulationRequests,
        setSimulationRequests: setSimulationRequests,
        callSimulationRequests: callSimulationRequests,
      }}
    >
      {children}
    </SimulationManagementContext.Provider>
  )
}

export default SimulationManagementProvider
