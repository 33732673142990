export default {
  navbar: {
    toggleDarkTheme: 'Utiliser le mode sombre',
    toggleLightTheme: 'Utiliser le mode clair',
    accountOptions: 'Options du compte',
  },
  account: {
    edit: 'Modifier',
  },
  simulations: {
    running: {
      pttMic: 'Maintenez P pour parler',
      pttMicOffMobile: 'Maintenez pour parler',
      pttMicOnMobile: 'Relâchez pour arrêter de parler',
      muteAudio: 'Appuyez sur M pour couper le son',
      unmuteAudio: 'Appuyez sur M pour réactiver le son',
      settings: {
        cannotAccessInput: "Impossible d'accéder aux périphériques d'entrée",
        cannotAccessOutput: "Impossible d'accéder aux périphériques de sortie",
      },
      privateCall: {
        call: 'Démarrer un appel privé',
        hangup: 'Raccrocher',
        pickUp: 'Répondre',
        hold: 'Mettre en attente',
        resume: 'Reprendre',
        add: "Ajouter quelqu'un à l'appel",
        cancel: 'Annuler',
      },
    },
  },
}
