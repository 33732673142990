import { Card, IconButton, ListItem, ListItemText, Tooltip } from '@mui/material'
import React from 'react'
import APIUser from '../../../rest/user/APIUser'
import EditIcon from '@mui/icons-material/Edit'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'

const AItem = (props: { children: React.ReactNode }): React.ReactElement => {
  const { children } = props
  const theme = useTheme()

  return (
    <Card
      sx={{
        ...theme.typography.body2,
        padding: theme.spacing(3),
        textAlign: 'left',
        boxShadow: 3,
        color: theme.palette.text.primary,
        '&:hover': {
          boxShadow: 10,
        },
      }}
    >
      {children}
    </Card>
  )
}

const AListItemSx = (dark: boolean) => {
  return {
    bgcolor: 'background.paper',
    borderTop: 1,
    borderTopColor: 'text.secondary',
    m: '0 auto',
    '&:hover': {
      filter: dark ? 'brightness(1.2)' : 'brightness(0.9)',
    },
  }
}

const AListElement = (props: {
  primary: string
  secondary: string
  user: APIUser
  setUser: React.Dispatch<APIUser>
  fieldId: string
  editable?: boolean
  setField?: (fieldId: string) => void
}): React.ReactElement => {
  const { primary, secondary, editable, setField, fieldId } = props
  const isLaptop = useMediaQuery({ minWidth: 950 })
  const { t } = useTranslation()
  const theme = useTheme()
  const mode = theme.palette.mode

  return (
    <ListItem sx={AListItemSx(mode === 'dark')}>
      <ListItemText
        secondaryTypographyProps={{ overflow: isLaptop ? 'hidden' : 'auto', whiteSpace: 'nowrap' }}
        primary={primary}
        secondary={secondary}
      />
      {editable && setField && (
        <Tooltip title={t('tooltips.account.edit') ?? ''}>
          <IconButton color={'primary'} onClick={() => setField(fieldId)}>
            <EditIcon color={'primary'} />
          </IconButton>
        </Tooltip>
      )}
    </ListItem>
  )
}

export { AItem, AListItemSx, AListElement }
